import axios from "axios";
import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const initialState = {
  lname: "",
  address: "",
  district: "",
  pincode: "",
  pos: "",
  postoffice: "",
  lcode: "",
};

const LocationFrom = ({ onCreate, onCancel }) => {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const [locationCode, setLocationCode] = useState([]);
  const { lname, address, district, pincode, lcode, postoffice } = state;
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const fetchNextLocationCode = async () => {
      try {
        const response = await axios.get(
          "https://hospital-api.hisabdev.com/api/location/nextlocationcode"
        );
        setLocationCode(response.data.LocationCode);
      } catch (error) {
        console.error("Error fetching next product code:", error);
        toast.error("Error fetching next product code");
      }
    };

    fetchNextLocationCode();
  }, []);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (!address || !district || !pincode || !postoffice) {
  //     toast.error("Please provide value into each inpute field ");
  //   } else {
  //     if (!id) {
  //       axios
  //         .post("https://hospital-api.hisabdev.com/api/location/createlocation", {
  //           ...state
  //         })
  //         .then(() => {
  //           setState(initialState);
  //           console.log("classdetails", state);
  //         })
  //         .catch((err) => toast.error(err.respose.data));
  //       toast.success("Successfully");
  //     }
  //     setTimeout(() => {
  //       onCancel();
  //     }, 500);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!address || !district || !pincode || !postoffice) {
      toast.error("Please provide value into each input field");
    } else {
      setLoading(true);
      try {
        if (!id) {
          await axios.post(
            "https://hospital-api.hisabdev.com/api/location/createlocation",
            {
              ...state,
            }
          );
          setState(initialState);
          toast.success("Location created successfully");
        }
      } catch (err) {
        toast.error(err.response.data);
      } finally {
        setLoading(false);
      }
      setTimeout(() => {
        onCancel();
      }, 500);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  return (
    <div className="bg-sky-200 p-4 drop-shadow rounded-xl ml-4 w-11/12">
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col lg={3} md={6} sm={12}>
            <Form.Group>
              <Form.Label>Location Name<span className="required-icon">*</span></Form.Label>
              <Form.Control
                required
                type="text"
                name="lname"
                value={lname || ""}
                onChange={handleInputChange}
                placeholder="Enter Address"
              />
            </Form.Group>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Form.Group>
              <Form.Label>Address<span className="required-icon">*</span></Form.Label>
              <Form.Control
                required
                type="text"
                name="address"
                value={address || ""}
                onChange={handleInputChange}
                placeholder="Enter Address"
              />
            </Form.Group>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Form.Group>
              <Form.Label>District<span className="required-icon">*</span></Form.Label>
              <Form.Control
                required
                type="text"
                name="district"
                value={district || ""}
                onChange={handleInputChange}
                placeholder="District"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={6} md={6} sm={12}>
            <Form.Group>
              <Form.Label>Pin Code<span className="required-icon">*</span></Form.Label>
              <Form.Control
                required
                type="number"
                name="pincode"
                value={pincode || ""}
                onChange={handleInputChange}
                placeholder="Enter Pin Code"
              />
            </Form.Group>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Form.Group>
              <Form.Label>Post Office<span className="required-icon">*</span></Form.Label>
              <Form.Control
                required
                type="text"
                name="postoffice"
                value={postoffice || ""}
                onChange={handleInputChange}
                placeholder="Enter Post Office  "
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="flex justify-content-between my-4">
          <Button
            variant="danger"
            onClick={onCancel}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
          >
            Cancel
          </Button>
          <Button type="submit" className="btn btn-success" disabled={loading}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default LocationFrom;
