import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { Input } from "@mui/joy";
import { RxCross2 } from "react-icons/rx";

const initialState = {
  name: "",
  degicnation: "",
  department: "",
  DOB: "",
  joining_date: "",
  pf: "",
  esi: "",
  aadharcard: "",
  pancard: "",
  additionalfield: [],
  direction: [],
  scode: ""
};

function UpdateStaff({ onCreate, onCancel, customers, model }) {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const [departmentData, setDepartmentData] = useState([]);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`https://hospital-api.hisabdev.com/api/staff/${id}`)
      .then((resp) => {
        const data = resp.data;
        setState(data);
      })
      .catch((err) => toast.error("Error fetching staff data"));
  }, [id]);

  const loadDepartmentData = async () => {
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/department");
      setDepartmentData(response.data[0]);
      console.log(response.data);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    loadDepartmentData()
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, degicnation, department, DOB, joining_date, pf, esi, aadharcard, pancard, additionalfield, direction, scode } = state;

    if (!name || !degicnation) {
      toast.error("Please provide value into each input field");
    } else {
      axios
        .put(`https://hospital-api.hisabdev.com/api/staff/updatestaff/${id}`, {
          name,
          degicnation,
          department,
          DOB,
          joining_date,
          pf,
          esi,
          aadharcard,
          pancard,
          additionalfield,
          direction,
          scode
        })
        .then(() => {
          toast.success("Update Successfully");
          navigate('/staff');
        })
        .catch((err) => toast.error("Error updating staff data"));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleTestChange = (index, event) => {
    const { name, value } = event.target;
    const newAdditionalField = [...state.additionalfield];
    newAdditionalField[index][name] = value;
    setState({
      ...state,
      additionalfield: newAdditionalField,
    });
  };

  const handleDeleteTest = (index) => {
    const newAdditionalField = state.additionalfield.filter((_, i) => i !== index);
    setState({
      ...state,
      additionalfield: newAdditionalField,
    });
  };

  const addTestField = () => {
    setState({
      ...state,
      additionalfield: [...state.additionalfield, { testname: "", result: "" }],
    });
  };

  const handleDirectionChange = (index, event) => {
    const { name, value } = event.target;
    const newDirection = [...state.direction];
    newDirection[index][name] = value;
    setState({
      ...state,
      direction: newDirection,
    });
  };

  const handleDeleteDirection = (index) => {
    const newDirection = state.direction.filter((_, i) => i !== index);
    setState({
      ...state,
      direction: newDirection,
    });
  };

  const addDirectionField = () => {
    setState({
      ...state,
      direction: [...state.direction, { directionName: "", directionResult: "" }],
    });
  };

  return (
    <div className="bg-sky-200 p-4 drop-shadow rounded-xl ml-4 w-11/12">
      <Form onSubmit={handleSubmit}>
        <div className="py-2">
          <Row>
            <Col lg={2} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="block text-gray-700 font-medium">Staff's Code</Form.Label>
                <Input
                  type="text"
                  name="scode"
                  disabled
                  value={state.scode || ""}
                />
              </Form.Group>
            </Col>
            <Col lg={5} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">Name</Form.Label>
                <Input
                  type="text"
                  placeholder="Staff's Name"
                  name="name"
                  value={state.name || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={5} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">Designation</Form.Label>
                <Input
                  type="text"
                  placeholder="Designation"
                  name="degicnation"
                  value={state.degicnation || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group as={Col}>
                <Form.Label>Department</Form.Label>
                <Form.Control
                  as="select"
                  name="department"
                  value={state.department || ""}
                  onChange={handleInputChange}
                >
                  <option value="">Select Department</option>
                  {departmentData.map((dp) => (
                    <option key={dp.Id} value={dp.departmentName}>
                      {dp.departmentName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group>
                <Form.Label className=" text-gray-700 font-medium">
                  DOB
                </Form.Label>
                <Input
                  type="date"
                  placeholder="DOB"
                  name="DOB"
                  value={state.DOB || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group>
                <Form.Label className=" text-gray-700 font-medium">
                  Joining Date
                </Form.Label>
                <Input
                  type="date"
                  placeholder="joining date"
                  name="joining_date"
                  value={state.joining_date || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">PF</Form.Label>
                <Input
                  type="text"
                  placeholder="PF"
                  name="pf"
                  value={state.pf || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group >
                <Form.Label className="text-gray-700 font-medium">ESI</Form.Label>
                <Input
                  type="text"
                  placeholder="ESI"
                  name="esi"
                  value={state.esi || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group >
                <Form.Label className="text-gray-700 font-medium">Aadhar No.</Form.Label>
                <Input
                  type="text"
                  placeholder="Aadhar Card No."
                  name="aadharcard"
                  value={state.aadharcard || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group >
                <Form.Label className="text-gray-700 font-medium">PAN No.</Form.Label>
                <Input
                  type="text"
                  placeholder="PAN Card No."
                  name="pancard"
                  value={state.pancard || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label className="font-semibold">Additional Field</Form.Label>
                {state.additionalfield.map((test, index) => (
                  <Row key={index} className="my-1">
                    <Col>
                      <input
                        type="text"
                        name="testname"
                        className="form-control"
                        value={test.testname || ""}
                        onChange={(e) => handleTestChange(index, e)}
                        placeholder="Enter Field Name"
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        name="result"
                        placeholder="Value"
                        value={test.result || ""}
                        onChange={(e) => handleTestChange(index, e)}
                      />
                    </Col>
                    <Col lg={2}>
                      {index !== 0 && (
                        <Button variant="danger" onClick={() => handleDeleteTest(index)}>
                          <RxCross2 />
                        </Button>
                      )}
                    </Col>
                  </Row>
                ))}
              </Form.Group>
              <Button className="w-full my-2" variant="outline-primary" onClick={addTestField}>
                + Add
              </Button>
            </Col>
          </Row>
        </div>
        <div className="flex justify-content-between my-4">
          <Button
            variant="danger"
            onClick={() => navigate('/staff')}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="px-4 py-2"
          >
            Update
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default UpdateStaff;
