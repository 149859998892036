// import React, { useEffect, useState } from "react";
// import { Button, Col, Container, Form, Row, Modal } from "react-bootstrap";
// import { useParams, useNavigate } from "react-router-dom";
// import axios from "axios";
// import logo from "../../assets/logo/Rotarylogo.png";

// const DischargeDetails = () => {
//   const { id } = useParams();
//   const [data, setData] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [medicine, setMedicine] = useState("");
//   const [nextVisitDate, setNextVisitDate] = useState("");
//   const [narration, setNarration] = useState("");
//   const [showModal, setShowModal] = useState(false);
//   const [medicineData, setMedicineData] = useState([]);

//   const navigate = useNavigate();

// const formatDate = (dateString) => {
//   const date = new Date(dateString);
//   return new Intl.DateTimeFormat("en-US", {
//     year: "numeric",
//     month: "long",
//     day: "numeric",
//   }).format(date);
// };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           `https://hospital-api.hisabdev.com/api/admission/${id}`
//         );
//         setData(response.data[0]);
//       } catch (error) {
//         setError(error.message);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchData();
//   }, [id]);

//   const handleDischarge = () => {
//     const dischargeDetails = {
//       status: "discharge",
//       nextVisitDate,
//       narration,
//     };

//     axios
//       .put(`https://hospital-api.hisabdev.com/api/admission/status/${id}`, dischargeDetails)
//       .then((response) => {
//         console.log("Status updated successfully", response);
//         return axios.get(`https://hospital-api.hisabdev.com/api/bed/bedId/${data.bed}`);
//       })
//       .then((response) => {
//         const bedId = response.data.bedId;
//         return axios.put(`https://hospital-api.hisabdev.com/api/bed/${bedId}/status`, {
//           status: "vacant",
//         });
//       })
//       .then((response) => {
//         setShowModal(true); // Show the modal after discharge
//       })
//       .catch((error) => {
//         setError("Failed to update status");
//       });
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//     navigate("/discharge");
//   };

//   const handlePrint = () => {
//     window.print();
//   };

//   if (isLoading) {
//     return <div className="text-center mt-5">Loading...</div>;
//   }

//   if (error) {
//     return <div className="text-center mt-5">Error: {error}</div>;
//   }

//   if (!data) {
//     return <div className="text-center mt-5">No data available</div>;
//   }

//   // Fetch Medicine
//   const loadMedicineData = async () => {
//     try {
//       const response = await axios.get("https://hospital-api.hisabdev.com/api/product");
//       setMedicineData(response.data[0]);
//     } catch (error) {
//       setError(error.message);
//     }
//   };

//   useEffect(()=>{
//     loadMedicineData()
//   })

//   const handleAddMedicine = () => {
//     setState((prevState) => ({
//       ...prevState,
//       medicines: [
//         ...prevState.medicines,
//         { name: "", instruction: "", dosage: "" },
//       ],
//     }));
//   };

//   return (
//     <Container className="mt-5">
//       <h2 className="text-primary mb-4">Patient Details</h2>

//       <Row className="mb-3">
// <Col><strong>Date:</strong> {formatDate(data.date)}</Col>
//       </Row>
//       <Row className="mb-3">
//         <Col><strong>Patient Name:</strong> {data.name}</Col>
//       </Row>
//       <Row className="mb-3">
//         <Col><strong>Mobile Number:</strong> {data.mobilenumber}</Col>
//       </Row>
//       <Row className="mb-3">
//         <Col><strong>Gender:</strong> {data.sex}</Col>
//       </Row>
//       <Row className="mb-3">
//         <Col><strong>Age:</strong> {data.age}</Col>
//       </Row>
//       <Row className="mb-3">
//         <Col><strong>Address:</strong> {data.address}</Col>
//       </Row>
//       <Row className="mb-3">
//         <Col><strong>Bed:</strong> {data.bed}</Col>
//       </Row>
//       <Row className="mb-3">
//         <Col><strong>Doctor Name:</strong> {data.doctor}</Col>
//       </Row>
//       <Row className="mb-3">
//         <Col><strong>Package:</strong> {data.packages}</Col>
//       </Row>

//       <Row className="mb-3">
//         <Col lg={2} md={6} sm={12} xs={12}>
//           <Form.Group controlId="formNextVisitDate">
//             <Form.Label><strong>Next Visit Date:</strong></Form.Label>
//             <Form.Control
//               type="date"
//               value={nextVisitDate}
//               onChange={(e) => setNextVisitDate(e.target.value)}
//             />
//           </Form.Group>
//         </Col>
//         <Col lg={5} md={12} sm={12}>
//                   <Form.Group>
//                     <Form.Label className="font-semibold">
//                       Prescribed Medicines
//                     </Form.Label>
//                     {medicines.map((medicine, index) => (
//                       <div
//                         key={index}
//                         className="border p-1 rounded flex flex-row gap-1 mb-1"
//                       >
//                         <Form.Control
//                           as="select"
//                           value={medicine.name}
//                           // onChange={(e) => {
//                           //   const updatedMedicines = [...medicines];
//                           //   updatedMedicines[index].name = e.target.value;
//                           //   setState((prevState) => ({
//                           //     ...prevState,
//                           //     medicines: updatedMedicines,
//                           //   }));
//                           // }}
//                         >
//                           <option value="">Select Medicine</option>
//                           {medicineData.map((medi) => (
//                             <option
//                               key={medi.Description}
//                               value={medi.Description}
//                             >
//                               {medi.Description}
//                             </option>
//                           ))}
//                         </Form.Control>
//                         <Form.Control
//                           type="text"
//                           placeholder="Instruction"
//                           value={medicine.instruction}
//                           // onChange={(e) => {
//                           //   const updatedMedicines = [...medicines];
//                           //   updatedMedicines[index].instruction =
//                           //     e.target.value;
//                           //   setState((prevState) => ({
//                           //     ...prevState,
//                           //     medicines: updatedMedicines,
//                           //   }));
//                           // }}
//                         />
//                         <Form.Control
//                           type="text"
//                           placeholder="Dosage"
//                           value={medicine.dosage}
//                           onChange={(e) => {
//                             const updatedMedicines = [...medicines];
//                             updatedMedicines[index].dosage = e.target.value;
//                             setState((prevState) => ({
//                               ...prevState,
//                               medicines: updatedMedicines,
//                             }));
//                           }}
//                         />
//                         <Button
//                           disabled={index === 0}
//                           variant="danger"
//                           onClick={() => handleDeleteMedicine(index)}
//                         >
//                           <RxCross2 />
//                         </Button>
//                       </div>
//                     ))}
//                   </Form.Group>
//                   <Button
//                     className="w-full"
//                     variant="outline-primary"
//                     onClick={handleAddMedicine}
//                   >
//                     Add Medicine
//                   </Button>
//                 </Col>
//         <Col lg={5} md={6} sm={12} xs={12}>
//           <Form.Group controlId="formNarration">
//             <Form.Label><strong>Narration:</strong></Form.Label>
//             <Form.Control
//               as="textarea"
//               placeholder="Write Narration"
//               rows={3}
//               value={narration}
//               onChange={(e) => setNarration(e.target.value)}
//             />
//           </Form.Group>
//         </Col>
//       </Row>
//       {/* End of New Fields */}

//       <div className="d-flex justify-content-between mt-4">
//         <Button
//           variant="secondary"
//           onClick={() => navigate(-1)}
//         >
//           Back
//         </Button>
//         <Button
//           variant="success"
//           onClick={handleDischarge}
//         >
//           Discharge
//         </Button>
//       </div>

//       {/* Modal for printing */}
//       <Modal show={showModal} onHide={handleCloseModal}>
//       <Modal.Header closeButton>
//           <div className="d-flex flex-column align-items-start w-100">
//             <div className="d-flex align-items-center">
//               <img
//                 src={logo}
//                 alt="Logo"
//                 style={{ height: "50px", marginRight: "20px" }}
//               />
//               <div className="flex-row">
//               <Modal.Title className="mb-0">
//                 Rotary Beldanga Eye Hospital
//               </Modal.Title>
//               <p className="mb-0 text-sm">(Unity of Rotary Domkal Murshidabad Welfare Trust)</p>
//               </div>
//             </div>
//             <div className="mt-2 text-center items-center">
//               <p className="mb-0">vill+P.O.-Barua(NH34),P.S.,Beldanga,Murshidabad,West Bengal</p>
//               <p className="mb-0">Contact No: 03482 264010/ Mob: 8641941515</p>
//               <p className="mb-0">Discharge Form</p>
//             </div>
//           </div>
//         </Modal.Header>
//         <Modal.Body>
//         <Row className="mb-3">
//         <Col><strong>Date:</strong> {formatDate(data.date)}</Col>
//       </Row>
//       <Row className="mb-3">
//         <Col><strong>Patient Name:</strong> {data.name}</Col>
//       </Row>
//       <Row className="mb-3">
//         <Col><strong>Mobile Number:</strong> {data.mobilenumber}</Col>
//       </Row>
//       <Row className="mb-3">
//         <Col><strong>Gender:</strong> {data.sex}</Col>
//       </Row>
//       <Row className="mb-3">
//         <Col><strong>Age:</strong> {data.age}</Col>
//       </Row>
//       <Row className="mb-3">
//         <Col><strong>Address:</strong> {data.address}</Col>
//       </Row>
//       <Row className="mb-3">
//         <Col><strong>Bed:</strong> {data.bed}</Col>
//       </Row>
//       <Row className="mb-3">
//         <Col><strong>Doctor Name:</strong> {data.doctor}</Col>
//       </Row>
//       <Row className="mb-3">
//         <Col><strong>Package:</strong> {data.packages}</Col>
//       </Row>
//       <Row className="mb-3">
//             <Col><strong>Next Visit Date:</strong> {nextVisitDate}</Col>
//           </Row>
//           <Row className="mb-3">
//             <Col><strong>Narration:</strong> {narration}</Col>
//           </Row>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseModal}>
//             Close
//           </Button>
//           <Button variant="primary" onClick={handlePrint}>
//             Print
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </Container>
//   );
// };

// export default DischargeDetails;

import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Modal } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../../assets/logo/Rotarylogo.png';

const DischargeDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [medicineData, setMedicineData] = useState([]);
  const [medicines, setMedicines] = useState([
    { name: '', instruction: '', dosage: '' },
  ]);
  const [nextVisitDate, setNextVisitDate] = useState('');
  const [narration, setNarration] = useState('');
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(date);
  };

  const today = new Date();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://hospital-api.hisabdev.com/api/admission/${id}`
        );
        setData(response.data[0]);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const loadMedicineData = async () => {
      try {
        const response = await axios.get('https://hospital-api.hisabdev.com/api/product');
        const dischargeMedicines = response.data[0].filter(
          (medicine) => medicine.productgroup === 'DISCHARGE'
        );
        setMedicineData(dischargeMedicines);
      } catch (error) {
        setError(error.message);
      }
    };

    loadMedicineData();
  }, []);

  const handleDischarge = () => {
    const dischargeDetails = {
      status: 'discharge',
      nextVisitDate,
      narration,
      medicines,
    };

    axios
      .put(`https://hospital-api.hisabdev.com/api/admission/status/${id}`, dischargeDetails)
      .then((response) => {
        console.log('Status updated successfully', response);
        return axios.get(`https://hospital-api.hisabdev.com/api/bed/bedId/${data.bed}`);
      })
      .then((response) => {
        const bedId = response.data.bedId;
        return axios.put(`https://hospital-api.hisabdev.com/api/bed/${bedId}/status`, {
          status: 'vacant',
        });
      })
      .then((response) => {
        setShowModal(true); // Show the modal after discharge
      })
      .catch((error) => {
        setError('Failed to update status');
      });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigate('/discharge');
  };

  const handlePrint = () => {
    window.print();
  };

  const handleAddMedicine = () => {
    setMedicines((prevMedicines) => [
      ...prevMedicines,
      { name: '', instruction: '', dosage: '' },
    ]);
  };

  const handleDeleteMedicine = (index) => {
    setMedicines((prevMedicines) =>
      prevMedicines.filter((_, i) => i !== index)
    );
  };

  const handleMedicineChange = (index, field, value) => {
    const updatedMedicines = [...medicines];
    updatedMedicines[index][field] = value;
    setMedicines(updatedMedicines);
  };

  if (isLoading) {
    return <div className="text-center mt-5">Loading...</div>;
  }

  if (error) {
    return <div className="text-center mt-5">Error: {error}</div>;
  }

  if (!data) {
    return <div className="text-center mt-5">No data available</div>;
  }

  return (
    <Container className="mt-5">
      <h2 className="text-primary mb-4">Patient Details</h2>
      {/* Patient Details */}
      <Row className="mb-3">
        <Col>
          <strong>Date:</strong> {formatDate(data.date)}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <strong>Patient Name:</strong> {data.name}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <strong>Mobile Number:</strong> {data.mobilenumber}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <strong>Gender:</strong> {data.sex}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <strong>Age:</strong> {data.age}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <strong>Address:</strong> {data.address}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <strong>Bed:</strong> {data.bed}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <strong>Doctor Name:</strong> {data.doctor}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <strong>Package:</strong> {data.packages}
        </Col>
      </Row>

      {/* New Fields */}
      <Row className="mb-3">
        <Col lg={3} md={6} sm={12} xs={12}>
          <Form.Group controlId="formNextVisitDate">
            <Form.Label>
              <strong>Next Visit Date:</strong>
            </Form.Label>
            <Form.Control
              type="date"
              value={nextVisitDate}
              onChange={(e) => setNextVisitDate(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col lg={9} md={12} sm={12}>
          <Form.Group>
            <Form.Label>
              <strong>Medicines:</strong>
            </Form.Label>
            {medicines.map((medicine, index) => (
              <div
                key={index}
                className="border p-1 rounded flex flex-row gap-1 mb-1"
              >
                <Form.Control
                  as="select"
                  value={medicine.name}
                  onChange={(e) =>
                    handleMedicineChange(index, 'name', e.target.value)
                  }
                >
                  <option value="">Select Medicine</option>
                  {medicineData.map((medi) => (
                    <option key={medi.Description} value={medi.Description}>
                      {medi.Description}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control
                  type="text"
                  placeholder="Instruction"
                  value={medicine.instruction}
                  onChange={(e) =>
                    handleMedicineChange(index, 'instruction', e.target.value)
                  }
                />
                <Form.Control
                  type="text"
                  placeholder="Dosage"
                  value={medicine.dosage}
                  onChange={(e) =>
                    handleMedicineChange(index, 'dosage', e.target.value)
                  }
                />
                <Button
                  variant="danger"
                  onClick={() => handleDeleteMedicine(index)}
                  disabled={medicines.length === 1}
                >
                  Remove
                </Button>
              </div>
            ))}
            <Button variant="outline-primary" onClick={handleAddMedicine}>
              Add Medicine
            </Button>
          </Form.Group>
        </Col>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Form.Group controlId="formNarration">
            <Form.Label>
              <strong>Narration:</strong>
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Write Narration"
              rows={3}
              value={narration}
              onChange={(e) => setNarration(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Action Buttons */}
      <div className="d-flex justify-content-between mt-4">
        <Button variant="secondary" onClick={() => navigate(-1)}>
          Back
        </Button>
        <Button variant="success" onClick={handleDischarge}>
          Discharge
        </Button>
      </div>

      {/* Modal for printing */}

      <Modal
        className="printable-content"
        show={showModal}
        onHide={handleCloseModal}
        centered
      >
        <Modal.Header>
          <div className="d-flex flex-column text-center w-100">
            <div className="d-flex align-items-center">
              <img
                src={logo}
                alt="Logo"
                style={{ height: '50px', marginRight: '20px' }}
              />
              <div className="flex-row">
                <Modal.Title className="mb-0">
                  Rotary Beldanga Eye Hospital
                </Modal.Title>
                <p className="mb-0 text-sm ">
                  EYE MICRO AND PHACO SURGERY CENTER
                </p>
                <p className="mb-0 text-sm ">
                  PO-Beldanga, PS-Beldanga, Murshidabad
                </p>
              </div>
            </div>
            <div className="flex justify-center">
              <h4 className="text-center">DISCHARGE CERTIFICATE</h4>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Col lg={6} md={6} sm={12}>
              <strong>Patient Name:</strong> {data.name}
            </Col>
            <Col lg={6} md={6} sm={12}>
              <strong>Mobile Number:</strong> {data.mobilenumber}
            </Col>
            <Col lg={6} md={6} sm={6}>
              <strong>Gender:</strong> {data.sex}
            </Col>
            <Col lg={6} md={6} sm={6}>
              <strong>Age:</strong> {data.age}
            </Col>
            <Col lg={12} md={6} sm={12}>
              <strong>Address:</strong> {data.address}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <strong>Date of Admission:</strong> {formatDate(data.date)}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <strong>Date of Discharge:</strong> {formatDate(today)}
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Advice</h5>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <strong>Medicines:</strong>
              {medicines.map((medicine, index) => (
                <div key={index} className="mb-2">
                  <strong>Medicine Name:</strong> {medicine.name}
                  <br />
                  <strong>Instruction:</strong> {medicine.instruction}
                  <br />
                  <strong>Dosage:</strong> {medicine.dosage}
                </div>
              ))}
            </Col>
            <Col>
              <strong>Next Check-Up Date:</strong> {nextVisitDate}
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>Notes:</strong> {narration}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="modal-btn">
          <Button variant="secondary" onClick={handleCloseModal}>
            No
          </Button>
          <Button variant="primary" onClick={handlePrint}>
            Print
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default DischargeDetails;
