import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';
import MyCalendar from '../components/MyCalendar';
import axios from 'axios';
import { FaUserMd, FaUserNurse, FaProcedures, FaClipboardList } from "react-icons/fa";
import './dashboard.css'

function Dashboard() {
  const { company } = useAuth();
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [doctorData, setDoctorData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [patientsData, setPatientsData] = useState([]);
  const [registrationCount, setRegistrationCount] = useState(0);
  const [admissionCount, setAdmissionCount] = useState(0);
  const [error, setError] = useState([]);
  console.log('registrationCount', registrationCount);
  const DoctorDataLoad = async () => {
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/doctor");
      setDoctorData(response.data[0]);
    } catch (error) {
      setError(error.message);
    }
  };

  const StaffDataLoad = async () => {
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/staff");
      setStaffData(response.data[0]);
    } catch (error) {
      setError(error.message);
    }
  };

  const PatientDataLoad = async () => {
    try {
      const admissionResponse = await axios.get("https://hospital-api.hisabdev.com/api/admission");
      const filteredData = admissionResponse.data[0].filter(admission => admission.status === "admitted");
      setPatientsData(filteredData);
    } catch (error) {
      setError(error.message);
    }
  };

  const loadRegistrationCount = async () => {
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/registation/today");
      console.log('API response:', response.data); // Add this line for debugging
      setRegistrationCount(response.data.count);
    } catch (error) {
      console.error('Error loading registration count:', error); // Add this line for debugging
      setError(error.message);
    }
  };

  const loadAdmissionCount = async () => {
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/admission/today");
      setAdmissionCount(response.data.count);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    DoctorDataLoad();
    StaffDataLoad();
    PatientDataLoad();
    loadRegistrationCount();
    loadAdmissionCount();
  }, []);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleAddToHomeScreen = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const choiceResult = await deferredPrompt.userChoice;

      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      setDeferredPrompt(null);
    }
  };

  return (
    <div className="container min-h-screen p-4">
      {deferredPrompt && (
        <div className="fixed bottom-0 right-0 p-4">
          <button onClick={handleAddToHomeScreen} className="bg-blue-500 text-white p-2 rounded">
            Add to Home Screen
          </button>
        </div>
      )}
      <div className='flex items-center justify-start w-full h-full'>
        {/* <p className='text-[30px] text-slate-600 font-bold'> {company?.CName || 'Welcome'}</p> */}
      </div>
      <Row className='pt-4'>
        <Col sm={6} md={4} lg={3}>
          <div className='cards'>
            <div className='card-body'>
              <div className='card-icon'>
                <FaUserMd size={40} />
              </div>
              <div className='card-info'>
                <p className='card-title'>Doctors</p>
                <p className='card-count'>{doctorData.length}</p>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={6} md={4} lg={3}>
          <div className='cards'>
            <div className='card-body'>
              <div className='card-icon'>
                <FaUserNurse size={40} />
              </div>
              <div className='card-info'>
                <p className='card-title'>Staffs</p>
                <p className='card-count'>{staffData.length}</p>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={6} md={4} lg={3}>
          <div className='cards'>
            <div className='card-body'>
              <div className='card-icon'>
                <FaProcedures size={40} />
              </div>
              <div className='card-info'>
                <p className='card-title'>Patients</p>
                <p className='card-count'>{patientsData.length}</p>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={6} md={4} lg={3}>
          <MyCalendar />
        </Col>
        <Col sm={6} md={4} lg={3}>
          <div className='cards'>
            <div className='card-body'>
              <div className='card-icon'>
                <FaClipboardList size={40} />
              </div>
              <div className='card-info'>
                <p className='card-title'>Registrations Today</p>
                <p className='card-count'>{registrationCount}</p>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={6} md={4} lg={3}>
          <div className='cards'>
            <div className='card-body'>
              <div className='card-icon'>
                <FaClipboardList size={40} />
              </div>
              <div className='card-info'>
                <p className='card-title'>Admissions Today</p>
                <p className='card-count'>{admissionCount}</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
