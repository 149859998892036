import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/esm/Button";
import Table from "../../components/Table";
import { useGlobalState } from "../../context/GlobalStateContext";
import { useAuth } from "../../context/AuthContext"
import axios from "axios";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import BedFrom from "../../components/BedFrom";
import { FaPen } from "react-icons/fa";
import GrowExample from "../../components/spiner/GrowExample";
import { parseISO, compareDesc } from "date-fns";


function Counselling() {
  const [data, setData] = useState([]);
  const [OutDoorData, setOutDoorData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [outDoorColumns, setOutDoorColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [createForm, showCreateForm] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(null);


  const sortAppointmentsByDate = (appointments) => {
    return appointments.sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
  };

  const loadData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/counselling");
      setData(sortAppointmentsByDate(response.data[0]));
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const loadOutDoorData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/prescription/outdoorPres");
      const filteredData = response.data[0].filter(reg => reg.operation === "Yes");
      setOutDoorData(sortAppointmentsByDate(filteredData));
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  console.log('OutDoorData', OutDoorData);
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadOutDoorData()
  }, []);

  const deleteContact = (id) => {
    if (
      window.confirm("Are you sure that you wanted to delete that Bed ?")
    ) {
      axios.delete(`https://hospital-api.hisabdev.com/api/bed/removebed/${id}`);
      toast.success("Bed Delete Successfully");
      setTimeout(() => loadData(), 500);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).format(date);
  };

  useEffect(() => {
    setColumns([
      {
        header: "Date",
        accessorKey: "date",
        cell: (rowData) => formatDate(rowData.getValue())
      },
      {
        header: "Name",
        accessorKey: "name",
      },
      {
        header: "Mobile Number",
        accessorKey: "mobilenumber",
      },
      {
        header: "Gender",
        accessorKey: "sex",
      },
      {
        header: "Age",
        accessorKey: "age",
      },
      {
        header: "Note",
        accessorKey: "note",
      },
      {
        header: "Test",
        accessorKey: "tests",
        cell: ({ row }) => {
          const items = row.original.tests;
          return (
            <div>
              {items && items.length > 0 ? (
                items.map((item, index) => (
                  <div key={index}>
                    <strong>Name:</strong> {item.testname}, <strong>Rate:</strong> {item.amount}
                  </div>
                ))
              ) : (
                <div>No items</div>
              )}
            </div>
          );
        }
      },
      {
        header: "Group Test",
        accessorKey: "group_tests",
        cell: ({ row }) => {
          const items = row.original.group_tests;
          return (
            <div>
              {items && items.length > 0 ? (
                items.map((item, index) => (
                  <div key={index}>
                    <strong>Name:</strong> {item.gptestname}, <strong>Rate:</strong> {item.gpamount}
                  </div>
                ))
              ) : (
                <div>No items</div>
              )}
            </div>
          );
        }
      },
      {
        header: "Medicine",
        accessorKey: "medicines",
        cell: ({ row }) => {
          const items = row.original.medicines;
          return (
            <div>
              {items && items.length > 0 ? (
                items.map((item, index) => (
                  <div key={index}>
                    <strong>Name:</strong> {item.name}, <strong>Dosage:</strong> {item.dosage},
                    <strong>Instruction:</strong> {item.instruction}
                  </div>
                ))
              ) : (
                <div>No items</div>
              )}
            </div>
          );
        }
      },
    ]);
    setOutDoorColumns([
      {
        header: "Date",
        accessorKey: "date",
        cell: (rowData) => formatDate(rowData.getValue())
      },
      {
        header: "Name",
        accessorKey: "name",
      },
      {
        header: "Mobile Number",
        accessorKey: "mobilenumber",
      },
      {
        header: "Gender",
        accessorKey: "sex",
      },
      {
        header: "Age",
        accessorKey: "age",
      },
      {
        header: "Note",
        accessorKey: "note",
      },
      {
        header: "Test",
        accessorKey: "tests",
        cell: ({ row }) => {
          const items = row.original.tests;
          return (
            <div>
              {items && items.length > 0 ? (
                items.map((item, index) => (
                  <div key={index}>
                    <strong>Name:</strong> {item.testname}, <strong>Rate:</strong> {item.amount}
                  </div>
                ))
              ) : (
                <div>No items</div>
              )}
            </div>
          );
        }
      },
      {
        header: "Group Test",
        accessorKey: "group_tests",
        cell: ({ row }) => {
          const items = row.original.group_tests;
          return (
            <div>
              {items && items.length > 0 ? (
                items.map((item, index) => (
                  <div key={index}>
                    <strong>Name:</strong> {item.gptestname}, <strong>Rate:</strong> {item.gpamount}
                  </div>
                ))
              ) : (
                <div>No items</div>
              )}
            </div>
          );
        }
      },
      {
        header: "Medicine",
        accessorKey: "medicines",
        cell: ({ row }) => {
          const items = row.original.medicines;
          return (
            <div>
              {items && items.length > 0 ? (
                items.map((item, index) => (
                  <div key={index}>
                    <strong>Name:</strong> {item.name}, <strong>Dosage:</strong> {item.dosage},
                    <strong>Instruction:</strong> {item.instruction}
                  </div>
                ))
              ) : (
                <div>No items</div>
              )}
            </div>
          );
        }
      },
    ]);
  }, []);

  const handleCreateAdmission = () => {
    console.log("create admission");
  };

  const handleViewAdmission = (id) => {
    // You can navigate to a detailed view of the admission using the admission ID
    // Example: navigate(`/admissions/${id}`);
  };

  const handleUpdate = (id) => {
    navigate(`/counselling/${id}`);
  };

  const handleUpdateOutDoor = (data) => {
    console.log('ha', data);
    navigate(`/OutDoor-counselling/${data}`);
  };

  return (
    <div className="container p-2 ">
      <h2 className="text-emerald-900">Hospital Patiant</h2>
      {/* <h2 className="text-xl font-bold mb-4">Bed</h2> */}
      {createForm ? (
        <BedFrom
          onCreate={handleCreateAdmission}
          onCancel={() => showCreateForm(false)}
        />
      ) : (
        <div className="flex justify-between">
          {/* <Button
            className="mb-2 drop-shadow"
            onClick={() => showCreateForm(true)}
          >
            Add New Bed
          </Button> */}
        </div>
      )}
      {isLoading && !data[0] && (
        <div className="w-full h-60 flex justify-center items-center">
          <GrowExample />
        </div>
      )}
      {!createForm &&
        (data[0] ? (
          <div className="drop-shadow">
            <Table
              data={data}
              columns={[
                ...columns,
                {
                  header: "Actions",
                  cell: (rowData) => {
                    return (
                      <div className="flex justify-between">
                        {/* <button
                        onClick={() => handleView(rowData?.row?.original?.id)}
                        className="btn btn-primary btn-sm me-2"
                      >
                        {" "}
                        <FaEye />{" "}
                      </button> */}
                        <button
                          onClick={() =>
                            handleUpdate(rowData?.row?.original?.id)
                          }
                          className="btn btn-primary btn-sm me-2"
                        >
                          {" "}
                          <FaPen />{" "}
                        </button>
                        {/* <button
                          onClick={() =>
                            deleteContact(rowData?.row?.original?.id)
                          }
                          className="btn btn-danger btn-sm"
                        >
                          {" "}
                          <MdDelete />{" "}
                        </button> */}
                      </div>
                    );
                  },
                },
              ]}
              pageLimit={10}
            />{" "}
          </div>
        ) : (
          !isLoading && (
            <p className="text-red-400 font-semibold w-max mx-auto my-20">
              No Counselling Data
            </p>
          )
        ))}
      <div className="mt-4">
        <h2 className="text-emerald-900">Outdoor Patiant</h2>
        {isLoading && !OutDoorData[0] && (
          <div className="w-full h-60 flex justify-center items-center">
            <GrowExample />
          </div>
        )}
        {OutDoorData[0] ? (
          <div className="drop-shadow">
            <Table
              data={OutDoorData}
              columns={[
                ...outDoorColumns,
                {
                  header: "Actions",
                  cell: (rowData) => {
                    return (
                      <div className="flex justify-between">
                        {/* <button
                        onClick={() => handleView(rowData?.row?.original?.id)}
                        className="btn btn-primary btn-sm me-2"
                      >
                        {" "}
                        <FaEye />{" "}
                      </button> */}
                        <button
                          onClick={() =>
                            handleUpdateOutDoor(rowData?.row?.original?.id)
                          }
                          className="btn btn-primary btn-sm me-2"
                        >
                          {" "}
                          <FaPen />{" "}
                        </button>
                        {/* <button
                          onClick={() =>
                            deleteContact(rowData?.row?.original?.id)
                          }
                          className="btn btn-danger btn-sm"
                        >
                          {" "}
                          <MdDelete />{" "}
                        </button> */}
                      </div>
                    );
                  },
                },
              ]}
              pageLimit={10}
            />
          </div>
        ) : (
          !isLoading && (
            <p className="text-red-400 font-semibold w-max mx-auto my-20">
              No Outdoor Prescription Data
            </p>
          )
        )}
      </div>
    </div>
  );
}

export default Counselling;