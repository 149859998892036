// Customers.js 
import React, { useEffect, useState } from "react";
import CreateDoctor from "../../components/CreateDoctor";
import Button from "react-bootstrap/esm/Button";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Table from "../../components/Table";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { FaPen } from "react-icons/fa";
import GrowExample from '../../components/spiner/GrowExample';
import CreateGeneralLedger from "../../components/CreateGeneralLedger";
import CreateVoucher from "../../components/CreateVoucher";

function Voucher() {
  const [data, setData] = useState([]);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname;
  const parts = url.split("/");
  const model = parts[parts.length - 1];
  const [error, setError] = useState(null);

  const loadData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/voucher");
      setData(response.data[0]);
      console.log(response.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [showCreateForm]);

  const deleteContact = (id) => {
    if (
      window.confirm("Are you sure that you wanted to delete that Voucher ?")
    ) {
      axios.delete(`https://hospital-api.hisabdev.com/api/voucher/removevoucher/${id}`);
      toast.success("Voucher Delete Successfully");
      setTimeout(() => loadData(), 500);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).format(date);
  };

  const columns = [
    {
      header: "Type",
      accessorKey: "TYPE",
    },
    {
      header: "Voucher No",
      accessorKey: "TRNNO",
    },
    {
      header: "Voucher Date",
      accessorKey: "TRN_DATE",
      cell: (rowData) => formatDate(rowData.getValue())
    },
    {
      header: "Gl Code",
      accessorKey: "GLCODE",
    },
    {
      header: "Gl Account",
      accessorKey: "DESCRIPT",
    },
    {
      header: "Cash/Bank",
      accessorKey: "CBNAME",
    },
    {
      header: "Narration",
      accessorKey: "NARRATION",
    },
    {
      header: "Pay To",
      accessorKey: "PAYEE_R",
    },
    {
      header: "Cheque No",
      accessorKey: "CHEQUE_NO",
    },
    {
      header: "Drawn No",
      accessorKey: "CHEQUE_ON",
    },
    {
      header: "Cheque Date",
      accessorKey: "CHEQUE_DT",
      cell: (rowData) => formatDate(rowData.getValue())
    },
    {
      header: "Amount",
      accessorKey: "AMOUNT",
    },
  ];

  const handleView = (id) => {
    navigate(`/voucher/${id}`);
  };

  const handleUpdate = (data) => {
    navigate(`/voucher/${data}`);
  };

  return (
    <div className="container p-2 ">
      {showCreateForm ? (
        <CreateVoucher
          onCancel={() => {
            setShowCreateForm(false);
          }}
          customers={data}
          model={model}
        />
      ) : (
        <div className="flex justify-between">
          <Button
            className="mb-2 drop-shadow"
            onClick={() => setShowCreateForm(true)}
          >
            {" "}
            Add {model}
          </Button>
        </div>
      )}
      {isLoading && !data[0] && (
        <div className="w-full h-60 flex justify-center items-center">
          <GrowExample />
        </div>
      )}
      {showCreateForm ? null : data[0] ? (
        <div className="drop-shadow">
          <Table
            data={data}
            columns={[
              ...columns,
              {
                header: "Actions",
                cell: (rowData) => {
                  return (
                    <div className="flex justify-between">
                      {/* <button
                        onClick={() => handleView(rowData?.row?.original?.id)}
                        className="btn btn-primary btn-sm me-2"
                      >
                        {" "}
                        <FaEye />{" "}
                      </button> */}
                      <button
                        onClick={() => handleUpdate(rowData?.row?.original?.id)}
                        className="btn btn-primary btn-sm me-2"
                      >
                        {" "}
                        <FaPen />{" "}
                      </button>
                      <button
                        onClick={() =>
                          deleteContact(rowData?.row?.original?.id)
                        }
                        className="btn btn-danger btn-sm"
                      >
                        {" "}
                        <MdDelete />{" "}
                      </button>
                    </div>
                  );
                },
              },
            ]}
            pageLimit={10}
          />
        </div>
      ) : !isLoading ? (
        <p className="text-red-400 font-semibold w-max mx-auto my-20">
          No Voucher's Data
        </p>
      ) : (
        ""
      )}
    </div>
  );
}

export default Voucher;
