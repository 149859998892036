import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { Input } from "@mui/joy";
import { RxCross2 } from "react-icons/rx";

const initialState = {
  patientname: "",
  tests: [{ name: "", amount: "" }],
  group_tests: [{ name: "", amount: "" }],
  referDrName: "",
  totalAmount: "",
  advancePayment: "0.00",
  duePayment: "0.00",
  date: new Date().toISOString().split("T")[0], // Format as YYYY-MM-DD
  patientnumber: "",
  patientaddress: "",
  agent: "",
  status: "",
  isFullPayment: false,
};

function UpdatePathology({ customers, model }) {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const [doctor, setDoctor] = useState([]);
  // const [gpTests, setGpTests] = useState([
  //   { gptestname: "", gpresult: "", gpamount: 0 },
  // ]);
  const [GroupTestData, setGroupTestData] = useState([]);
  const [agentData, setAgentData] = useState([]);
  const [testData, setTestData] = useState([]);
  const { id } = useParams();
  console.log('state', state);
  useEffect(() => {
    axios.get(`https://hospital-api.hisabdev.com/api/pathology/${id}`).then((resp) => {
      // Ensure the date is formatted correctly
      const fetchedData = resp.data[0];
      fetchedData.date = fetchedData.date.split("T")[0]; // Format as YYYY-MM-DD
      setState({ ...fetchedData });
    });
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      patientname,
      tests,
      group_tests,
      referDrName,
      totalAmount,
      advancePayment,
      duePayment,
      date,
      patientnumber,
      patientaddress,
      agent,
      status
    } = state;

    if (
      !patientname ||
      !referDrName ||
      !totalAmount ||
      !advancePayment ||
      !duePayment ||
      !date ||
      !patientnumber
    ) {
      toast.error("Please provide a value for each input field");
    } else {
      axios
        .put(`https://hospital-api.hisabdev.com/api/pathology/updatepathology/${id}`, {
          patientname,
          tests,
          group_tests,
          referDrName,
          totalAmount,
          advancePayment,
          duePayment,
          date,
          patientnumber,
          patientaddress,
          agent,
          status
        })
        .then(() => {
          setState(initialState);
          toast.success("Update Successfully");
          setTimeout(() => {
            navigate("/pathology");
          }, 500);
        })
        .catch((err) => toast.error(err.response.data));
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "isFullPayment") {
      setState({
        ...state,
        isFullPayment: checked,
        advancePayment: checked ? "0.00" : state.advancePayment,
        duePayment: checked
          ? "0.00"
          : (state.totalAmount - state.advancePayment).toFixed(2),
      });
    } else {
      setState({ ...state, [name]: value });
    }
  };

  const handleTestChange = (index, event, key) => {
    const { name, value } = event.target;
    const newTests = [...state[key]];
    newTests[index][name] = value;
    const selectedTest = [...testData].find((test) => test.testname === value);
    if (selectedTest) {
      newTests[index].amount = selectedTest.amount;
    }
    setState({ ...state, [key]: newTests });
  };

  const handleGpTestChange = (index, event) => {
    const { name, value } = event.target;
    const newgTests = [...state.group_tests];
    newgTests[index][name] = value;
    const selectedgTest = GroupTestData.find((gtest) => gtest.name === value);
    if (selectedgTest) {
      newgTests[index].amount = selectedgTest.PAmount;
    }
    setState((prevState) => ({
      ...prevState,
      group_tests: newgTests,
    }));
  };

  const addTestField = (key) => {
    const newTests = [...state[key], { name: "", amount: "" }];
    setState({ ...state, [key]: newTests });
  };

  const handleDeleteTest = (index, key) => {
    const newTests = [...state[key]];
    newTests.splice(index, 1);
    setState({ ...state, [key]: newTests });
  };

  const handleDeleteGpTest = (index) => {
    const newgTests = [...state.group_tests];
    newgTests.splice(index, 1);
    setState((prevState) => ({
      ...prevState,
      group_tests: newgTests,
    }));
  };

  const addGpTestField = () => {
    const newgTests = [...state.group_tests, { name: "", gpamount: "" }];
    setState((prevState) => ({
      ...prevState,
      group_tests: newgTests,
    }));
  };

  const loadTestData = async () => {
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/test");
      setTestData(response.data[0]);
    } catch (error) {
      console.error("Error loading test data:", error);
    }
  };

  const loadGroupTestData = async () => {
    try {
      const response = await axios.get(
        "https://hospital-api.hisabdev.com/api/test/grouptest"
      );
      setGroupTestData(response.data);
    } catch (error) {
      console.error("Error loading group test data:", error);
    }
  };

  const loadDoctorData = async () => {
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/doctor");
      setDoctor(response.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const loadAgentData = async () => {
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/agent");
      setAgentData(response.data[0]);
    } catch (error) {
      console.error(error.message);
    }
  };

  const calculateTotalAmount = () => {
    const testTotal = state.tests.reduce(
      (total, test) => total + parseFloat(test.amount || 0),
      0
    );
    const groupTestTotal = state.group_tests.reduce(
      (total, gtest) => total + parseFloat(gtest.amount || 0),
      0
    );
    const newTotalAmount = (testTotal + groupTestTotal).toFixed(2);
    const newDuePayment = (
      parseFloat(newTotalAmount) - parseFloat(state.advancePayment)
    ).toFixed(2);

    setState((prevState) => ({
      ...prevState,
      totalAmount: newTotalAmount,
      duePayment: newDuePayment,
    }));
  };

  useEffect(() => {
    loadDoctorData();
    loadAgentData();
    loadTestData();
    loadGroupTestData();
  }, []);

  useEffect(() => {
    calculateTotalAmount();
  }, [state.tests, state.group_tests]);

  useEffect(() => {
    if (state.totalAmount && state.advancePayment) {
      const newDuePayment = (
        parseFloat(state.totalAmount) - parseFloat(state.advancePayment)
      ).toFixed(2);
      setState((prevState) => ({
        ...prevState,
        duePayment: newDuePayment,
      }));
    }
  }, [state.totalAmount, state.advancePayment]);

  return (
    <div className="bg-sky-200 p-4 drop-shadow rounded-xl ml-4 w-11/12">
      <Form onSubmit={handleSubmit}>
        <div className="py-2">
          <Row>
            <Col lg={3} md={6} sm={12}>
              <Form.Group controlId="date">
                <Form.Label className="block text-gray-700 font-medium">
                  Date
                </Form.Label>
                <Input
                  type="date"
                  placeholder="Enter Date"
                  name="date"
                  value={state.date || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group controlId="patientname">
                <Form.Label className="block text-gray-700 font-medium">
                  Patient's Name
                </Form.Label>
                <Input
                  placeholder="Patients name"
                  type="text"
                  name="patientname"
                  value={state.patientname || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group controlId="patientnumber">
                <Form.Label className="block text-gray-700 font-medium">
                  Patient's Mobile Number
                </Form.Label>
                <Input
                  placeholder="Patient's number"
                  type="text"
                  name="patientnumber"
                  value={state.patientnumber || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group controlId="patientaddress">
                <Form.Label className="block text-gray-700 font-medium">
                  Patient's Address
                </Form.Label>
                <Input
                  placeholder="Patient's address"
                  type="text"
                  name="patientaddress"
                  value={state.patientaddress || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={6} sm={12} xs={12}>
              <Form.Group controlId="testsAssigned">
                <Form.Label className="font-semibold">Tests</Form.Label>
                {state.tests.map((test, index) => (
                  <div key={index}>
                    <Row className="my-1">
                      <Col>
                        <Form.Control
                          as="select"
                          name="name"
                          value={test.name}
                          onChange={(e) => handleTestChange(index, e, "tests")}
                        >
                          <option value="">Select Test</option>
                          {testData.map((option) => (
                            <option
                              key={option.testname}
                              value={option.testname}
                            >
                              {option.testname}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          name="remarks"
                          placeholder="Remarks"
                          value={`₹${test.amount}`}
                          readOnly
                        />
                      </Col>
                      {index !== 0 ? (
                        <Col lg={2}>
                          <Button
                            variant="danger"
                            onClick={() => handleDeleteTest(index, "tests")}
                          >
                            <RxCross2 />
                          </Button>
                        </Col>
                      ) : (
                        <Col lg={2}></Col>
                      )}
                    </Row>
                  </div>
                ))}
              </Form.Group>
              <Button
                className="w-full my-2"
                variant="outline-primary"
                onClick={() => addTestField("tests")}
              >
                Add Test
              </Button>
            </Col>
            {/* <Col lg={6} sm={12} xs={12}>
              <Form.Group controlId="group_testsAssigned">
                <Form.Label className="font-semibold">Group Tests</Form.Label>
                {state.group_tests.map((groupTest, index) => (
                  <div key={index}>
                    <Row className="my-1">
                      <Col>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Group Test Name"
                          value={groupTest.name}
                          onChange={(e) => handleTestChange(index, e, "group_tests")}
                        />
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          name="amount"
                          placeholder="Amount"
                          value={groupTest.amount}
                          onChange={(e) => handleTestChange(index, e, "group_tests")}
                        />
                      </Col>
                      {index !== 0 ? (
                        <Col lg={2}>
                          <Button
                            variant="danger"
                            onClick={() => handleDeleteTest(index, "group_tests")}
                          >
                            <RxCross2 />
                          </Button>
                        </Col>
                      ) : (
                        <Col lg={2}></Col>
                      )}
                    </Row>
                  </div>
                ))}
              </Form.Group>
              <Button
                className="w-full my-2"
                variant="outline-primary"
                onClick={() => addTestField("group_tests")}
              >
                Add Group Test
              </Button>
            </Col> */}
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="testsAssigned">
                <Form.Label className="font-semibold">Group Tests</Form.Label>
                {state.group_tests.map((gtest, index) => (
                  <div key={index}>
                    <Row className="my-1">
                      <Col>
                        <Form.Control
                          as="select"
                          name="testname"
                          value={gtest.name}
                          onChange={(e) => handleGpTestChange(index, e)}
                        >
                          <option value="">Select Test</option>
                          {GroupTestData.map((group) => (
                            <option key={group.name} value={group.name}>
                              {group.name}
                              {/* -₹{group.PAmount} */}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          name="remarks"
                          placeholder="Remarks"
                          value={`₹${gtest.amount}`}
                          readOnly
                        />
                      </Col>
                      {index !== 0 ? (
                        <Col lg={2}>
                          <Button
                            variant="danger"
                            onClick={() => handleDeleteGpTest(index)}
                          >
                            <RxCross2 />
                          </Button>
                        </Col>
                      ) : (
                        <Col lg={2}></Col>
                      )}
                    </Row>
                  </div>
                ))}
              </Form.Group>
              <Button
                className="w-full my-2"
                variant="outline-primary"
                onClick={addGpTestField}
              >
                Add Group Test
              </Button>
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={4} sm={12} xs={12}>
              <Form.Group controlId="referDrName">
                <Form.Label className="block text-gray-700 font-medium">
                  Refer Doctor <span className="required-icon">*</span>
                </Form.Label>
                <Form.Control
                  required
                  as="select"
                  placeholder="Select a Doctor"
                  value={state.referDrName || ""}
                  name="referDrName"
                  onChange={handleInputChange}
                >
                  <option value="">Select Doctor</option>
                  {doctor.map((item) => (
                    <option key={item.doctorname} value={item.doctorname}>
                      {item.doctorname} ({item.designation})
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="agent">
                <Form.Label className="block text-gray-700 font-medium">
                  Agent <span className="required-icon">*</span>
                </Form.Label>
                <Form.Control
                  required
                  as="select"
                  name="agent"
                  value={state.agent || ""}
                  onChange={handleInputChange}
                >
                  <option value="">Select Agent</option>
                  {agentData.map((agent) => (
                    <option key={agent.name} value={agent.name}>
                      {agent.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group controlId="totalAmount">
                <Form.Label className="block text-gray-700 font-medium">
                  Total Amount
                </Form.Label>
                <Input
                  placeholder="Total Amount"
                  type="text"
                  name="totalAmount"
                  value={state.totalAmount || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group controlId="advancePayment">
                <Form.Label className="block text-gray-700 font-medium">
                  Advance Payment
                </Form.Label>
                <Input
                  placeholder="Advance Payment"
                  type="text"
                  name="advancePayment"
                  value={state.advancePayment || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group controlId="duePayment">
                <Form.Label className="block text-gray-700 font-medium">
                  Due Payment
                </Form.Label>
                <Input
                  placeholder="Due Payment"
                  type="text"
                  name="duePayment"
                  value={state.duePayment || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group controlId="isFullPayment">
                <Form.Check
                  type="checkbox"
                  label="Full Payment"
                  name="isFullPayment"
                  checked={state.isFullPayment}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="flex justify-content-between my-4">
            <Button
              className="btn bg-red-500 btn-danger mx-2 my-2"
              onClick={() => navigate("/pathology")}
            >
              Cancel
            </Button>
            <Button type="submit" className="btn  mx-2 my-2">
              Update
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default UpdatePathology;
