import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { Input } from "@mui/joy";

const initialState = {
  TYPE: "",
  TRNNO: "",
  TRN_DATE: "",
  GLCODE: "",
  DESCRIPT: "",
  CBNAME: "",
  NARRATION: "",
  PAYEE_R: "",
  CHEQUE_ON: "",
  CHEQUE_NO: "",
  CHEQUE_DT: "",
  AMOUNT: "",
};

function CreateVoucher({ onCreate, onCancel, customers, model }) {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  // const [doctorCode, setDoctorCode] = useState([]);
  const [Ptype, setType] = useState("");

  console.log("snehamgshu", state);

  const {
    TYPE,
    TRNNO,
    TRN_DATE,
    GLCODE,
    DESCRIPT,
    CBNAME,
    NARRATION,
    PAYEE_R,
    CHEQUE_ON,
    CHEQUE_NO,
    CHEQUE_DT,
    AMOUNT,
  } = state;

  const { id } = useParams();

  // useEffect(() => {
  //   const fetchNextDoctorCode = async () => {
  //     try {
  //       const response = await axios.get(
  //         "https://hospital-api.hisabdev.com/api/doctor/nextledgercode"
  //       );
  //       setDoctorCode(response.data.DoctorCode);
  //     } catch (error) {
  //       console.error("Error fetching next product code:", error);
  //       toast.error("Error fetching next product code");
  //     }
  //   };

  //   fetchNextDoctorCode();
  // }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!TYPE || !GLCODE) {
      toast.error("Please provide value into each input field");
    } else {
      if (!id) {
        axios
          .post("https://hospital-api.hisabdev.com/api/voucher/createvoucher", {
            TYPE,
            TRNNO,
            TRN_DATE,
            GLCODE,
            DESCRIPT,
            CBNAME,
            NARRATION,
            PAYEE_R,
            CHEQUE_ON,
            CHEQUE_NO,
            CHEQUE_DT,
            AMOUNT,
          })
          .then(() => {
            setState(initialState);
          })
          .catch((err) => toast.error(err.response.data));
        toast.success("Voucher created successfully");
      }
      setTimeout(() => {
        onCancel();
      }, 500);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
    setState({ ...state, TYPE: e.target.value });
  };

  return (
    <div className="bg-sky-200 p-4 drop-shadow rounded-xl">
      <h2 className="text-xl font-semibold">Create {model}</h2>
      <Form onSubmit={handleSubmit}>
        <div className="py-2">
          <Row>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Type
                </Form.Label>
                <Form.Control
                  required
                  as="select"
                  name="TYPE"
                  value={Ptype}
                  onChange={handleTypeChange}
                >
                  <option value="">Select Type</option>
                  <option value="payment">Payment</option>
                  <option value="received">Received</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Voucher No
                </Form.Label>
                <Input
                  required
                  type="text"
                  placeholder="Voucher No"
                  id="TRNNO"
                  name="TRNNO"
                  value={TRNNO || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Date
                </Form.Label>
                <Input
                  required
                  type="date"
                  placeholder="Date"
                  id="TRN_DATE"
                  name="TRN_DATE"
                  value={TRN_DATE || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={2} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  GL Code
                </Form.Label>
                <Input
                  required
                  type="text"
                  placeholder="GL Code"
                  name="GLCODE"
                  value={GLCODE || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  GL Account
                </Form.Label>
                <Input
                  required
                  type="text"
                  placeholder="GL Account"
                  name="DESCRIPT"
                  value={DESCRIPT || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Cash/Bank
                </Form.Label>
                <Input
                  required
                  type="text"
                  placeholder="Cash/Bank"
                  name="CBNAME"
                  value={CBNAME || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  {Ptype === "payment"
                    ? "Paid To"
                    : Ptype === "received"
                      ? "Received From"
                      : "Paid To"}
                </Form.Label>
                <Input
                  type="text"
                  placeholder={
                    Ptype === "payment"
                      ? "Paid To"
                      : Ptype === "received"
                        ? "Received From"
                        : "Paid To"
                  }
                  name="PAYEE_R"
                  value={PAYEE_R || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={9} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Narration
                </Form.Label>
                <Input
                  type="text"
                  placeholder="Narration"
                  name="NARRATION"
                  value={NARRATION || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Cheque No
                </Form.Label>
                <Input
                  type="text"
                  placeholder="Cheque No"
                  name="CHEQUE_NO"
                  value={CHEQUE_NO || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Dated
                </Form.Label>
                <Input
                  type="date"
                  name="CHEQUE_DT"
                  value={CHEQUE_DT || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Drawn No
                </Form.Label>
                <Input
                  type="text"
                  placeholder="Drawn No"
                  name="CHEQUE_ON"
                  value={CHEQUE_ON || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Amount Rs
                </Form.Label>
                <Input
                  required
                  type="number"
                  placeholder="Amount Rs"
                  name="AMOUNT"
                  value={AMOUNT || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="flex justify-content-between my-4">
          <Button
            variant="danger"
            onClick={onCancel}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
          >
            Cancel
          </Button>
          <Button type="submit" className="px-4 py-2">
            Create
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default CreateVoucher;
