import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import axios from 'axios';
import { Input } from '@mui/joy';

const initialState = {
  agcode: "",
  name: "",
  mobilenumber: "",
  percentage: ""
};


function AgentDetailsUpdate({ onCreate, onCancel, customers, model }) {

  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const {
    agcode,
    name,
    mobilenumber,
    percentage
  } = state;
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`https://hospital-api.hisabdev.com/api/agent/${id}`)
      .then((resp) => setState({ ...resp.data[0] }));
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !mobilenumber) {
      toast.error("Please provide value into each inpute field ");
    } else {
      if (id) {
        axios
          .put(`https://hospital-api.hisabdev.com/api/agent/updateagent/${id}`, {
            agcode,
            name,
            mobilenumber,
            percentage
          })
          .then(() => {
            setState(initialState);
          })
          .catch((err) => toast.error(err.respose.data));
        toast.success("update Successfully");
      }
      setTimeout(() => { navigate('/agent') }, 500);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  return (
    <div className='bg-sky-200 p-4 drop-shadow rounded-xl ml-4 w-11/12'>
      {/* <h2 className="text-xl font-semibold ">Create {model}</h2> */}
      <Form onSubmit={handleSubmit}>
        <div className=' py-2'>
          <Row>
            <Col lg={2} md={6} sm={12}>
              <Form.Group controlId="PATIENT_ID">
                <Form.Label className="block text-gray-700 font-medium">Agent's Code</Form.Label>
                <Input
                  className=''
                  type="text"
                  name="doctorname"
                  value={agcode || ''}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group>
                <Form.Label className=" text-gray-700 font-medium">Agent's Name</Form.Label>
                <Input
                  type="text"
                  placeholder='Agent,s Name'
                  name="name"
                  value={name || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className=" text-gray-700 font-medium">Mobile Number</Form.Label>
                <Input
                  type="text"
                  placeholder='Mobile Number'
                  name="mobilenumber"
                  value={mobilenumber || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className=" text-gray-700 font-medium">Percentage</Form.Label>
                <Input
                  type="text"
                  placeholder='Percentage'
                  name="percentage"
                  value={percentage || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="flex justify-content-between my-4">
          <Button
            variant='danger'
            onClick={() => navigate('/doctor')}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="px-4 py-2"
            onClick={() => console.log('click')}
          >
            {/* { id ? "Update" : "save"} */}
            Update
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default AgentDetailsUpdate;



