import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DynamicTable from "./DynamicTable";

const initialState = {
  InvDate: "",
  PartyInvNo: "",
  PurchaseInvDate: "",
  VendorCode: "",
  VendorName: "",
  items: [],
};

const PurchaseForm = ({ onCreate, onCancel }) => {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const [tableData, setTableData] = useState([
    [null, null, null, null, null, null, null, null, null, []],
  ]);
  const { id } = useParams();
  const [purchaseCode, setPurchaseCode] = useState("");

  const { InvDate, PartyInvNo, PurchaseInvDate, VendorCode, VendorName } = state;

  useEffect(() => {
    const fetchNextPurchaseCode = async () => {
      try {
        const response = await axios.get(
          "https://hospital-api.hisabdev.com/api/purchase/nextpurchasescode"
        );
        setPurchaseCode(response.data.PurchasesCode);
      } catch (error) {
        console.error("Error fetching next purchase code:", error);
        toast.error("Error fetching next purchase code");
      }
    };

    fetchNextPurchaseCode();
  }, []);

  useEffect(() => {

    const generateUniqueVandorCode = () => {
      // For simplicity, generating a random 4-digit number
      const randomNumber = Math.floor(1000 + Math.random() * 9000);
      return `VN${randomNumber}`;
    };

    setState((prevState) => ({
      ...prevState,
      VendorCode: generateUniqueVandorCode()
    }));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!VendorName || !VendorCode) {
      toast.error("Please provide a value for each input field");
    } else {
      const purchaseData = {
        InvDate,
        PartyInvNo,
        PurchaseInvDate,
        VendorCode,
        VendorName,
        items: tableData.map((row) => ({
          ItemCode: purchaseCode,
          Description: row[1],
          Quantity: row[2],
          UOM: row[3],
          Rate: row[4],
          ProductTotal: row[5],
          GSTRate: row[6],
          GSTAmount: row[7],
          Amount: row[8],
          Batches: row[9] ? row[9].map(batch => ({
            productCode: batch.productCode || row[0],
            productName: row[1],
            batchNumber: batch.batchNumber,
            mfgDate: batch.mfgDate,
            expDate: batch.expDate,
            quantity: batch.quantity
          })) : []
        })),
      };

      axios
        .post("https://hospital-api.hisabdev.com/api/purchase/createpurchase", purchaseData)
        .then(() => {
          setState(initialState);
          setTableData([
            [null, null, null, null, null, null, null, null, null, []],
          ]);
          toast.success("Purchase Successfully Created");
        })
        .catch((err) => toast.error(err.response.data));

      setTimeout(() => {
        onCancel();
      }, 500);
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  return (
    <div className="bg-sky-200 p-4 drop-shadow rounded-xl">
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col lg={4} md={6} sm={12}>
            <Form.Group as={Col}>
              <Form.Label>Purchase Inv No.<span className="required-icon">*</span></Form.Label>
              <Form.Control
                required
                type="text"
                name="PurchaseInvNo"
                value={purchaseCode}
                readOnly
              />
            </Form.Group>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <Form.Group as={Col}>
              <Form.Label>Inv Date<span className="required-icon">*</span></Form.Label>
              <Form.Control
                required
                type="date"
                name="InvDate"
                value={InvDate || ""}
                onChange={handleInputChange}
                placeholder="GRN Date"
              />
            </Form.Group>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <Form.Group as={Col}>
              <Form.Label>Party Inv No.<span className="required-icon">*</span></Form.Label>
              <Form.Control
                required
                type="text"
                name="PartyInvNo"
                value={PartyInvNo || ""}
                onChange={handleInputChange}
                placeholder="Enter Type"
              />
            </Form.Group>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <Form.Group as={Col}>
              <Form.Label>Party Inv Date<span className="required-icon">*</span></Form.Label>
              <Form.Control
                required
                type="date"
                name="PurchaseInvDate"
                value={PurchaseInvDate || ""}
                onChange={handleInputChange}
                placeholder="Enter Type"
              />
            </Form.Group>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <Form.Group as={Col}>
              <Form.Label>Vendor Code<span className="required-icon">*</span></Form.Label>
              <Form.Control
                required
                readOnly
                type="text"
                name="VendorCode"
                value={VendorCode || ""}
                onChange={handleInputChange}
                placeholder="Vendor Code"
              />
            </Form.Group>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <Form.Group as={Col}>
              <Form.Label>Vendor Name<span className="required-icon">*</span></Form.Label>
              <Form.Control
                required
                type="text"
                name="VendorName"
                value={VendorName || ""}
                onChange={handleInputChange}
                placeholder="Vendor Name"
              />
            </Form.Group>
          </Col>
          <Col lg={12} md={12} sm={12}>
            <Form.Label></Form.Label>
            <DynamicTable tableData={tableData} setTableData={setTableData} />
          </Col>
        </Row>
        <div className="flex justify-content-between my-4">
          <Button
            variant="danger"
            onClick={onCancel}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default PurchaseForm;
