import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/esm/Button";
import { Spinner } from "react-bootstrap";
import Table1 from "../../components/Table1";
import { useGlobalState } from "../../context/GlobalStateContext";
import CreateAppointmentForm from "../../components/CreateAppoinment";
import axios from "axios";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import CreateAdmitpatiant from "../../components/CreateAdmitpatiant";
import { FaPen } from "react-icons/fa";
import { LuChevronRightSquare } from "react-icons/lu";
import { parseISO, compareDesc } from "date-fns";



function Discharge() {

  const { state, dispatch } = useGlobalState();
  const [admissionData, setAdmissionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [createForm, showCreateForm] = useState(false);
  const [error, setError] = useState(null);
  const [showRegistrationTable, setShowRegistrationTable] = useState(true);


  const navigate = useNavigate();

  const sortAppointmentsByDate = (appointments) => {
    return appointments.sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
  };

  const loadData = async () => {
    try {
      const admissionResponse = await axios.get(
        "https://hospital-api.hisabdev.com/api/admission"
      );
      // Filter the data to only include admissions with status 'admitted'
      const filteredData = admissionResponse.data[0].filter(admission => admission.status === "admitted");
      setAdmissionData(sortAppointmentsByDate(filteredData));
      console.log(filteredData);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    loadData();
  }, [createForm]);

  const deleteContact = (id) => {
    if (
      window.confirm("Are you sure that you wanted to delete that Appoinment ?")
    ) {
      axios.delete(`https://hospital-api.hisabdev.com/api/admission/removeadmission/${id}`);
      toast.success("Admit Patiant Delete Successfully");
      setTimeout(() => loadData(), 500);
    }
  };



  const columns2 = [
    {
      header: "Date",
      accessorKey: "date",
    },
    {
      header: "Patiant Name",
      accessorKey: "name",
    },
    {
      header: "Age",
      accessorKey: "age",
    },
    {
      header: "Gender",
      accessorKey: "sex",
    },
    {
      header: "Mobile Number",
      accessorKey: "mobilenumber",
    },
    {
      header: "Doctor Name",
      accessorKey: "doctor",
    },
    {
      header: "Time",
      accessorKey: "time",
    },
    {
      header: "Pin Code",
      accessorKey: "pincode",
    },
    {
      header: "Block",
      accessorKey: "block",
    },
    {
      header: "Address",
      accessorKey: "address",
    },
    {
      header: "Bed",
      accessorKey: "bed",
    },
    {
      header: "Package",
      accessorKey: "packages",
    },
  ];

  const handleViewAppointment = (id) => {
    // You can navigate to a detailed view of the appointment using the appointment ID
    // Example: navigate(`/appointments/${id}`);
    navigate(`/appointments/${id}`);
  };

  const handleUpdate = (data) => {
    navigate(`/discharge/${data}`)
  };

  return (
    <div className="container p-2 ">
      <h2 className="text-xl font-bold mb-4">Discharge</h2>
      {createForm ? (
        <CreateAdmitpatiant
          onCancel={() => {
            showCreateForm(false);
          }}
        />
      ) : (
        <div className="flex justify-between mb-2">
          {/* <Button
              className="drop-shadow"
              onClick={() => showCreateForm(true)}
            >
              Register New Discharge
            </Button> */}
        </div>
      )}
      {showRegistrationTable && !createForm && (

        <div className="drop-shadow">
          <Table1
            data={admissionData}
            columns={[
              {
                header: "No.",
                cell: (row) => {
                  return <>{row.cell.row.index + 1}</>;
                },
              },
              ...columns2,
              {
                header: "Actions",
                cell: (rowData) => (
                  <div className="flex justify-between">
                    {/* <button
                      onClick={() =>
                        handleViewAppointment(rowData?.row?.original?.id)
                      }
                      className="btn btn-primary btn-sm me-2"
                    >
                      {" "}
                      <FaEye />{" "}
                    </button> */}
                    <button
                      onClick={() =>
                        handleUpdate(rowData?.row?.original?.id)
                      }
                      className="btn btn-primary btn-sm me-2"
                    >
                      {" "}
                      <LuChevronRightSquare />{" "}
                    </button>
                    {/* <button
                      onClick={() =>
                        deleteContact(rowData?.row?.original?.id)
                      }
                      className="btn btn-danger btn-sm"
                    >
                      {" "}
                      <MdDelete />{" "}
                    </button> */}
                  </div>
                ),
              },
            ]}
            pageLimit={10}
          />
        </div>
      )}
    </div>
  );
}

export default Discharge;
