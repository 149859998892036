import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Table, Form, Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DynamicTable = ({ tableData, setTableData }) => {
  const [productData, setProductData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedProductDetails, setSelectedProductDetails] = useState(null);
  const [openedModalRowIndex, setOpenedModalRowIndex] = useState(null);
  const [purchaseCode, setPurchasesCodeData] = useState();
  const [modalRows, setModalRows] = useState([{ productCode: "", batchNumber: "", mfgDate: "", expDate: "", quantity: "" }]); // Initialize with one empty row
  const quantityInputRef = useRef(null);

  const loadProductData = async () => {
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/product");
      setProductData(response.data[0]);
    } catch (error) {
      console.error("Error loading product data:", error);
      toast.error("Error loading product data");
    }
  };

  useEffect(() => {
    loadProductData();
  }, []);

  useEffect(() => {
    const fetchNextPurchasesCode = async () => {
      try {
        const response = await axios.get(
          "https://hospital-api.hisabdev.com/api/purchase/nextpurchasescode"
        );
        setPurchasesCodeData(response.data.PurchasesCode);
      } catch (error) {
        console.error("Error fetching next purchases code:", error);
        toast.error("Error fetching next purchases code");
      }
    };

    fetchNextPurchasesCode();
  }, []);

  const openModal = (rowIndex) => {
    const productDescription = tableData[rowIndex][1];
    const selectedProduct = productData.find(
      (product) => product.Description === productDescription
    );

    if (!selectedProduct) {
      toast.error("Please select a product before adding batch details.");
      return;
    }

    setShowModal(true);
    setOpenedModalRowIndex(rowIndex);

    if (selectedProduct) {
      setSelectedProductDetails(selectedProduct);
      const modalRow = { productCode: selectedProduct.productcode, batchNumber: "", mfgDate: "", expDate: "", quantity: "" };
      setModalRows([modalRow]);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setOpenedModalRowIndex(null);
    setModalRows([{ productCode: "", batchNumber: "", mfgDate: "", expDate: "", quantity: "" }]); // Reset rows when closing modal
  };

  const handleQuantityFocus = (rowIndex) => {
    const productDescription = tableData[rowIndex][1];
    const quantity = parseFloat(tableData[rowIndex][2]) || 0; // Adjusted column index

    if (productDescription && quantity > 0 && !openedModalRowIndex) {
      openModal(rowIndex);
    }
  };

  const handleCellChange = (value, rowIndex, colIndex) => {
    const updatedData = [...tableData];
    updatedData[rowIndex][colIndex] = value;

    if (colIndex === 1) {
      const selectedProduct = productData.find(
        (product) => product.Description === value
      );
      if (selectedProduct) {
        setSelectedProductDetails(selectedProduct);
        updatedData[rowIndex][3] = selectedProduct.purchesunit;
        updatedData[rowIndex][4] = selectedProduct.buyrate;
        updatedData[rowIndex][6] = selectedProduct.taxcategory;
      }
    }

    if (colIndex === 2) {
      const productDescription = updatedData[rowIndex][1];
      const quantity = parseFloat(value) || 0;
      if (quantity < 0) {
        openModal(rowIndex);
      }
      if (productDescription && quantity >= 0) {
        const selectedProduct = productData.find(
          (product) => product.Description === productDescription
        );

        if (quantity) {
          const rate = parseFloat(selectedProduct.buyrate) || 0;
          const productTotal = quantity * rate;
          updatedData[rowIndex][5] = productTotal;
          const gstRate = parseFloat(selectedProduct.taxcategory) || 0;
          const gstAmount = (productTotal * gstRate) / 100;
          updatedData[rowIndex][7] = gstAmount;
          updatedData[rowIndex][8] = productTotal + gstAmount;
        }
      }
    }
    setTableData(updatedData);
  };

  const addRow = () => {
    const newEmptyRow = [null, null, null, null, null, null, null, null, null, null];
    setTableData([...tableData, newEmptyRow]);
  };

  const handleInputChange = (e, rowIndex, columnName) => {
    const updatedModalRows = [...modalRows];
    const value = e.target.value;
    updatedModalRows[rowIndex][columnName] = value;
    setModalRows(updatedModalRows);
  };

  const handleSave = () => {
    const totalModalQuantity = modalRows.reduce((total, row) => {
      return total + (parseFloat(row.quantity) || 0);
    }, 0);

    const mainTableQuantity = parseFloat(tableData[openedModalRowIndex][2]) || 0; // Adjust column index if necessary

    if (totalModalQuantity !== mainTableQuantity) {
      toast.error("Quantity in the modal rows must match the Quantity in the main table row.");
      return;
    }

    const updatedTableData = [...tableData];
    updatedTableData[openedModalRowIndex][9] = modalRows; // Adjust column index if necessary
    setTableData(updatedTableData);

    closeModal();
  };

  const handleAddModalRow = () => {
    setModalRows([...modalRows, { productCode: selectedProductDetails.productcode, batchNumber: "", mfgDate: "", expDate: "", quantity: "" }]);
  };

  return (
    <div>
      <div className="table-responsive mt-4">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Serial</th>
              <th>Inv No.</th>
              <th>Product</th>
              <th>Quantity</th>
              <th>UOM</th>
              <th>Rate</th>
              <th>Product Total</th>
              <th>GST Rate</th>
              <th>GST Amount</th>
              <th>Amount</th>
              <th>Batch Details</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>{rowIndex + 1}</td>
                {row.map((cell, colIndex) => (
                  <td key={colIndex}>
                    {colIndex === 0 ? (
                      <span>{purchaseCode || "00"}</span>
                    ) : colIndex === 1 ? (
                      <Form.Control
                        as="select"
                        value={cell || ""}
                        onChange={(e) =>
                          handleCellChange(e.target.value, rowIndex, colIndex)
                        }
                      >
                        <option value="">Select Product</option>
                        {productData.map((product) => (
                          <option key={product.Id} value={product.Description}>
                            {product.Description}
                          </option>
                        ))}
                      </Form.Control>
                    ) : colIndex === 2 ? (
                      <div className="flex">
                        <Form.Control
                          type="text"
                          value={cell || ""}
                          onChange={(e) =>
                            handleCellChange(e.target.value, rowIndex, colIndex)
                          }
                          ref={quantityInputRef}
                          onFocus={() => {
                            handleQuantityFocus(rowIndex);
                          }}
                        />
                        <Button variant="outline-secondary" onClick={() => openModal(rowIndex)}>
                          Batch
                        </Button>
                      </div>
                    ) : colIndex === 4 ? (
                      <Form.Control
                        type="text"
                        value={cell || ""}
                        onChange={(e) =>
                          handleCellChange(e.target.value, rowIndex, colIndex)
                        }
                        ref={quantityInputRef}
                        onFocus={() => {
                          handleQuantityFocus(rowIndex);
                        }}
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        value={cell || ""}
                        onChange={(e) =>
                          handleCellChange(e.target.value, rowIndex, colIndex)
                        }
                      />
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Button variant="outline-primary" onClick={addRow}>
        Add Row
      </Button>
      <Modal size="xl" show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          {openedModalRowIndex !== null &&
            tableData[openedModalRowIndex] !== undefined ? (
            <Modal.Title>
              {selectedProductDetails ? (
                <>
                  Product Name: {selectedProductDetails.Description} <br />
                  Product Code: {selectedProductDetails.productcode} <br />
                  Quantity: {tableData[openedModalRowIndex][2] || "Enter the quantity"} {/* Adjust column index */}
                </>
              ) : (
                <p className="text-red-500">Please select a product</p>
              )}
            </Modal.Title>
          ) : (
            <Modal.Title>Error: Row data is unavailable</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {openedModalRowIndex !== null && (
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th>SL</th>
                    <th>Batch Numbers</th>
                    <th>Mfg Date</th>
                    <th>Exp Date</th>
                    <th>Qty</th>
                  </tr>
                </thead>
                <tbody>
                  {modalRows.map((row, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <input
                          type="text"
                          placeholder="Enter Batch Number"
                          value={row.batchNumber}
                          onChange={(e) =>
                            handleInputChange(e, index, "batchNumber")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="date"
                          value={row.mfgDate}
                          onChange={(e) =>
                            handleInputChange(e, index, "mfgDate")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="date"
                          value={row.expDate}
                          onChange={(e) =>
                            handleInputChange(e, index, "expDate")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          min="1"
                          placeholder="Qty"
                          value={row.quantity}
                          onChange={(e) =>
                            handleInputChange(e, index, "quantity")
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Button
                variant="secondary"
                onClick={handleAddModalRow}
              >
                Add Row
              </Button>
            </div>
          )}
          {openedModalRowIndex === null && (
            <p>No row selected to edit batch details.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DynamicTable;
