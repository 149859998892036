import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { Input } from "@mui/joy";

const initialState = {
  productcode: "",
  Description: "",
  purchesunit: "",
  Stock: "",
  sale: "",
  hsnsaccode: "",
  productgroup: "",
  productsubgroup: "",
  taxcategory: "",
  buyrate: "",
  salerate: "",
  opening: "",
  expdate: "",
  batchnumber: "",
  lancecode: "",
  hasBatchNumber: false, // Add this line
};

function CreateProduct({ onCreate, onCancel, model }) {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const [productCode, setProductCode] = useState("");
  const [loading, setLoading] = useState(false);

  console.log("state", state);

  const {
    productcode,
    Description,
    purchesunit,
    Stock,
    sale,
    hsnsaccode,
    productgroup,
    productsubgroup,
    taxcategory,
    buyrate,
    salerate,
    opening,
    expdate,
    batchnumber,
    lancecode,
    hasBatchNumber,
  } = state;

  useEffect(() => {
    const fetchNextProductCode = async () => {
      try {
        const response = await axios.get(
          "https://hospital-api.hisabdev.com/api/product/nextproductcode"
        );
        setProductCode(response.data.productCode);
      } catch (error) {
        console.error("Error fetching next product code:", error);
        toast.error("Error fetching next product code");
      }
    };

    fetchNextProductCode();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!Description) {
      toast.error("Please provide a value for each input field");
    } else {
      setLoading(true);
      const data = {
        Description,
        purchesunit,
        Stock,
        sale,
        hsnsaccode,
        productgroup,
        productsubgroup,
        taxcategory,
        buyrate,
        salerate,
        opening,
        hasBatchNumber,
        expdate: hasBatchNumber ? expdate : "",
        batchnumber: hasBatchNumber ? batchnumber : "",
        lancecode: !hasBatchNumber ? lancecode : "",
      };
      try {
        const response = await axios.post(
          "https://hospital-api.hisabdev.com/api/product/createproduct",
          data
        );
        setState(initialState);
        toast.success(
          "Medicine created successfully with Product Code: " + productCode
        );
      } catch (err) {
        toast.error(err.response.data);
      } finally {
        setLoading(false);
      }
      setTimeout(() => {
        onCancel();
      }, 500);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleBatchNumberChange = (e) => {
    const hasBatch = e.target.value === "yes";
    setState({ ...state, hasBatchNumber: hasBatch });
  };

  return (
    <div className="bg-sky-200 p-4 drop-shadow rounded-xl">
      <h2 className="text-xl font-semibold ">Create {model}</h2>
      <Form onSubmit={handleSubmit}>
        <div className="py-2">
          <Row>
            <Col lg={4} md={6} sm={12}>
              <Form.Group controlId="PRODUCT_CODE">
                <Form.Label className="block text-gray-700 font-medium">
                  Product Code
                </Form.Label>
                <Input
                  className=""
                  type="text"
                  name="productcode"
                  value={productCode}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Description<span className="required-icon">*</span>
                </Form.Label>
                <Input
                  required
                  type="text"
                  placeholder="Description"
                  name="Description"
                  value={Description || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Purches Unit<span className="required-icon">*</span>
                </Form.Label>
                <Input
                  required
                  type="text"
                  placeholder="Purchesunit"
                  name="purchesunit"
                  value={purchesunit || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Stock
                </Form.Label>
                <Input
                  type="text"
                  placeholder="Stock"
                  name="Stock"
                  value={Stock || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Sale
                </Form.Label>
                <Input
                  type="text"
                  placeholder="Sale"
                  name="sale"
                  value={sale || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  HSN/SAC code<span className="required-icon">*</span>
                </Form.Label>
                <Input
                  required
                  type="text"
                  placeholder="Hsn/Sac Code"
                  name="hsnsaccode"
                  value={hsnsaccode || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={4} sm={6} xs={6}>
              <Form.Group>
                <Form.Label className="block text-gray-700 font-medium">
                  VAT / GST Rate
                </Form.Label>
                <Input
                  type="text"
                  placeholder="Select Block"
                  name="taxcategory"
                  value={taxcategory || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Group<span className="required-icon">*</span>
                </Form.Label>
                <Input
                  required
                  type="text"
                  placeholder="Product Group"
                  name="productgroup"
                  value={productgroup || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Sub-Group
                </Form.Label>
                <Input
                  type="text"
                  placeholder="Product sub-group"
                  name="productsubgroup"
                  value={productsubgroup || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Buy Rate<span className="required-icon">*</span>
                </Form.Label>
                <Input
                  required
                  type="number"
                  placeholder="Buy Rate"
                  name="buyrate"
                  value={buyrate || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Sale Rate<span className="required-icon">*</span>
                </Form.Label>
                <Input
                  required
                  type="number"
                  placeholder="Sale Rate"
                  name="salerate"
                  value={salerate || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Opening<span className="required-icon">*</span>
                </Form.Label>
                <Input
                  required
                  type="text"
                  placeholder="Opening"
                  name="opening"
                  value={opening || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Batch Number
                </Form.Label>
                <Form.Check
                  type="radio"
                  label="Yes"
                  value="yes"
                  checked={hasBatchNumber === true}
                  onChange={handleBatchNumberChange}
                />
                <Form.Check
                  type="radio"
                  label="No"
                  value="no"
                  checked={hasBatchNumber === false}
                  onChange={handleBatchNumberChange}
                />
              </Form.Group>
            </Col>
            {hasBatchNumber ? (
              <>
                <Col lg={4} md={6} sm={12}>
                  <Form.Group>
                    <Form.Label className="text-gray-700 font-medium">
                      Expiry Date
                    </Form.Label>
                    <Input
                      type="date"
                      placeholder="Expiry Date"
                      name="expdate"
                      value={expdate || ""}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <Form.Group>
                    <Form.Label className="text-gray-700 font-medium">
                      Batch Number
                    </Form.Label>
                    <Input
                      type="text"
                      placeholder="Batch Number"
                      name="batchnumber"
                      value={batchnumber || ""}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </>
            ) : (
              <Col lg={4} md={6} sm={12}>
                <Form.Group>
                  <Form.Label className="text-gray-700 font-medium">
                    Lens  Code
                  </Form.Label>
                  <Input
                    type="text"
                    placeholder="Lens Code"
                    name="lancecode"
                    value={lancecode || ""}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            )}
            <Col lg={12} className="text-right flex justify-between mt-2">
              <Button
                variant="danger"
                type="button"
                className="btn btn-secondary"
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-success"
                disabled={loading}
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
}

export default CreateProduct;
