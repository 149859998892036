import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Row, Modal } from "react-bootstrap";
import logo from '../assets/logo/HIMS_v2.png';


const ViewOutDoorCounselling = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [packageData, setPackageData] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState("");
  const [packageAmount, setPackageAmount] = useState(0);
  const [discount, setDiscount] = useState("");
  const [netAmount, setNetAmount] = useState(0);
  const [advance, setAdvance] = useState(0);
  const [otDate, setOtDate] = useState("");
  const [showTicket, setShowTicket] = useState(false);
  console.log('data', data);

  const loadPackageData = async () => {
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/packeg");
      setPackageData(response.data[0]);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    loadPackageData();
  }, []);
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    if (isNaN(date)) return "Invalid date";
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://hospital-api.hisabdev.com/api/counselling/otdoorview/${id}`
        );
        setData(response.data[0]);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "packages") {
      const selectedPack = packageData.find(
        (pack) => pack.packegname === value
      );
      setSelectedPackage(value);
      setPackageAmount(selectedPack ? selectedPack.packegrate : 0);
      calculateNetAmount(discount, selectedPack ? selectedPack.packegrate : 0, advance);
    } else if (name === "discount") {
      setDiscount(value);
      calculateNetAmount(value, packageAmount, advance);
    } else if (name === "OtDate") {
      setOtDate(value);
    } else if (name === "advance") {
      setAdvance(value);
      calculateNetAmount(discount, packageAmount, value);
    }
  };


  const calculateNetAmount = (discountValue, packageRate, advanceValue) => {
    const discountNum = parseFloat(discountValue) || 0;
    const advanceNum = parseFloat(advanceValue) || 0;
    const net = packageRate - discountNum - advanceNum;
    setNetAmount(net.toFixed(2));
  };

  const handleSubmit = async () => {
    try {
      const updatedData = {
        ...data,
        selectedPackage,
        packageAmount,
        discount,
        netAmount,
        advance,
        otDate,
        source: "camp"
      };
      await axios.put(
        `https://hospital-api.hisabdev.com/api/counselling/outdoor/${id}`,
        updatedData
      );
      alert("Data saved successfully");
      setShowTicket(true);
    } catch (error) {
      alert("Error saving data: " + error.message);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  if (isLoading) {
    return <div className="text-center mt-5">Loading...</div>;
  }

  if (error) {
    return <div className="text-center mt-5">Error: {error}</div>;
  }

  if (!data) {
    return <div className="text-center mt-5">No data available</div>;
  }

  const handleModalClose = () => {
    setShowTicket(false);
    navigate(-1);
  };

  return (
    <div className="container mt-5">
      {/* <div className="card shadow bg-sky-200"> */}
      <div className="bg-sky-200 rounded p-2 drop-shadow">
        <div className="card-header">
          <h2 className="card-title">Patient Details</h2>
        </div>
        <div className="card-bodys">
          <div className="mb-3">
            <strong>Date:</strong> {formatDate(data.date)}
          </div>
          <div className="mb-3">
            <strong>Name:</strong> {data.name}
          </div>
          <div className="mb-3">
            <strong>Mobile Number:</strong> {data.mobilenumber}
          </div>
          <div className="mb-3">
            <strong>Gender:</strong> {data.sex}
          </div>
          <div className="mb-3">
            <strong>Age:</strong> {data.age}
          </div>
          <div className="mb-3">
            <strong>Note:</strong> {data.note}
          </div>

          <div className="mb-3">
            <h4>Tests</h4>
            {data.tests && data.tests.length > 0 ? (
              <ul className="list-group">
                {data.tests.map((test, index) => (
                  <li key={index} className="list-group-item">
                    <strong>Name:</strong> {test.testname},{" "}
                    <strong>Rate:</strong> {test.amount}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No tests recorded</p>
            )}
          </div>
          <div className="mb-3">
            <h4>Group Tests</h4>
            {data.group_tests && data.group_tests.length > 0 ? (
              <ul className="list-group">
                {data.group_tests.map((gptest, index) => (
                  <li key={index} className="list-group-item">
                    <strong>Name:</strong> {gptest.gptestname},{" "}
                    <strong>Rate:</strong> {gptest.gpamount}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No group tests recorded</p>
            )}
          </div>
          <div className="mb-3">
            <h4>Medicines</h4>
            {data.medicines && data.medicines.length > 0 ? (
              <ul className="list-group">
                {data.medicines.map((medicine, index) => (
                  <li key={index} className="list-group-item">
                    <strong>Name:</strong> {medicine.name},{" "}
                    <strong>Dosage:</strong> {medicine.dosage},{" "}
                    <strong>Instruction:</strong> {medicine.instruction}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No medicines prescribed</p>
            )}
          </div>
          <Row>
            <Col lg={4} md={4} sm={12} xs={12}>
              <Form.Group>
                <Form.Label className="block text-gray-700 font-medium">
                  Package *
                </Form.Label>
                <Form.Select
                  id="packages"
                  name="packages"
                  value={selectedPackage}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Package</option>
                  {packageData.map((pack) => (
                    <option key={pack.packegname} value={pack.packegname}>
                      {pack.packegname}(₹{pack.packegrate})
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label className="block text-gray-700 font-medium">
                  Package Amount:
                </Form.Label>
                <Form.Control value={packageAmount} readOnly />
              </Form.Group>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <Form.Group>
                <Form.Label className="block text-gray-700 font-medium">
                  OT Date
                </Form.Label>
                <Form.Control
                  placeholder="discount"
                  type="date"
                  id="OtDate"
                  name="OtDate"
                  value={otDate}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <Form.Group>
                <Form.Label className="block text-gray-700 font-medium">
                  Discount
                </Form.Label>
                <Form.Control
                  placeholder="Discount"
                  type="text"
                  id="discount"
                  name="discount"
                  value={discount}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <Form.Group>
                <Form.Label className="block text-gray-700 font-medium">
                  Advance
                </Form.Label>
                <Form.Control
                  placeholder="Advance"
                  type="text"
                  id="advance"
                  name="advance"
                  value={advance}
                  onChange={handleInputChange}
                // required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label className="block text-gray-700 font-medium">
                  Net Amount:
                </Form.Label>
                <Form.Control value={netAmount} readOnly />
              </Form.Group>
            </Col>
          </Row>
          <div className="flex justify-between m-2">
            <Button
              variant="danger"
              className="btn btn-secondary me-2"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <Button
              variant="success"
              className="btn btn-secondary me-2"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
      {/* Ticket Modal */}
      <Modal
        className="printable-content"
        show={showTicket}
        onHide={() => setShowTicket(false)}
        centered
      >
        <Modal.Header closeButton>
          <div className="d-flex align-items-center w-100">
            <img src={logo} alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
            <Modal.Title className="mb-0">Rotary Eye Hospital(OT Tickite)</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p><strong>Patient Name:</strong> {data.name}</p>
            <p><strong>Operation Date:</strong> {formatDate(otDate)}</p>
            <p><strong>Selected Package:</strong> {selectedPackage}</p>
            <p><strong>Net Amount:</strong> ₹{netAmount}</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-btn">
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handlePrint}>
            Print
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewOutDoorCounselling;
