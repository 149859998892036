import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import axios from 'axios';
import background from '../assets/images/Medical_login-1.jpg';
import { BiHide } from "react-icons/bi";
import { BiSolidShow } from "react-icons/bi";

function SignupPage() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm({ mode: 'onChange' });
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);


  const onSubmit = async (data) => {
    try {
      await axios.post('https://hospital-api.hisabdev.com/api/auth/signup', data);
      toast.success('User registered successfully', {
        position: 'top-center',
        autoClose: 600,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      navigate('/login');
    } catch (error) {
      toast.error(`Signup failed: ${error.response.data.message}`, {
        position: 'top-center',
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center px-2"
      style={{ backgroundImage: `url('${background}')`, backgroundSize: 'cover' }}
    >
      <div className="p-8 rounded-lg shadow-xl w-96 backdrop-filter backdrop-blur-sm bg-opacity-50 bg-slate-200">
        <h2 className="text-3xl font-semibold text-center mb-6 text-sky-600">Signup</h2>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="username">
            <Form.Label className="font-semibold">Username</Form.Label>
            <Form.Control
              type="text"
              name="username"
              {...register('username', { required: true })}
              placeholder="Enter your username"
            />
            {errors.username && <span className="text-danger">Username is required</span>}
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label className="font-semibold">Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              {...register('email', { required: true })}
              placeholder="Enter your email"
            />
            {errors.email && <span className="text-danger">Email is required</span>}
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label className="font-semibold">Password</Form.Label>
            <div className="input-group">
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                name="password"
                {...register('password', { required: true })}
                placeholder="Enter your password"
              />
              <Button
                variant="outline-secondary"
                onClick={togglePasswordVisibility}
                className="btn-toggle-password"
              >
                {showPassword ? <BiHide /> : <BiSolidShow />}
              </Button>
            </div>
            {errors.password && <span className="text-danger">Password is required</span>}
          </Form.Group>
          <Button
            type="submit"
            className="bg-sky-600 mt-10 text-white py-1 px-4 rounded-full font-semibold hover:bg-sky-800 transition duration-300 w-full"
            disabled={isSubmitting || !isDirty || !isValid}
          >
            {isSubmitting ? 'Signing up...' : 'Signup'}
          </Button>
        </Form>
        <div className="text-center mt-4">
          <span className="text-gray-600">Already have an account? </span>
          <Link to="/login" className="text-sky-600 hover:text-sky-800 transition duration-300">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SignupPage;
