import axios from "axios";
import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Container, Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import "./Settings.css"; // Assuming you have a CSS file for additional custom styles 

const initialState = {
  address: "",
  district: "",
  pincode: "",
  postoffice: "",
  lcode: "",
};

const Settings = ({ onCreate, onCancel }) => {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const { address, district, pincode, postoffice } = state;
  const { id } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Something went wrong');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100 flex-row">
      <Card className="shadow-lg p-4 w-100" style={{ maxWidth: '800px' }}>
        <Card.Body className="">
          {/* <Card.Title className="mb-4 text-center">Edit Company Profile</Card.Title> */}
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col lg={4} md={6} sm={12}>
                <Form.Group>
                  <Form.Label>Name of Company</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="district"
                    value={district || ""}
                    onChange={handleInputChange}
                    placeholder="Microace Software Consultancy"
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col lg={8} md={6} sm={12}>
                <Form.Group>
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="address"
                    value={address || ""}
                    onChange={handleInputChange}
                    placeholder="Halisahar"
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={6} md={6} sm={12}>
                <Form.Group>
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="postoffice"
                    value={postoffice || ""}
                    onChange={handleInputChange}
                    placeholder="India"
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <Form.Group>
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="postoffice"
                    value={postoffice || ""}
                    onChange={handleInputChange}
                    placeholder="West Bengal"
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="postoffice"
                    value={postoffice || ""}
                    onChange={handleInputChange}
                    placeholder="microace@gmail.com"
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <Form.Group>
                  <Form.Label>Pin Code</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    name="pincode"
                    value={pincode || ""}
                    onChange={handleInputChange}
                    placeholder="743896"
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <Form.Group>
                  <Form.Label>PAN No.</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="postoffice"
                    value={postoffice || ""}
                    onChange={handleInputChange}
                    placeholder="ABCDE12345"
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <Form.Group>
                  <Form.Label>GST No.</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="postoffice"
                    value={postoffice || ""}
                    onChange={handleInputChange}
                    placeholder="GST58965486"
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* <div className="d-flex justify-content-end my-4">
              <Button variant="warning" type="submit" className="px-4 py-2">
                Modify
              </Button>
            </div> */}
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Settings;
