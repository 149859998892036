import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { IoSettingsSharp } from "react-icons/io5";
import { BiSolidDashboard } from "react-icons/bi";
import { RxAvatar } from "react-icons/rx";
import { BsClipboard2DataFill } from "react-icons/bs";
import { MdLogout } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { useGlobalState } from "../context/GlobalStateContext";
import { FaUserDoctor } from "react-icons/fa6";
import { useAuth } from "../context/AuthContext";
import { GiMedicines } from "react-icons/gi";
import { IoPerson } from "react-icons/io5";
import { GrUserWorker } from "react-icons/gr";
import { FaLocationDot } from "react-icons/fa6";
import { FaFemale } from "react-icons/fa";
import { FaBed } from "react-icons/fa";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { BsCalendar2PlusFill } from "react-icons/bs";
import { FaWallet } from "react-icons/fa";
import { toast } from "react-toastify";
import { GetCollection, collections } from "../config";
// import logo from '../assets/logo/msc.png';
import { GiMicroscope } from "react-icons/gi";
import { LuTestTube2 } from "react-icons/lu";
import { FaHospitalUser } from "react-icons/fa";
import { MdPayment } from "react-icons/md";
import { GiHospitalCross } from "react-icons/gi";
import { BiPurchaseTag } from "react-icons/bi";
import { GiReturnArrow } from "react-icons/gi";
import { MdInventory } from "react-icons/md";
import { FaBedPulse } from "react-icons/fa6";
import logo from "../assets/logo/HIMS_v4.png";
import Companylogo from "../assets/logo/msc.png";
import { TfiMenu } from "react-icons/tfi";
import { FcDepartment } from "react-icons/fc";
import { LuTestTubes } from "react-icons/lu";
import { CiShoppingTag } from "react-icons/ci";
import { AiOutlineMedicineBox } from "react-icons/ai";
import { IoMan } from "react-icons/io5";
import { GiNotebook } from "react-icons/gi";
import { IoTicketOutline } from "react-icons/io5";
import { TbReport } from "react-icons/tb";
import { BiSolidReport } from "react-icons/bi";
import { FaPeopleArrows } from "react-icons/fa6";
import { FaWheelchairMove } from "react-icons/fa6";
import { GrTestDesktop } from "react-icons/gr";
import { FaHospital } from "react-icons/fa";
import { PiEyeglasses } from "react-icons/pi";





function Sidebar() {
  const { user, tenant } = useAuth();
  const isAdmin = tenant?.role === "ADMIN" ? true : false;
  const { state, dispatch } = useGlobalState();
  const navigate = useNavigate();
  const location = useLocation();
  const [masterMenuOpen, setMasterMenuOpen] = useState(false);
  const [salesEntryOpen, setSalesEntryOpen] = useState(false);
  const [purchaseEntryOpen, setPurchaseEntryOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const [reportMenuOpen, setReportMenuOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const windowWidth = window.innerWidth;
  const { signOut } = useAuth();
  // const [selectedMenus, setSelectedMenus] = useState([]);

  // useEffect(() => {
  //   const fetchUserMenus = async () => {
  //     try {
  //       const response = await axios.get(`https://hospital-api.hisabdev.com/api/users/${user.id}`);
  //       setSelectedMenus(response.data.menus || []);
  //     } catch (error) {
  //       console.error('Failed to fetch user menus:', error);
  //     }
  //   };

  //   fetchUserMenus();
  // }, [user.id]);


  useEffect(() => {
    setSidebarOpen(state.sidebarOpen);
  }, [state.sidebarOpen]);

  const toggleMasterMenu = () => {
    setMasterMenuOpen(!masterMenuOpen);
  };
  const togglePuechaseMenu = () => {
    setPurchaseEntryOpen(!purchaseEntryOpen);
  };
  const toggleRepotMenu = () => {
    setReportOpen(!reportOpen);
  };

  const handleLogout = () => {
    signOut();
    navigate("/login");
  };

  const closeSidebarOutsideClick = (e) => {
    if (sidebarOpen && !e.target.closest(".sidebar")) {
      dispatch({ type: "TOGGLE_SIDEBAR" });
    }
  };
  const NavigateAndToggleSidebar = (path) => {
    navigate(path);
    if (windowWidth < 768) {
      dispatch({ type: "TOGGLE_SIDEBAR" });
    }
  };

  useEffect(() => {
    if (sidebarOpen && windowWidth < 768) {
      document.addEventListener("click", closeSidebarOutsideClick);
      document.addEventListener("touchstart", closeSidebarOutsideClick);
    }

    return () => {
      document.removeEventListener("click", closeSidebarOutsideClick);
      document.removeEventListener("touchstart", closeSidebarOutsideClick);
    };
  }, [sidebarOpen, dispatch]);

  // const isMenuVisible = (menu) => selectedMenus.includes(menu);


  return (
    <aside
      className={`overflow-hidden shadow flex flex-col bg-sky-300 sidebar text-black font-semibold h-full fixed transition ease-in-out duration-200 z-2 w-[250px] backdrop-filter backdrop-blur-sm bg-opacity-50 bg-slate-300 ${sidebarOpen ? "" : "-translate-x-full"
        }`}
    >
      <div className="">
        {/* avatar and close button */}
        {/* <div className='w-full px-3 py-3'>
          <div className='flex justify-between items-center'>
            <div
              className='flex items-center'
              onClick={() => NavigateAndToggleSidebar('/user')}>
              <RxAvatar className='w-10 h-10 text-sky-500 rounded-full me-2 drop-shadow hover:bg-sky-50 hover:text-sky-600' />
            </div>
            <p className='font-semibold mb-0 text-slate-100 text-black'>{tenant?.email}</p>
            <AiOutlineClose
              className='w-6 h-6'
              onClick={() => {
                dispatch({ type: 'TOGGLE_SIDEBAR' });
              }}
            />
          </div>
        </div> */}
        <div className="flex items-center text-slate-600 gap-5">
          <div
            className={sidebarOpen ? "hidden" : ""}
            onClick={() => {
              dispatch({ type: "TOGGLE_SIDEBAR" });
            }}
          >
            <TfiMenu className=" text-black " />
          </div>
          <img className="w-40 h-20 -my-4 pt-2 ps-2" src={logo} alt="Logo" />
          <AiOutlineClose
            className="w-6 h-6"
            onClick={() => {
              dispatch({ type: "TOGGLE_SIDEBAR" });
            }}
          />
        </div>
        <hr />
        <div className="max-h-[72vh] overflow-y-auto">
          <div
            id="dashboard-menu"
            onClick={() => {
              NavigateAndToggleSidebar("/");
            }}
            className={`${location.pathname === "/"
                ? "bg-sky-50 text-sky-600"
                : "bg-sky-300"
              } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-400 rounded cursor-pointer  drop-shadow`}
          >
            <BiSolidDashboard className="h-8 w-8 mx-3 -my-2" />
            <p className="no-underline text-black font-semibold text-lg m-0">
              Dashboard
            </p>
          </div>
          <div className="bg-sky-400 rounded mx-2 overflow-hidden drop-shadow">
            <div
              id="master-menu"
              onClick={() => {
                setMasterMenuOpen(!masterMenuOpen);
              }}
              className={`${location.pathname === "/return" ? "bg-sky-400" : "bg-sky-400"
                } flex items-center py-2 hover:bg-sky-50  hover:text-sky-600 hover:text-sky-500 text-sky-50 cursor-pointer`}
            >
              <BsClipboard2DataFill className="h-7 w-8 mx-3 -my-2" />
              <p className="no-underline text-black font-semibold text-lg m-0">
                Master
              </p>
              <div className="ml-auto mr-4">
                {masterMenuOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>
            </div>
            {masterMenuOpen && (
              <div className="py-2 ">
                <div
                  id="product-master-menu"
                  onClick={() => NavigateAndToggleSidebar("/product")}
                  className={`${location.pathname === "/product"
                      ? "bg-sky-50 text-sky-600"
                      : "bg-sky-300"
                    } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-300 rounded cursor-pointer  drop-shadow`}
                >
                  <GiMedicines className="h-4 w-4 ms-8 me-3" />
                  <p className="no-underline text-black text-md mb-0">
                    Product Master{" "}
                  </p>
                </div>
                <div
                  id="doctor-master-menu"
                  onClick={() => NavigateAndToggleSidebar("/doctor")}
                  className={`${location.pathname === "/doctor"
                      ? "bg-sky-50 text-sky-600"
                      : "bg-sky-300"
                    } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-300 rounded cursor-pointer  drop-shadow`}
                >
                  <FaUserDoctor className="h-4 w-4 ms-8 me-3" />
                  <p className="no-underline text-black text-md mb-0">
                    Doctor Master{" "}
                  </p>
                </div>
                <div
                  id="agent-master-menu"
                  onClick={() => NavigateAndToggleSidebar("/agent")}
                  className={`${location.pathname === "/agent"
                      ? "bg-sky-50 text-sky-600"
                      : "bg-sky-300"
                    } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-300 rounded cursor-pointer  drop-shadow`}
                >
                  <IoMan className="h-4 w-4 ms-8 me-3" />
                  <p className="no-underline text-black text-md mb-0">
                    Agent Master{" "}
                  </p>
                </div>
                <div
                  id="staff-master-menu"
                  onClick={() => NavigateAndToggleSidebar("/staff")}
                  className={`${location.pathname === "/staff"
                      ? "bg-sky-50 text-sky-600"
                      : "bg-sky-300"
                    } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-300 rounded cursor-pointer  drop-shadow`}
                >
                  <GrUserWorker className="h-4 w-4 ms-8 me-3" />
                  <p className="no-underline text-black text-md mb-0">
                    {" "}
                    Staff Master{" "}
                  </p>
                </div>
                <div
                  id="location-master-menu"
                  onClick={() => NavigateAndToggleSidebar("/location")}
                  className={`${location.pathname === "/location"
                      ? "bg-sky-50 text-sky-600"
                      : "bg-sky-300"
                    } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-300 rounded cursor-pointer  drop-shadow`}
                >
                  <FaLocationDot className="h-4 w-4 ms-8 me-3" />
                  <p className="no-underline text-black text-md mb-0">
                    {" "}
                    Location Master{" "}
                  </p>
                </div>
                <div
                  id="packeg-master-menu"
                  onClick={() => NavigateAndToggleSidebar("/packeg")}
                  className={`${location.pathname === "/packeg"
                      ? "bg-sky-50 text-sky-600"
                      : "bg-sky-300"
                    } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-300 rounded cursor-pointer  drop-shadow`}
                >
                  <MdOutlineCurrencyRupee className="h-4 w-4 ms-8 me-3" />
                  <p className="no-underline text-black text-md mb-0">
                    {" "}
                    Package Master{" "}
                  </p>
                </div>
                <div
                  id="department-master-menu"
                  onClick={() => NavigateAndToggleSidebar("/department")}
                  className={`${location.pathname === "/department"
                      ? "bg-sky-50 text-sky-600"
                      : "bg-sky-300"
                    } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-300 rounded cursor-pointer  drop-shadow`}
                >
                  <FcDepartment className="h-4 w-4 ms-8 me-3" />
                  <p className="no-underline text-black text-md mb-0">
                    {" "}
                    Department Master{" "}
                  </p>
                </div>
                <div
                  id="test-master-menu"
                  onClick={() => NavigateAndToggleSidebar("/test")}
                  className={`${location.pathname === "/test"
                      ? "bg-sky-50 text-sky-600"
                      : "bg-sky-300"
                    } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-300 rounded cursor-pointer  drop-shadow`}
                >
                  <LuTestTube2 className="h-4 w-4 ms-8 me-3" />
                  <p className="no-underline text-black text-md mb-0">
                    {" "}
                    Test Master{" "}
                  </p>
                </div>
                <div
                  id="grouptest-master-menu"
                  onClick={() => NavigateAndToggleSidebar("/grouptest")}
                  className={`${location.pathname === "/grouptest"
                      ? "bg-sky-50 text-sky-600"
                      : "bg-sky-300"
                    } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-300 rounded cursor-pointer  drop-shadow`}
                >
                  <LuTestTubes className="h-4 w-4 ms-8 me-3" />
                  <p className="no-underline text-black text-md mb-0">
                    Group Test Master{" "}
                  </p>
                </div>
                <div
                  id="bed-master-menu"
                  onClick={() => NavigateAndToggleSidebar("/bed")}
                  className={`${location.pathname === "/bed"
                      ? "bg-sky-50 text-sky-600"
                      : "bg-sky-300"
                    } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-300 rounded cursor-pointer  drop-shadow`}
                >
                  <FaBed className="h-4 w-4 ms-8 me-3" />
                  <p className="no-underline text-black text-md mb-0">
                    {" "}
                    Bed Master{" "}
                  </p>
                </div>
                <div
                  id="generalledger-master-menu"
                  onClick={() => NavigateAndToggleSidebar("/generalledger")}
                  className={`${location.pathname === "/generalledger"
                      ? "bg-sky-50 text-sky-600"
                      : "bg-sky-300"
                    } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-300 rounded cursor-pointer  drop-shadow`}
                >
                  <GiNotebook className="h-4 w-4 ms-8 me-3" />
                  <p className="no-underline text-black text-md mb-0">
                    {" "}
                    General Ledger Master{" "}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div
            id="registation-menu"
            onClick={() => {
              NavigateAndToggleSidebar("/appoinment");
            }}
            className={`${location.pathname === "/appoinment"
                ? "bg-sky-50 text-sky-600"
                : "bg-sky-300"
              } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-400 rounded cursor-pointer  drop-shadow`}
          >
            <BsCalendar2PlusFill className="h-5 w-5 mx-4" />
            <p className="no-underline text-black font-semibold text-lg m-0">
              Registration
            </p>
          </div>
          <div
            id="precheckup-menu"
            onClick={() => {
              NavigateAndToggleSidebar("/pre-checkup");
            }}
            className={`${location.pathname === "/pre-checkup"
                ? "bg-sky-50 text-sky-600"
                : "bg-sky-300"
              } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-400 rounded cursor-pointer  drop-shadow`}
          >
            <FaHospital className="h-5 w-5 mx-4" />
            <p className="no-underline text-black font-semibold text-lg m-0">
              Pre-Doctor Check-up
            </p>
          </div>
          <div
            id="checkup-menu"
            onClick={() => {
              NavigateAndToggleSidebar("/checkup");
            }}
            className={`${location.pathname === "/checkup"
                ? "bg-sky-50 text-sky-600"
                : "bg-sky-300"
              } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-400 rounded cursor-pointer  drop-shadow`}
          >
            <FaHospitalUser className="h-5 w-5 mx-4" />
            <p className="no-underline text-black font-semibold text-lg m-0">
              Doctor Check-up
            </p>
          </div>
          <div
            id="counselling-menu"
            onClick={() => {
              NavigateAndToggleSidebar("/counselling");
            }}
            className={`${location.pathname === "/counselling"
                ? "bg-sky-50 text-sky-600"
                : "bg-sky-300"
              } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-400 rounded cursor-pointer  drop-shadow`}
          >
            <FaPeopleArrows className="h-5 w-5 mx-4" />
            <p className="no-underline text-black font-semibold text-lg m-0">
              Counselling
            </p>
          </div>
          <div
            id="admission-menu"
            onClick={() => {
              NavigateAndToggleSidebar("/admission");
            }}
            className={`${location.pathname === "/admission"
                ? "bg-sky-50 text-sky-600"
                : "bg-sky-300"
              } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-400 rounded cursor-pointer  drop-shadow`}
          >
            <FaBedPulse className="h-5 w-5 mx-4" />
            <p className="no-underline text-black font-semibold text-lg m-0">
              Admission
            </p>
          </div>
          <div
            id="discharge-menu"
            onClick={() => {
              NavigateAndToggleSidebar("/discharge");
            }}
            className={`${location.pathname === "/discharge"
                ? "bg-sky-50 text-sky-600"
                : "bg-sky-300"
              } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-400 rounded cursor-pointer  drop-shadow`}
          >
            <FaWheelchairMove className="h-5 w-5 mx-4" />
            <p className="no-underline text-black font-semibold text-lg m-0">
              Discharge
            </p>
          </div>
          <div className="bg-sky-400 rounded mx-2 overflow-hidden drop-shadow">
            <div
              id="sales-entry-menu"
              onClick={() => {
                setSalesEntryOpen(!salesEntryOpen);
              }}
              className={`${location.pathname === "/return" ? "bg-sky-400" : "bg-sky-400"
                } flex items-center py-2 hover:bg-sky-50  hover:text-sky-600 hover:text-sky-500 text-sky-50 cursor-pointer`}
            >
              <CiShoppingTag className="h-7 w-8 mx-3 -my-2" />
              <p className="no-underline text-black font-semibold text-lg m-0">
                Sales
              </p>
              <div className="ml-auto mr-4">
                {salesEntryOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>
            </div>
            {salesEntryOpen && (
              <div className="py-2 ">
                <div
                  id="Sales-bill-menu"
                  onClick={() => NavigateAndToggleSidebar("/sales")}
                  className={`${location.pathname === "/sales"
                      ? "bg-sky-50 text-sky-600"
                      : "bg-sky-300"
                    } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-300 rounded cursor-pointer  drop-shadow`}
                >
                  <AiOutlineMedicineBox className="h-4 w-4 ms-8 me-3" />
                  <p className="no-underline text-black text-md mb-0">Sales Medicine</p>
                </div>

                <div
                  onClick={() =>
                    NavigateAndToggleSidebar("/saleslens")
                  }
                  className={`${location.pathname === "/saleslens"
                      ? "bg-sky-50 text-sky-600"
                      : "bg-sky-300"
                    } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-300 rounded cursor-pointer  drop-shadow`}
                >
                  <PiEyeglasses className="h-4 w-4 ms-8 me-3" />
                  <p className="no-underline text-black text-md mb-0">
                    {" "}
                    Sales Lens
                  </p>
                </div>
              </div>
            )}
          </div>
          <div
            id="issue-menu"
            onClick={() => {
              NavigateAndToggleSidebar("/issue");
            }}
            className={`${location.pathname === "/issue"
                ? "bg-sky-50 text-sky-600"
                : "bg-sky-300"
              } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-400 rounded cursor-pointer  drop-shadow`}
          >
            <MdInventory className="h-5 w-5 mx-4" />
            <p className="no-underline text-black font-semibold text-lg m-0">
              Issues
            </p>
          </div>
          <div
            id="InHousediagnostic-menu"
            onClick={() => {
              NavigateAndToggleSidebar("/in-house-pathology");
            }}
            className={`${location.pathname === "/in-house-pathology"
                ? "bg-sky-50 text-sky-600"
                : "bg-sky-300"
              } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-400 rounded cursor-pointer  drop-shadow`}
          >
            <GrTestDesktop className="h-5 w-5 mx-4" />
            <p className="no-underline text-black font-semibold text-lg m-0">
              In-House-Diagnostic
            </p>
          </div>
          <div
            id="diagnostic-menu"
            onClick={() => {
              NavigateAndToggleSidebar("/pathology");
            }}
            className={`${location.pathname === "/pathology"
                ? "bg-sky-50 text-sky-600"
                : "bg-sky-300"
              } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-400 rounded cursor-pointer  drop-shadow`}
          >
            <GiMicroscope className="h-5 w-5 mx-4" />
            <p className="no-underline text-black font-semibold text-lg m-0">
              Diagnostic
            </p>
          </div>

          <div className="bg-sky-400 rounded mx-2 overflow-hidden drop-shadow">
            <div
              id="purchase-entry-menu"
              onClick={() => {
                setPurchaseEntryOpen(!purchaseEntryOpen);
              }}
              className={`${location.pathname === "/return" ? "bg-sky-400" : "bg-sky-400"
                } flex items-center py-2 hover:bg-sky-50  hover:text-sky-600 hover:text-sky-500 text-sky-50 cursor-pointer`}
            >
              <BiPurchaseTag className="h-7 w-8 mx-3 -my-2" />
              <p className="no-underline text-black font-semibold text-lg m-0">
                Purchase
              </p>
              <div className="ml-auto mr-4">
                {purchaseEntryOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>
            </div>
            {purchaseEntryOpen && (
              <div className="py-2 ">
                <div
                  id="purchase-order-menu"
                  onClick={() => NavigateAndToggleSidebar("/purchases")}
                  className={`${location.pathname === "/purchases"
                      ? "bg-sky-50 text-sky-600"
                      : "bg-sky-300"
                    } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-300 rounded cursor-pointer  drop-shadow`}
                >
                  <BiPurchaseTag className="h-4 w-4 ms-8 me-3" />
                  <p className="no-underline text-black text-md mb-0">
                    Purchase
                  </p>
                </div>
              </div>
            )}
          </div>
          <div
            id="voucher-menu"
            onClick={() => {
              NavigateAndToggleSidebar("/voucher");
            }}
            className={`${location.pathname === "/voucher"
                ? "bg-sky-50 text-sky-600"
                : "bg-sky-300"
              } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-400 rounded cursor-pointer  drop-shadow`}
          >
            <IoTicketOutline className="h-5 w-5 mx-4" />
            <p className="no-underline text-black font-semibold text-lg m-0">
              Voucher
            </p>
          </div>
          <div
            id="outdore-user-menu"
            onClick={() => {
              NavigateAndToggleSidebar("/createoutdoreuser");
            }}
            className={`${location.pathname === "/createoutdoreuser"
                ? "bg-sky-50 text-sky-600"
                : "bg-sky-300"
              } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-400 rounded cursor-pointer  drop-shadow`}
          >
            <GiHospitalCross className="h-5 w-5 mx-4" />
            <p className="no-underline text-black font-semibold text-lg m-0">
              Camp-Outdoor
            </p>
          </div>

          <div className="bg-sky-400 rounded mx-2 overflow-hidden drop-shadow">
            <div
              id="report-menu"
              onClick={() => {
                setReportOpen(!reportOpen);
              }}
              className={`${location.pathname === "/return" ? "bg-sky-400" : "bg-sky-400"
                } flex items-center py-2 hover:bg-sky-50  hover:text-sky-600 hover:text-sky-500 text-sky-50 cursor-pointer`}
            >
              <TbReport className="h-7 w-8 mx-3 -my-2" />
              <p className="no-underline text-black font-semibold text-lg m-0">
                Report
              </p>
              <div className="ml-auto mr-4">
                {reportOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>
            </div>
            {reportOpen && (
              <div className="py-2 ">
                <div
                  id="registation-report-menu"
                  onClick={() => NavigateAndToggleSidebar("/report")}
                  className={`${location.pathname === "/report"
                      ? "bg-sky-50 text-sky-600"
                      : "bg-sky-300"
                    } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-300 rounded cursor-pointer  drop-shadow`}
                >
                  <BiSolidReport className="h-4 w-4 ms-8 me-3" />
                  <p className="no-underline text-black text-md mb-0">
                    Registation Report
                  </p>
                </div>
                <div
                  id="registation-report-menu"
                  onClick={() => NavigateAndToggleSidebar("/admissionreport")}
                  className={`${location.pathname === "/admissionreport"
                      ? "bg-sky-50 text-sky-600"
                      : "bg-sky-300"
                    } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-300 rounded cursor-pointer  drop-shadow`}
                >
                  <BiSolidReport className="h-4 w-4 ms-8 me-3" />
                  <p className="no-underline text-black text-md mb-0">
                    Admission Report
                  </p>
                </div>
                <div
                  id="registation-report-menu"
                  onClick={() => NavigateAndToggleSidebar("/testreport")}
                  className={`${location.pathname === "/testreport"
                      ? "bg-sky-50 text-sky-600"
                      : "bg-sky-300"
                    } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-300 rounded cursor-pointer  drop-shadow`}
                >
                  <BiSolidReport className="h-4 w-4 ms-8 me-3" />
                  <p className="no-underline text-black text-md mb-0">
                    Test Report
                  </p>
                </div>
                <div
                  id="registation-report-menu"
                  onClick={() => NavigateAndToggleSidebar("/stockreport")}
                  className={`${location.pathname === "/stockreport"
                      ? "bg-sky-50 text-sky-600"
                      : "bg-sky-300"
                    } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-300 rounded cursor-pointer  drop-shadow`}
                >
                  <BiSolidReport className="h-4 w-4 ms-8 me-3" />
                  <p className="no-underline text-black text-md mb-0">
                    Stock Report
                  </p>
                </div>
              </div>
            )}
          </div>

          <div
            id="setting-menu"
            onClick={() => {
              NavigateAndToggleSidebar("/settings");
            }}
            className={`${location.pathname === "/settings"
                ? "bg-sky-50 text-sky-600"
                : "bg-sky-300"
              } flex items-center py-2 hover:bg-sky-50 hover:text-sky-600  text-sky-50 mx-2 my-2 bg-sky-400 rounded cursor-pointer  drop-shadow`}
          >
            <IoSettingsSharp className="h-5 w-5 mx-4" />
            <p className="no-underline text-black font-semibold text-lg m-0">
              Settings
            </p>
          </div>
        </div>
      </div>
      <div
        onClick={handleLogout}
        className="flex justify-around items-center mt-auto mx-auto h-5 border-2 bg-sky-600 border-sky-600 py-1 h-max w-2/3 rounded-md mb-24 hover:bg-sky-500  hover:text-sky-600  text-sky-50shadow cursor-pointer"
      >
        <p className="no-underline text-white font-semibold text-lg m-0">
          Logout
        </p>
        <MdLogout className="h-5 w-5 text-white" />
      </div>
      <div className="px-3 fixed w-[250px] bottom-0 flex items-center">
        <img src={Companylogo} className="w-10" alt="Companylogo" />
        <span className="ms-2 mt-3">
          <small className="text-slate-800">Developed by</small>
          <p className="font-semibold text-gray-900">Microace Software</p>
        </span>
      </div>
    </aside>
  );
}

export default Sidebar;
