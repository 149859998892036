// import React, { useState, useEffect } from 'react';
// import { Button, Col, Form, Row } from 'react-bootstrap';
// import { useForm } from 'react-hook-form';
// import { useNavigate, useParams } from 'react-router-dom';
// import { toast } from 'react-toastify';
// import { yupResolver } from "@hookform/resolvers/yup"
// import * as yup from "yup"
// import axios from 'axios';
// import { Input } from '@mui/joy';

// const initialState = {
//     email:"",
//     password:"",
//     location:""
// };


// function CreateOutdoreUser({ onCreate, onCancel, customers, model }) {
//   const navigate = useNavigate();
//   const [state, setState] = useState(initialState);
//   const {
//     email,
//     password,
//     location
//   } = state;
//   const { id } = useParams();

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (!email || !password || !location ) {
//       toast.error("Please provide value into each inpute field ");
//     } else {
//       if (!id) {
//         axios
//           .post("https://hospital-api.hisabdev.com/api/outdoreuser/createoutdoreuser", {
//             email,
//             password,
//             location
//           })
//           .then(() => {
//             setState(initialState);
//             console.log("classdetails", state);
//           })
//           .catch((err) => toast.error(err.respose.data));
//         toast.success("Appointment  Successfully");
//       } else {
//         axios
//           .put(`https://hospital-api.hisabdev.com/api/createoutdoreuser/${id}`, {
//             email,
//             password,
//             location
//           })
//           .then(() => {
//             setState({
//                 email:"",
//                 password:"",
//                 location:""
//             });
//             console.log("classdetails", state);
//           })
//           .catch((err) => toast.error(err.respose.data));
//         toast.success("update Successfully");
//       }
//       setTimeout(() => {onCancel()}, 500);
//     }
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setState({ ...state, [name]: value });
//   };

//   return (
//     <div className='bg-sky-200 p-4 drop-shadow rounded-xl'>
//       <h2 className="text-xl font-semibold ">Create {model}</h2>
//       <Form onSubmit={handleSubmit}>
//         <div className=' py-2'>
//           <Row>
//             <Col lg={6} md={6} sm={12}>
//               <Form.Group controlId="PATIENT_ID">
//                 <Form.Label className="block text-gray-700 font-medium">User Id</Form.Label>
//                 <Input
//                   required
//                   placeholder='User Id'
//                   className=''
//                   type="text"
//                   name="email"
//                   value={email || ""}
//                   onChange={handleInputChange}
//                 />
//               </Form.Group>
//             </Col>
//             <Col lg={6} md={6} sm={12}>
//               <Form.Group>
//                 <Form.Label className=" text-gray-700 font-medium">Password</Form.Label>
//                 <Input
//                   required
//                   type="text"
//                   placeholder='Password'
//                   name="password" 
//                   value={password || ""}
//                   onChange={handleInputChange}
//                 />
//               </Form.Group>
//             </Col>
//             <Col lg={12} md={6} sm={12}>
//               <Form.Group>
//                 <Form.Label className=" text-gray-700 font-medium">Location</Form.Label>
//                 <Input
//                   required
//                   type="text"
//                   placeholder='Location'
//                   name="location" 
//                   value={location || ""}
//                   onChange={handleInputChange}
//                 />
//               </Form.Group>
//             </Col>
//           </Row>
//         </div>
//         <div className="flex justify-content-between my-4">
//           <Button
//             variant='danger'
//             onClick={onCancel}
//             className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
//           >
//             Cancel
//           </Button>
//           <Button
//             type="submit"
//             className="px-4 py-2"
//             onClick={()=>console.log('click')}
//           >
//             Create
//           </Button>
//         </div>
//       </Form>
//     </div>
//   );
// }

// export default CreateOutdoreUser;



import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';

const schema = yup.object().shape({
  username: yup.string().required('Username is required'),
  password: yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
  location: yup.string().required('Location is required'),
});

const CreateOutdoreUser = ({ onCancel }) => {
  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = async (data) => {
    try {
      const response = await axios.post('https://hospital-api.hisabdev.com/api/outdoreuser/createoutdoreuser', data);
      toast.success(response.data.message);
      console.log('User created successfully:', response.data);
      setTimeout(() => { onCancel() }, 500);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to create user');
      console.error('Error creating user:', error);
    }
  };

  return (
    <div className='bg-sky-200 p-4 drop-shadow rounded-xl'>
      <h2>Create Outdoor User</h2>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" placeholder="Enter username" {...register('username')} />
              <p className="text-danger">{errors.username?.message}</p>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Enter email" {...register('email')} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Enter password" {...register('password')} />
              <p className="text-danger">{errors.password?.message}</p>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="location">
              <Form.Label>Location</Form.Label>
              <Form.Control type="text" placeholder="Enter location" {...register('location')} />
              <p className="text-danger">{errors.location?.message}</p>
            </Form.Group>
          </Col>
        </Row>
        <div className="flex justify-content-between my-4">
          <Button
            variant='danger'
            onClick={onCancel}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Creating...' : 'Create'}
          </Button>
        </div>

      </Form>
    </div>
  );
};

export default CreateOutdoreUser;
