import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Spinner, Container, Row, Col, Form, Button, Table } from "react-bootstrap";

const ViewSale = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [sale, setSale] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedSale, setEditedSale] = useState(null);
  const [productData, setProductData] = useState([]);

  const loadProductData = async () => {
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/product");
      setProductData(response.data[0]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadProductData();
  }, []);

  useEffect(() => {
    const fetchSale = async () => {
      try {
        const response = await axios.get(`https://hospital-api.hisabdev.com/api/sales/${id}`);
        setSale(response.data);
        setEditedSale(response.data);
      } catch (error) {
        setError(error.message);
        toast.error("Error fetching sale data");
      } finally {
        setLoading(false);
      }
    };

    fetchSale();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedSale((prevEditedSale) => ({
      ...prevEditedSale,
      [name]: value,
    }));
  };

  const handleItemChange = (index, e) => {
    const { name, value } = e.target;
    const updatedItems = [...editedSale.items];
    updatedItems[index] = {
      ...updatedItems[index],
      [name]: value,
    };

    if (name === "description") {
      const selectedProduct = productData.find(
        (product) => product.Description === value
      );
      if (selectedProduct) {
        updatedItems[index] = {
          ...updatedItems[index],
          itemCode: selectedProduct.productcode,
          uom: selectedProduct.purchesunit,
          rate: selectedProduct.buyrate,
          gstrRate: selectedProduct.taxcategory,
        };
      }
    }

    setEditedSale((prevEditedSale) => ({
      ...prevEditedSale,
      items: updatedItems,
    }));
    calculateItemTotals(index, updatedItems);
  };

  const calculateItemTotals = (index, items) => {
    const updatedItems = [...items];
    const item = updatedItems[index];
    const quantity = parseFloat(item.quantity) || 0;
    const rate = parseFloat(item.rate) || 0;
    const discount = parseFloat(item.discount) || 0;
    const productTotal = quantity * rate;
    const taxableAmount = productTotal - discount;
    const gstRate = parseFloat(item.gstrRate) || 0;
    const gstAmount = (taxableAmount * gstRate) / 100;
    const amount = taxableAmount + gstAmount;

    updatedItems[index] = {
      ...item,
      productTotal,
      taxableAmount,
      gstAmount,
      amount,
    };

    setEditedSale((prevEditedSale) => ({
      ...prevEditedSale,
      items: updatedItems,
    }));
  };

  const handleAddRow = () => {
    setEditedSale((prevEditedSale) => ({
      ...prevEditedSale,
      items: [...prevEditedSale.items, {
        itemCode: "",
        description: "",
        quantity: "",
        uom: "",
        rate: "",
        productTotal: "",
        discount: "",
        taxableAmount: "",
        gstrRate: "",
        gstAmount: "",
        amount: "",
      }],
    }));
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setEditedSale(sale); // Reset editedSale to original sale data
    setIsEditing(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://hospital-api.hisabdev.com/api/sales/${id}`, editedSale);
      toast.success("Sale updated successfully");
      setSale(editedSale); // Update sale state with edited data
      setIsEditing(false);
    } catch (error) {
      toast.error("Error updating sale data");
    }
  };

  if (loading) {
    return (
      <div className="w-full h-60 flex justify-center items-center">
        <Spinner animation="border" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Container className="p-4">
      <h2 className="mb-4">Sale Details</h2>
      {!isEditing && (
        <Button variant="warning" onClick={handleEditClick}>
          Modify
        </Button>
      )}
      {sale ? (
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBillNo">
              <Form.Label>Bill No</Form.Label>
              <Form.Control
                type="text"
                name="billNo"
                value={editedSale.billNo}
                onChange={handleChange}
                disabled={!isEditing}
                readOnly
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formBillDate">
              <Form.Label>Bill Date</Form.Label>
              <Form.Control
                type="text"
                name="billDate"
                value={editedSale.billDate}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formCustCode">
              <Form.Label>Cust Code</Form.Label>
              <Form.Control
                type="text"
                name="custCode"
                value={editedSale.custCode}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formCustName">
              <Form.Label>Cust Name</Form.Label>
              <Form.Control
                type="text"
                name="custName"
                value={editedSale.custName}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formPaymentMode">
              <Form.Label>Payment Mode</Form.Label>
              <Form.Control
                type="text"
                name="paymentMode"
                value={editedSale.paymentMode}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formAdvancePayment">
              <Form.Label>Advance Payment</Form.Label>
              <Form.Control
                type="text"
                name="advancePayment"
                value={editedSale.advancePayment}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formDuePayment">
              <Form.Label>Due Payment</Form.Label>
              <Form.Control
                type="text"
                name="duePayment"
                value={editedSale.duePayment}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Row>

          <div className="overflow-x-scroll">
            <h3>Items</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Item Code</th>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>UOM</th>
                  <th>Rate</th>
                  <th>Product Total</th>
                  <th>Discount</th>
                  <th>Taxable Amount</th>
                  <th>GST Rate</th>
                  <th>GST Amount</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {editedSale.items.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Form.Control
                        type="text"
                        name="itemCode"
                        value={item.itemCode}
                        onChange={(e) => handleItemChange(index, e)}
                        disabled={!isEditing}
                      />
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        name="description"
                        value={item.Descriptionescription}
                        onChange={(e) => handleItemChange(index, e)}
                        disabled={!isEditing}
                      >
                        <option value="">Select Product</option>
                        {productData.map((product, productIndex) => (
                          <option key={productIndex} value={product.Description}>
                            {product.Description}
                          </option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        name="quantity"
                        value={item.quantity}
                        onChange={(e) => handleItemChange(index, e)}
                        disabled={!isEditing}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="uom"
                        value={item.uom}
                        onChange={(e) => handleItemChange(index, e)}
                        disabled={!isEditing}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        name="rate"
                        value={item.rate}
                        onChange={(e) => handleItemChange(index, e)}
                        disabled={!isEditing}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        name="productTotal"
                        value={item.productTotal}
                        onChange={(e) => handleItemChange(index, e)}
                        disabled={!isEditing}
                        readOnly
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        name="discount"
                        value={item.discount}
                        onChange={(e) => handleItemChange(index, e)}
                        disabled={!isEditing}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        name="taxableAmount"
                        value={item.taxableAmount}
                        onChange={(e) => handleItemChange(index, e)}
                        disabled={!isEditing}
                        readOnly
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        name="gstrRate"
                        value={item.gstrRate}
                        onChange={(e) => handleItemChange(index, e)}
                        disabled={!isEditing}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        name="gstAmount"
                        value={item.gstAmount}
                        onChange={(e) => handleItemChange(index, e)}
                        disabled={!isEditing}
                        readOnly
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        name="amount"
                        value={item.amount}
                        onChange={(e) => handleItemChange(index, e)}
                        disabled={!isEditing}
                        readOnly
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          {isEditing && (
            <Row className="mb-3 mt-3">
              <Col>
                <Button variant="primary" onClick={handleAddRow} className="mb-3">
                  Add Row
                </Button>
              </Col>
              <Col>
                <Button variant="secondary" onClick={handleCancelEdit}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button className="float-end" variant="success" type="submit">
                  Update Sale
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      ) : (
        <p>No sale data available</p>
      )}
    </Container>
  );
};

export default ViewSale;
