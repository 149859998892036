import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/esm/Button";
import { Spinner } from "react-bootstrap";
import Table from "../../components/Table";
import { useGlobalState } from "../../context/GlobalStateContext";
import axios from "axios";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { FaEye, FaPencilAlt } from "react-icons/fa";
import GrowExample from '../../components/spiner/GrowExample';
import { parseISO, compareDesc } from "date-fns";


function Appointments() {
  const { state, dispatch } = useGlobalState();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [createForm, showCreateForm] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const sortAppointmentsByDate = (appointments) => {
    return appointments.sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
  };

  const loadData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/pre-checkup");
      setData(sortAppointmentsByDate(response.data[0]));
      console.log(response.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [createForm]);

  const deleteContact = (id) => {
    if (window.confirm("Are you sure that you wanted to delete that Appointment?")) {
      axios.delete(`https://hospital-api.hisabdev.com/api/removeregistation/${id}`);
      toast.success("Appointment Deleted Successfully");
      setTimeout(() => loadData(), 500);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
  };

  const columns = [
    {
      header: "Date",
      accessorKey: "date",
      cell: (rowData) => formatDate(rowData.getValue()),
    },
    {
      header: "Address",
      accessorKey: "location",
    },
    {
      header: "Patient Name",
      accessorKey: "name",
    },
    {
      header: "Age",
      accessorKey: "age",
    },
    {
      header: "Image",
      accessorKey: "image",
    },
    {
      header: "Mobile Number",
      accessorKey: "mobilenumber",
    },
    {
      header: "Sex",
      accessorKey: "sex",
    },
    {
      header: "Doctor Name",
      accessorKey: "doctorname",
    },
    {
      header: "Time",
      accessorKey: "time",
    },
    {
      header: "Type",
      accessorKey: "type",
    },
    {
      header: "Price",
      accessorKey: "price",
    },
  ];

  const handleViewAppointment = (id) => {
    navigate(`/patientdetails/${id}`);
  };

  const filteredData = {
    checked: data.filter((item) => item.doctorCheckupStatus === "yes"),
    notChecked: data.filter((item) => item.doctorCheckupStatus === "no"),
  };

  return (
    <div className="container p-2">
      <h2 className="text-xl font-bold mb-4">Checkup</h2>
      {isLoading && !data[0] && (
        <div className="w-full h-60 flex justify-center items-center">
          <GrowExample />
        </div>
      )}
      {!createForm && (
        <>
          <h3 className="text-lg font-bold mb-4">Checked</h3>
          <div className="drop-shadow mb-6">
            <Table
              data={filteredData.checked}
              columns={[
                {
                  header: "No.",
                  cell: (row) => <>{row.cell.row.index + 1}</>,
                },
                ...columns,
                // {
                //   header: "Actions",
                //   cell: (rowData) => (
                //     <div className="flex justify-between">
                //       {/* <button
                //         onClick={() => handleViewAppointment(rowData?.row?.original?.id)}
                //         className="btn btn-primary btn-sm me-2"
                //       >
                //         <FaPencilAlt />
                //       </button> */}
                //       <button
                //         onClick={() => deleteContact(rowData?.row?.original?.id)}
                //         className="btn btn-danger btn-sm"
                //       >
                //         <MdDelete />
                //       </button>
                //     </div>
                //   ),
                // },
              ]}
              pageLimit={10}
            />
          </div>

          <h3 className="text-lg font-bold mb-4">Not Checked</h3>
          <div className="drop-shadow">
            <Table
              data={filteredData.notChecked}
              columns={[
                {
                  header: "No.",
                  cell: (row) => <>{row.cell.row.index + 1}</>,
                },
                ...columns,
                {
                  header: "Actions",
                  cell: (rowData) => (
                    <div className="flex justify-between">
                      <button
                        onClick={() => handleViewAppointment(rowData?.row?.original?.id)}
                        className="btn btn-primary btn-sm me-2"
                      >
                        <FaPencilAlt />
                      </button>
                      {/* <button
                        onClick={() => deleteContact(rowData?.row?.original?.id)}
                        className="btn btn-danger btn-sm"
                      >
                        <MdDelete />
                      </button> */}
                    </div>
                  ),
                },
              ]}
              pageLimit={10}
            />
          </div>
        </>
      )}
      {!isLoading && data.length === 0 && (
        <p className="text-red-400 font-semibold w-max mx-auto my-20">
          No Appointment Data
        </p>
      )}
    </div>
  );
}

export default Appointments;
