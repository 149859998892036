import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Form, Row, Col } from "react-bootstrap";
import { format } from "date-fns";

function RegistrationReport() {
  const [registrations, setRegistrations] = useState([]);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [registrationCount, setRegistrationCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedType, setSelectedType] = useState("");

  const fetchRegistrations = async (start, end) => {
    try {
      const response = await axios.get(
        `https://hospital-api.hisabdev.com/api/registation/registrationreport`,
        {
          params: { startDate: start, endDate: end },
        }
      );
      if (response.data.length === 0) {
        setError(
          `No data found for the selected date range: ${start} to ${end}`
        );
      } else {
        setError(null);
        setRegistrations(response.data);
        filterRegistrations(response.data, selectedType);
      }
    } catch (error) {
      setError(
        `Error fetching data for the selected date range: ${start} to ${end}`
      );
    }
  };

  useEffect(() => {
    fetchRegistrations(startDate, endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    filterRegistrations(registrations, selectedType);
  }, [selectedType, registrations]);

  const filterRegistrations = (registrations, type) => {
    const filtered = type
      ? registrations.filter((reg) => reg.type === type)
      : registrations;
    setRegistrationCount(filtered.length);

    // Check and handle invalid price values
    const total = filtered.reduce((sum, reg) => {
      const price = parseFloat(reg.price);
      if (isNaN(price)) {
        console.warn(`Invalid price value found: ${reg.price}`);
        return sum;
      }
      return sum + price;
    }, 0);

    console.log("Total price:", total);
    setTotalPrice(total);

    if (filtered.length === 0 && type) {
      setError(`No data found for the selected type: ${type}`);
    } else {
      setError(null);
    }
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  return (
    <div className="p-4">
      <h2>Registrations Report</h2>
      {/* <div className="bg-sky-200 rounded p-2 drop-shadow"> */}
      <Form className="flex mb-3">
        <Row>
          <Col lg={4} md={6} sm={6} xs={6}>
            <Form.Group controlId="startDate" className="mr-4">
              <Form.Label className="font-bold">To Date</Form.Label>
              <Form.Control
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
              />
            </Form.Group>
          </Col>
          <Col lg={4} md={6} sm={6} xs={6}>
            <Form.Group controlId="endDate" className="mr-4">
              <Form.Label className="font-bold">Form Date</Form.Label>
              <Form.Control
                type="date"
                value={endDate}
                onChange={handleEndDateChange}
              />
            </Form.Group>
          </Col>
          <Col lg={4} md={6} sm={6} xs={6}>
            <Form.Group controlId="type">
              <Form.Label className="font-bold">Select Type</Form.Label>
              <Form.Control
                as="select"
                value={selectedType}
                onChange={handleTypeChange}
              >
                <option value="">All</option>
                <option value="General">General</option>
                <option value="Special">Special</option>
                <option value="OnCall">OnCall</option>
                <option value="Free">Free</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </Form>
      {error && <p className="text-danger">{error}</p>}
      {/* </div> */}
      {registrationCount > 0 && !error ? (
        <>
          <p className="text-red-700 text-2xl font-bold">
            Total Registrations:{" "}
            <span className="text-green-900">{registrationCount}</span>
          </p>
          <p className="text-red-700 text-lg font-semibold">
            Total Collection:{" "}
            <span className="text-green-900"> ₹{totalPrice.toFixed(2)}</span>
          </p>
          <div className="table-responsive mt-4">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Admission ID</th>
                  <th>Registration Time</th>
                  <th>Name</th>
                  <th>Address</th>
                  <th>Mobile Number</th>
                  <th>Age</th>
                  <th>Gender</th>
                  <th>Doctor</th>
                  <th>Type</th>
                  <th>Amount</th>
                  <th>Guardian Name</th>
                  <th>Guardian Number</th>
                </tr>
              </thead>
              <tbody>
                {registrations
                  .filter((reg) => !selectedType || reg.type === selectedType)
                  .map((reg) => (
                    <tr key={reg.id}>
                      <td>{reg.rpcode}</td>
                      <td>{format(new Date(reg.createdAt), "yyyy-MM-dd")}</td>
                      <td>{reg.name}</td>
                      <td>{reg.location}</td>
                      <td>{reg.mobilenumber}</td>
                      <td>{reg.age}</td>
                      <td>{reg.sex}</td>
                      <td>{reg.doctorname}</td>
                      <td>{reg.type}</td>
                      <td>{reg.price}</td>
                      <td>{reg.guardianname}</td>
                      <td>{reg.guardiannumber}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        !error && (
          <p className="text-red-700">
            No data found for the selected date range.
          </p>
        )
      )}
    </div>
  );
}

export default RegistrationReport;
