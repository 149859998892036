import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Form, Col, Row } from "react-bootstrap";
import { format } from "date-fns";

function AdmissionReport() {
  const [registrations, setRegistrations] = useState([]);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [registrationCount, setRegistrationCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [patientSource, setPatientSource] = useState("All");

  const fetchRegistrations = async (start, end) => {
    try {
      const response = await axios.get(
        `https://hospital-api.hisabdev.com/api/admission/admisionreport`,
        {
          params: { startDate: start, endDate: end },
        }
      );
      if (response.data.length === 0) {
        setError(
          `No data found for the selected date range: ${start} to ${end}`
        );
      } else {
        setError(null);
        setRegistrations(response.data);
        filterRegistrations(response.data, selectedStatus, patientSource);
      }
    } catch (error) {
      setError(
        `Error fetching data for the selected date range: ${start} to ${end}`
      );
    }
  };

  useEffect(() => {
    fetchRegistrations(startDate, endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    filterRegistrations(registrations, selectedStatus, patientSource);
  }, [selectedStatus, registrations, patientSource]);

  const filterRegistrations = (registrations, status, source) => {
    let filtered =
      status === "All"
        ? registrations
        : registrations.filter((reg) => reg.status === status);
    filtered =
      source === "All"
        ? filtered
        : filtered.filter((reg) => reg.source === source);
    setRegistrationCount(filtered.length);

    const total = filtered.reduce((sum, reg) => {
      const price = parseFloat(reg.price);
      if (isNaN(price)) {
        console.warn(`Invalid price value found: ${reg.price}`);
        return sum;
      }
      return sum + price;
    }, 0);

    setTotalPrice(total);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handleSourceChange = (e) => {
    setPatientSource(e.target.value);
  };

  return (
    <div className="p-4">
      <h2>Admission Report</h2>
      <Form className="flex mb-3">
        <Row>
          <Col lg={3} md={6} sm={6} xs={6}>
            <Form.Group controlId="startDate" className="mr-3">
              <Form.Label className="font-bold">From Date</Form.Label>
              <Form.Control
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
              />
            </Form.Group>
          </Col>
          <Col lg={3} md={6} sm={6} xs={6}>
            <Form.Group controlId="endDate" className="mr-3">
              <Form.Label className="font-bold">To Date</Form.Label>
              <Form.Control
                type="date"
                value={endDate}
                onChange={handleEndDateChange}
              />
            </Form.Group>
          </Col>
          <Col lg={3} md={6} sm={6} xs={6}>
            <Form.Group controlId="status">
              <Form.Label className="font-bold"> Status</Form.Label>
              <Form.Control
                as="select"
                value={selectedStatus}
                onChange={handleStatusChange}
              >
                <option value="All">All</option>
                <option value="admitted">Admitted</option>
                <option value="discharge">Discharged</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col lg={3} md={6} sm={6} xs={6}>
            <Form.Group controlId="source">
              <Form.Label className="font-bold">Patient Source</Form.Label>
              <Form.Control
                as="select"
                value={patientSource}
                onChange={handleSourceChange}
              >
                <option value="All">All</option>
                <option value="hospital">Hospital</option>
                <option value="camp">Camp</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </Form>
      {error && <p className="text-danger">{error}</p>}
      {registrationCount > 0 && !error ? (
        <>
          <p className="text-red-700 text-2xl font-bold">
            Total Admission:{" "}
            <span className="text-green-900">{registrationCount}</span>
          </p>
          {/* <p className="text-red-700 text-lg font-semibold">Total Collection: <span className="text-green-900"> ₹{totalPrice.toFixed(2)}</span></p> */}
          <div className="table-responsive mt-4">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Admission Date</th>
                  <th>Name</th>
                  <th>Address</th>
                  <th>Mobile Number</th>
                  <th>Age</th>
                  <th>Gender</th>
                  <th>Doctor</th>
                  <th>Guardian Name</th>
                  <th>Guardian Number</th>
                </tr>
              </thead>
              <tbody>
                {registrations
                  .filter(
                    (reg) =>
                      (selectedStatus === "All" ||
                        reg.status === selectedStatus) &&
                      (patientSource === "All" || reg.source === patientSource)
                  )
                  .map((reg) => (
                    <tr key={reg.id}>
                      <td>{format(new Date(reg.createdAt), "yyyy-MM-dd")}</td>
                      <td>{reg.name}</td>
                      <td>{reg.address}</td>
                      <td>{reg.mobilenumber}</td>
                      <td>{reg.age}</td>
                      <td>{reg.sex}</td>
                      <td>{reg.doctor}</td>
                      <td>{reg.guardianname}</td>
                      <td>{reg.guardiannumbaer}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        !error && (
          <p className="text-red-700">
            No data found for the selected date range.
          </p>
        )
      )}
    </div>
  );
}

export default AdmissionReport;
