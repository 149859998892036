import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row, Modal } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { Input, Radio, RadioGroup } from '@mui/joy';
import Webcam from 'react-webcam';
import logo from '../assets/logo/Rotarylogo.png';
import CreatableSelect from 'react-select/creatable';
import { useAuth } from '../context/AuthContext';

const initialState = {
  rpcode: '',
  date: new Date().toISOString().split('T')[0],
  location: '',
  name: '',
  image: '',
  mobilenumber: '',
  sex: '',
  age: '',
  guardiannumber: '',
  guardianname: '',
  doctorname: '',
  doctordesignation: '',
  time: new Date().toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  }),
  type: '',
  price: '',
  status: 'not-checked',
};

function CreateAppointmentForm({ onCreate, onCancel }) {
  const { email, username } = useAuth();
  const [doctorList, setDoctorList] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState({});
  const [state, setState] = useState(initialState);
  const [error, setError] = useState();
  const [showCaptureButton, setShowCaptureButton] = useState(true);
  const webcamRef = useRef(null);
  const [registationCode, setRegistationCode] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [regiPatiant, setRegistationPatiant] = useState([]);
  console.log('infiel', registationCode);
  console.log('state', state);
  const {
    rpcode,
    date,
    location,
    name,
    image,
    mobilenumber,
    sex,
    age,
    guardiannumber,
    guardianname,
    doctorname,
    doctordesignation,
    time,
    type,
    price,
    status,
  } = state;

  const displayName = username || 'Guest';

  const { id } = useParams();
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const fetchNextRegistationCode = async () => {
      try {
        const response = await axios.get(
          'https://hospital-api.hisabdev.com/api/registation/nextregistationcode'
        );
        setRegistationCode(response.data.RegistationCode);
      } catch (error) {
        console.error('Error fetching next Registation code:', error);
        toast.error('!Opps Somthing Wrong');
      }
    };
    fetchNextRegistationCode();
  }, []);

  const getCurrentDateAndTime = () => {
    const now = new Date();
    setCurrentDate(now);
    setCurrentTime(now);
  };

  useEffect(() => {
    getCurrentDateAndTime();
  }, []);

  const capturePhoto = () => {
    // Capture the image from the webcam as a base64 string
    const base64Photo = webcamRef.current.getScreenshot({
      screenshotFormat: 'image/jpeg',
      quality: 1.0,
    });

    if (base64Photo) {
      // Convert base64 to a binary string
      const byteString = atob(base64Photo.split(',')[1]);

      // Extract the mime type from the base64 string
      const mimeType = base64Photo.split(',')[0].split(':')[1].split(';')[0];

      // Create an ArrayBuffer to hold the binary data
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      // Write the binary data into the ArrayBuffer
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      // Create a Blob from the binary data
      const blob = new Blob([ab], { type: mimeType });

      // Create an Object URL from the Blob
      const imageUrl = URL.createObjectURL(blob);

      // Set the URL into the state (or use it directly)
      setState({ ...state, image: imageUrl });
      setShowCaptureButton(false);

      // Log the URL so you can use it in a browser like Chrome
      console.log(imageUrl); // Paste this URL into Chrome to view the image

      // Optional: Revoke the object URL when no longer needed to free up memory
      // URL.revokeObjectURL(imageUrl);
    } else {
      console.error('No image captured.');
    }
  };

  const handleRecapture = () => {
    setState({ ...state, photo: null });
    setShowCaptureButton(true);
  };

  const videoConstraints = {
    facingMode: 'environment',
  };

  const validateForm = () => {
    const requiredFields = [
      state.name,
      state.mobilenumber,
      state.doctorname,
      state.type,
      state.sex,
      state.age,
    ];
    const isValid = requiredFields.every(
      (field) => field && field.toString().trim() !== ''
    );
    setIsFormValid(isValid);
  };

  useEffect(() => {
    validateForm();
  }, [state]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'doctorname') {
      const doctor = doctorList.find((doc) => doc.doctorname === value);
      setSelectedDoctor(doctor);
      setState((prevState) => ({
        ...prevState,
        [name]: value,
        doctordesignation: doctor ? doctor.designation : '',
      }));
    } else if (name === 'type') {
      let newPrice = '';
      if (value === 'Free') {
        newPrice = '0.00';
      } else if (selectedDoctor) {
        switch (value) {
          case 'Special':
            newPrice = selectedDoctor.specialFees;
            break;
          case 'General':
            newPrice = selectedDoctor.generalFees;
            break;
          case 'OnCall':
            newPrice = selectedDoctor.onCallFees;
            break;
          default:
            newPrice = '';
        }
      }
      setState((prevState) => ({
        ...prevState,
        [name]: value,
        price: newPrice,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    validateForm();
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (!name || !mobilenumber || !sex || !age) {
  //     toast.error('Please provide value into * input field');
  //   } else {
  //     if (!id) {
  //       axios
  //         .post('https://hospital-api.hisabdev.com/api/registation/createregistation', {
  //           rpcode,
  //           date,
  //           location,
  //           name,
  //           image,
  //           mobilenumber,
  //           sex,
  //           age,
  //           guardiannumber,
  //           guardianname,
  //           doctorname,
  //           doctordesignation,
  //           time,
  //           type,
  //           price,
  //           status,
  //         })
  //         .then((response) => {
  //           console.log("response",response);
  //           const registrationId = response.data.id; 

  //           return axios.post('https://hospital-api.hisabdev.com/api/trans/create', {
  //             voucher_no: rpcode, 
  //             date: date,
  //             patient_id: registrationId, 
  //             name: name,
  //             particular: "registration",
  //             net_amount: price,
  //             received_amount: price,
  //             type: type || 'cash',
  //           });
  //         })
  //         .then(() => {
  //           setState(initialState);
  //           console.log('classdetails', state);
  //           setShowModal(true);
  //         })
  //         .catch((err) => toast.error(err.response.data));
  //       toast.success('Successfully Created');
  //     }
  //   }
  // };


  const handleSubmit = (e) => {
    e.preventDefault();

    if (!name || !mobilenumber || !sex || !age) {
      toast.error('Please provide value into * input field');
    } else {
      if (!id) {
        // Create the registration first
        axios
          .post('https://hospital-api.hisabdev.com/api/registation/createregistation', {
            rpcode,
            date,
            location,
            name,
            image,
            mobilenumber,
            sex,
            age,
            guardiannumber,
            guardianname,
            doctorname,
            doctordesignation,
            time,
            type,
            price,
            status,
          })
          .then((res) => {
            console.log("Registration res:", res);

            // Check if the response has the ID of the new registration
            const registrationId = res.data.id;
            if (!registrationId) {
              throw new Error('Registration ID not found in response');
            }

            // Create the transaction linked to the registration
            return axios.post('https://hospital-api.hisabdev.com/api/trans/create', {
              voucher_no: registrationId,
              date: date,
              patient_id: registrationId,
              name: name,
              particular: "registration",
              net_amount: price,
              received_amount: price,
              type: 'Received',
            });
          })
          .then((response) => {
            console.log("Transaction response:", response);
            setState(initialState);
            setShowModal(true);
            toast.success('Successfully Created');
          })
          .catch((err) => {
            if (err.response && err.response.data) {
              toast.error(err.response.data);
              console.log("Error response:", err.response.data);
            } else {
              toast.error(err.message || 'Error occurred');
              console.log("Error:", err);
            }
          });
      }
    }
  };


  const loadDoctorData = async () => {
    try {
      const response = await axios.get('https://hospital-api.hisabdev.com/api/doctor');
      setDoctorList(response.data[0]);
    } catch (error) {
      setError(error.message);
    }
  };

  const loadRegistrationData = async () => {
    try {
      const response = await axios.get('https://hospital-api.hisabdev.com/api/registation');
      setRegistationPatiant(response.data[0]);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    loadDoctorData();
    loadRegistrationData();
  }, []);

  const calculateDateDifference = (date1, date2) => {
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const handleSearchChange = (newValue, actionMeta) => {
    if (actionMeta.action === 'select-option') {
      const selectedPatient = newValue ? newValue.patient : null;
      if (selectedPatient) {
        const registrationDate = new Date(selectedPatient.date);
        const today = new Date();
        const daysDifference = calculateDateDifference(registrationDate, today);

        let newPrice = selectedPatient.price;
        if (daysDifference <= 8) {
          newPrice = (parseFloat(selectedPatient.price) / 2).toFixed(2);
        }
        setState({
          ...state,
          name: selectedPatient.name,
          rpcode: selectedPatient.rpcode,
          location: selectedPatient.location,
          mobilenumber: selectedPatient.mobilenumber,
          pincode: selectedPatient.pincode,
          block: selectedPatient.block,
          age: selectedPatient.age,
          sex: selectedPatient.sex,
          guardianname: selectedPatient.guardianname,
          guardiannumber: selectedPatient.guardiannumber,
          doctorname: selectedPatient.doctorname,
          type: selectedPatient.type,
          price: newPrice,
        });
        setRegistationCode(selectedPatient.rpcode);
      }
    } else if (actionMeta.action === 'create-option') {
      setState((prevState) => ({
        ...prevState,
        name: newValue,
        location: '',
        mobilenumber: '',
        pincode: '',
        block: '',
        age: '',
        sex: '',
        guardianname: '',
        guardiannumber: '',
        doctorname: '',
        price: '',
        registationCode: prevState.registationCode, // retain the generated registration code
      }));
      setRegistationCode(registationCode);
    }
  };

  const getSearchOptions = () => {
    return regiPatiant.map((patient) => ({
      value: patient.id,
      label: `${patient.name} (${patient.mobilenumber})`,
      patient,
    }));
  };

  const handlePrint = () => {
    window.print();
  };

  const handleModalClose = () => {
    setShowModal(false);
    onCancel();
  };

  return (
    <div className="container p-2">
      <Form onSubmit={handleSubmit}>
        <Row className="p-2">
          <Col lg={12} className="p-1">
            <div className="bg-sky-200 rounded p-2 drop-shadow">
              <div className="py-2">
                <h2 className="text-lg font-semibold">Patient Details:</h2>
                <Row>
                  <Col lg={4} md={4} sm={12}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Patient ID
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="mobilenumber"
                        name="id"
                        value={registationCode}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Date
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="date"
                        value={currentDate.toISOString().split('T')[0]}
                        onChange={handleInputChange}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Time
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="time"
                        name="time"
                        value={currentTime.toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                        onChange={handleInputChange}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={3} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Name <span className="required-icon">*</span>
                      </Form.Label>
                      <CreatableSelect
                        required
                        id="name"
                        name="name"
                        isClearable
                        options={getSearchOptions()}
                        value={
                          state.name
                            ? { label: state.name, value: state.name }
                            : null
                        }
                        onChange={handleSearchChange}
                        onCreateOption={(value) =>
                          handleSearchChange(value, { action: 'create-option' })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={5} md={3} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Address <span className="required-icon">*</span>
                      </Form.Label>
                      <Input
                        required
                        placeholder="Enter Patient Address"
                        type="text"
                        name="location"
                        value={location || ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Image
                      </Form.Label>
                      <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={320}
                        height={240}
                        videoConstraints={videoConstraints}
                      />
                      {showCaptureButton && (
                        <Button onClick={capturePhoto}>Capture</Button>
                      )}
                      {!showCaptureButton && (
                        <Button onClick={handleRecapture}>Re-capture</Button>
                      )}
                      {state.photo && (
                        <img
                          src={state.image}
                          alt="Captured"
                          width={320}
                          height={240}
                        />
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Mobile Number <span className="required-icon">*</span>
                      </Form.Label>
                      <Input
                        required
                        placeholder="Enter Mobile Number"
                        type="text"
                        id="mobilenumber"
                        name="mobilenumber"
                        value={mobilenumber || ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={1} md={3} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Gender <span className="required-icon">*</span>
                      </Form.Label>
                      <RadioGroup
                        required
                        name="sex"
                        value={sex}
                        onChange={handleInputChange}
                      >
                        <Radio value="Male" label="Male" />
                        <Radio value="Female" label="Female" />
                        <Radio value="Other" label="Other" />
                      </RadioGroup>
                    </Form.Group>
                  </Col>
                  <Col lg={2} md={3} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Age <span className="required-icon">*</span>
                      </Form.Label>
                      <Input
                        required
                        placeholder="Enter Age"
                        type="number"
                        id="age"
                        name="age"
                        value={age || ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Guardian Name
                      </Form.Label>
                      <Input
                        placeholder="Enter Guardian Name"
                        type="text"
                        id="guardianname"
                        name="guardianname"
                        value={guardianname || ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Guardian Number
                      </Form.Label>
                      <Input
                        placeholder="Enter Guardian Number"
                        type="text"
                        id="guardiannumber"
                        name="guardiannumber"
                        value={guardiannumber || ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Select Doctor <span className="required-icon">*</span>
                      </Form.Label>
                      <Form.Control
                        required
                        as="select"
                        name="doctorname"
                        value={doctorname}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Doctor</option>
                        {doctorList.map((doctor) => (
                          <option
                            key={doctor.doctorname}
                            value={doctor.doctorname}
                          >
                            {doctor.doctorname} - {doctor.doctorSpecialist}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Doctor Designation
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="doctordesignation"
                        name="doctordesignation"
                        value={doctordesignation || ''}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={2} md={4} sm={12}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Type <span className="required-icon">*</span>
                      </Form.Label>
                      <Form.Control
                        required
                        as="select"
                        name="type"
                        value={type}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Type</option>
                        <option value="General">General</option>
                        <option value="Special">Special</option>
                        <option value="OnCall">OnCall</option>
                        <option value="Free">Free</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col lg={2} md={4} sm={12}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Price
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="price"
                        name="price"
                        value={price || ''}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col lg={1} md={3} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        are you human <span className="required-icon">*</span>
                      </Form.Label>
                      <RadioGroup
                        required
                        name="sex"
                        // value={sex}
                        // onChange={handleInputChange}
                      >
                        <Radio value="YES" label="YES" />
                      </RadioGroup>
                    </Form.Group>
                  </Col> */}
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <div className="flex justify-content-between my-4">
          <Button
            variant="danger"
            onClick={onCancel}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="px-4 py-2"
            onClick={() => setShowModal(true)}
            disabled={!isFormValid}
          >
            Create
          </Button>
        </div>
      </Form>

      <Modal
        className="printable-content"
        show={showModal}
        onHide={handleModalClose}
        centered
      // size="lg"
      >
        <Modal.Header closeButton>
          <div className="d-flex flex-column align-items-start w-100">
            <div className="d-flex align-items-center">
              <img
                src={logo}
                alt="Logo"
                style={{ height: '50px', marginRight: '20px' }}
              />
              <div className="flex-row">
                <Modal.Title className="mb-0">
                  Rotary Beldanga Eye Hospital
                </Modal.Title>
                <p className="mb-0 text-sm">
                  (Unity of Rotary Domkal Murshidabad Welfare Trust)
                </p>
              </div>
            </div>
            <div className="mt-2 text-center items-center">
              <p className="mb-0">
                vill+P.O.-Barua(NH34),P.S.,Beldanga,Murshidabad,West Bengal
              </p>
              <p className="mb-0">Contact No: 03482 264010/ Mob: 8641941515</p>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="flex justify-between">
            <div className="row mb-3">
              <div className="col-4 text-muted">Date:</div>
              <div className="col-8 fw-bold">{date}</div>
            </div>
            <div className="row mb-3">
              <div className="col-4 text-muted">Time:</div>
              <div className="col-8 fw-bold">{time}</div>
            </div>
          </div>
          <div>
            {/* <h4 className="mb-4">Appointment Details</h4> */}
            <div className="row mb-3">
              <div className="col-2 text-muted">Doctor:</div>
              <div className="col-10 fw-bold">{doctorname}</div>
            </div>

            <div className="row mb-3">
              <div className="col-2 text-muted">PatientID:</div>
              <div className="col-10 fw-bold">{registationCode}</div>
            </div>
            <div className="row mb-3 ">
              <div className="col-2 text-muted">Name:</div>
              <div className="col-10 fw-bold">{name}</div>
            </div>
            <div className="flex justify-between">
              <div className="row mb-3">
                <div className="col-6 text-muted">Age:</div>
                <div className="col-6 fw-bold">{age}</div>
              </div>
              <div className="row mb-3">
                <div className="col-6 text-muted">Gender:</div>
                <div className="col-6 fw-bold">{sex}</div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-2 text-muted">Amount:</div>
              <div className="col-10 fw-bold">{price}</div>
            </div>
            <div className="flex justify-between">
              <div className="row mb-3">
                <div className="col-4 text-muted">User:</div>
                <div className="col-8 fw-bold">{displayName}</div>
              </div>
              <div className="row mb-3">
                <div className="col-4 text-muted">Authorized</div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-btn">
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handlePrint}>
            Print Ticket
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CreateAppointmentForm;
