// import React, { useState, useEffect } from "react";
// import { Button, Col, Form, Row } from "react-bootstrap";
// import { useParams } from "react-router-dom";
// import { toast } from "react-toastify";
// import axios from "axios";
// import { Input } from "@mui/joy";

// const initialState = {
//   testname: "",
//   amount: "",
//   day: "",
//   inHouse: false,
// };

// function CreateTest({ onCreate, onCancel, customers, model }) {
//   const [state, setState] = useState(initialState);
//   const { testname, amount, day, inHouse  } = state;
//   const { id } = useParams();

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (!testname || !amount) {
//       toast.error("Please provide value into each inpute field ");
//     } else {
//       if (!id) {
//         axios
//           .post("https://hospital-api.hisabdev.com/api/test/createtest", {
//             testname,
//             amount,
//             day,
//             inHouse
//           })
//           .then(() => {
//             setState(initialState);
//           })
//           .catch((err) => toast.error(err.respose.data));
//         toast.success("Successfully");
//       } else {
//         axios
//           .put(`https://hospital-api.hisabdev.com/api/createappointment/${id}`, {
//             testname,
//             amount,
//             day,
//           })
//           .then(() => {
//             setState({
//               testname: "",
//               amount: "",
//               day: "",
//             });
//             console.log("classdetails", state);
//           })
//           .catch((err) => toast.error(err.respose.data));
//         toast.success("update Successfully");
//       }
//       setTimeout(() => {
//         onCancel();
//       }, 500);
//     }
//   };

//   const handleInputChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     setState({
//       ...state,
//       [name]: type === "checkbox" ? checked : value,
//     });
//   };

//   return (
//     <div className="bg-sky-200 p-4 drop-shadow rounded-xl">
//       <h2 className="text-xl font-semibold ">Create {model}</h2>
//       <Form onSubmit={handleSubmit}>
//         <div className=" py-2">
//           <Row>
//             <Col lg={4} md={6} sm={12}>
//               <Form.Group controlId="PATIENT_ID">
//                 <Form.Label className="block text-gray-700 font-medium">
//                   Test Name
//                 </Form.Label>
//                 <Input
//                   required
//                   className=""
//                   placeholder="Test Name"
//                   type="text"
//                   name="testname"
//                   value={testname || ""}
//                   onChange={handleInputChange}
//                 />
//               </Form.Group>
//             </Col>
//             <Col lg={4} md={6} sm={12}>
//               <Form.Group>
//                 <Form.Label className=" text-gray-700 font-medium">
//                   Amount
//                 </Form.Label>
//                 <Input
//                   required
//                   type="number"
//                   placeholder="Amount"
//                   name="amount"
//                   value={amount || ""}
//                   onChange={handleInputChange}
//                 />
//               </Form.Group>
//             </Col>
//             <Col lg={4} md={6} sm={12}>
//               <Form.Group>
//                 <Form.Label className=" text-gray-700 font-medium">
//                   Day's
//                 </Form.Label>
//                 <Input
//                   type="text"
//                   placeholder="Day"
//                   name="day"
//                   value={day || ""}
//                   onChange={handleInputChange}
//                 />
//               </Form.Group>
//             </Col>
//             <Col lg={4} md={6} sm={12} className="mt-4">
//               <Form.Group>
//                 <Form.Check
//                   type="checkbox"
//                   label="In House"
//                   name="inHouse"
//                   checked={inHouse}
//                   onChange={handleInputChange}
//                 />
//                 <Form.Check
//                   type="checkbox"
//                   label="Out"
//                   name="inHouse"
//                   checked={!inHouse}
//                   onChange={handleInputChange}
//                 />
//               </Form.Group>
//             </Col>
//           </Row>
//         </div>
//         <div className="flex justify-content-between my-4">
//           <Button
//             variant="danger"
//             onClick={onCancel}
//             className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
//           >
//             Cancel
//           </Button>
//           <Button
//             type="submit"
//             className="px-4 py-2"
//             onClick={() => console.log("click")}
//           >
//             Create
//           </Button>
//         </div>
//       </Form>
//     </div>
//   );
// }

// export default CreateTest;



import React, { useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { Input } from "@mui/joy";

const initialState = {
  testname: "",
  amount: "",
  day: "",
  inHouse: false,
};

function CreateTest({ onCreate, onCancel, model }) {
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false); // New loading state
  const { testname, amount, day, inHouse } = state;
  const { id } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!testname || !amount) {
      toast.error("Please provide value into each input field");
    } else {
      setLoading(true); // Set loading to true when form is submitting
      const apiCall = !id
        ? axios.post("https://hospital-api.hisabdev.com/api/test/createtest", { testname, amount, day, inHouse })
        : axios.put(`https://hospital-api.hisabdev.com/api/test/updatetest/${id}`, { testname, amount, day, inHouse });

      apiCall
        .then(() => {
          setState(initialState);
          toast.success(id ? "Update Successful" : "Created Successfully");
          onCreate && onCreate();
        })
        .catch((err) => toast.error(err.response.data))
        .finally(() => {
          setLoading(false); // Set loading to false after request completes
          onCancel();
        });
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setState({
      ...state,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  return (
    <div className="bg-sky-200 p-4 drop-shadow rounded-xl">
      <h2 className="text-xl font-semibold ">Create {model}</h2>
      <Form onSubmit={handleSubmit}>
        <div className="py-2">
          <Row>
            <Col lg={4} md={6} sm={12}>
              <Form.Group controlId="testname">
                <Form.Label className="block text-gray-700 font-medium">Test Name<span className="required-icon">*</span></Form.Label>
                <Input
                  required
                  placeholder="Test Name"
                  type="text"
                  name="testname"
                  value={testname}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group controlId="amount">
                <Form.Label className="text-gray-700 font-medium">Amount<span className="required-icon">*</span></Form.Label>
                <Input
                  required
                  type="number"
                  placeholder="Amount"
                  name="amount"
                  value={amount}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group controlId="day">
                <Form.Label className="text-gray-700 font-medium">Day's</Form.Label>
                <Input
                  type="text"
                  placeholder="Day"
                  name="day"
                  value={day}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12} className="mt-4">
              <Form.Group controlId="inHouse">
                <Form.Check
                  type="checkbox"
                  label="In House"
                  name="inHouse"
                  checked={inHouse}
                  onChange={handleInputChange}
                />
                <Form.Check
                  type="checkbox"
                  label="Out"
                  name="inHouse"
                  checked={!inHouse}
                  onChange={() => setState({ ...state, inHouse: !inHouse })}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="flex justify-content-between my-4">
          <Button
            variant="danger"
            onClick={onCancel}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="px-4 py-2"
            disabled={loading} // Disable button while loading
          >
            {loading ? <Spinner animation="border" size="sm" /> : id ? "Update" : "Create"}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default CreateTest;
