// Customers.js
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Table from "../../components/Table";
import { useGlobalState } from "../../context/GlobalStateContext";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import CreateProduct from "../../components/CreateProduct";
import { FaPen } from "react-icons/fa";
import GrowExample from "../../components/spiner/GrowExample";

function Product() {
  const [data, setData] = useState([]);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname;
  const parts = url.split("/");
  const model = parts[parts.length - 1];
  const [error, setError] = useState(null);

  const loadData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/product");
      setData(response.data[0]);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(true);
    }
  };

  useEffect(() => {
    loadData();
  }, [showCreateForm]);

  const deleteContact = (id) => {
    if (
      window.confirm("Are you sure that you wanted to delete that Medicine ?")
    ) {
      axios.delete(`https://hospital-api.hisabdev.com/api/product/removeproduct/${id}`);
      toast.success("Product Delete Successfully");
      setTimeout(() => loadData(), 500);
    }
  };

  const columns = [
    {
      header: "Product Code",
      accessorKey: "productcode",
    },
    {
      header: "Description",
      accessorKey: "Description",
    },
    {
      header: "Purches Unit",
      accessorKey: "purchesunit",
    },
    {
      header: "Stock",
      accessorKey: "Stock",
    },
    {
      header: "Sale",
      accessorKey: "sale",
    },
    {
      header: "HSN/SAC code",
      accessorKey: "hsnsaccode",
    },
    {
      header: "Tax Category",
      accessorKey: "taxcategory",
    },
    {
      header: "Group",
      accessorKey: "productgroup",
    },
    {
      header: "Sub-Gropu",
      accessorKey: "productsubgroup",
    },
    // {
    //     header: "Purches Date",
    //     accessorKey: "purchasedate",
    // },
    {
      header: "Expiry Date",
      accessorKey: "expdate",
    },
    {
      header: "Opening",
      accessorKey: "opening",
    },
    {
      header: "Buy Rate",
      accessorKey: "buyrate",
    },
    {
      header: "Sales Rate",
      accessorKey: "salerate",
    },
    {
      header: "Batch  Number",
      accessorKey: "batchnumber",
    },
    {
      header: "Lance code",
      accessorKey: "lancecode",
    },
  ];

  const handleView = (data) => {
    navigate(`/${model}/${data}`);
  };

  const handleUpdate = (data) => {
    navigate(`/product/${data}`);
  };
  return (
    <div className="container p-2 ">
      {/* <h2 className="text-xl font-bold mb-4">{model.charAt(0).toUpperCase() + model.slice(1)}s</h2> */}
      {showCreateForm ? (
        <CreateProduct
          onCancel={() => {
            setShowCreateForm(false);
          }}
          customers={data}
          model={model}
        />
      ) : (
        <div className="flex justify-between">
          <Button
            className="mb-2 drop-shadow"
            onClick={() => setShowCreateForm(true)}
          >
            {" "}
            Add {model}
          </Button>
        </div>
      )}
      {isLoading && !data[0] && (
        <div className="w-full h-60 flex justify-center items-center">
          <GrowExample />
        </div>
      )}
      {showCreateForm ? null : data[0] ? (
        <div className="drop-shadow">
          <Table
            data={data}
            columns={[
              ...columns,
              {
                header: "Actions",
                cell: (rowData) => {
                  return (
                    <div className="flex justify-between">
                      {/* <button
                        onClick={() => handleView(rowData?.row?.original?.id)}
                        className="btn btn-primary btn-sm me-2"
                      >
                        {" "}
                        <FaEye />{" "}
                      </button> */}
                      <button
                        onClick={() => handleUpdate(rowData?.row?.original?.id)}
                        className="btn btn-primary btn-sm me-2"
                      >
                        {" "}
                        <FaPen />{" "}
                      </button>
                      <button
                        onClick={() =>
                          deleteContact(rowData?.row?.original?.id)
                        }
                        className="btn btn-danger btn-sm"
                      >
                        {" "}
                        <MdDelete />{" "}
                      </button>
                    </div>
                  );
                },
              },
            ]}
            pageLimit={10}
          />
        </div>
      ) : !isLoading ? (
        <p className="text-red-400 font-semibold w-max mx-auto my-20">
          No Medicine's Data
        </p>
      ) : (
        ""
      )}
    </div>
  );
}

export default Product;
