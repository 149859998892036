import React from 'react';

const DisplayImage = ({ imageUrl }) => {

    // console.log('imageUrlisplayimage', imageUrl);
  return (
    <div>
      <h5>Saved Retinoscope Image:</h5>
      <img src={imageUrl} alt="Retinoscope" style={{ maxWidth: '100%' }} />
    </div>
  );
};

export default DisplayImage;
