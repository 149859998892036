import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Button, Form, Col, Row, Modal, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DynamicSalesLensTable from './DynamicSalesLensTable';
import logo from '../assets/logo/Rotarylogo.png';

const initialState = {
  BillNo: '',
  BillDate: '',
  CustCode: '',
  CustName: '',
  PaymentMode: '',
  AdvancePayment: 0,
  DuePayment: 0,
  items: [],
};

const SalesLensFrom = ({ onCreate, onCancel }) => {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const [tableData, setTableData] = useState([
    [null, null, null, null, null, null, null, null, null, null, null],
  ]);
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  console.log('state', state);

  useEffect(() => {
    // Set default BillDate to today
    const today = new Date().toISOString().split('T')[0];
    setState((prevState) => ({ ...prevState, BillDate: today }));

    // Generate a unique BillNo
    const generateUniqueBillNo = () => {
      const uniqueId = `BL${Date.now()}`;
      return uniqueId;
    };

    // Generate a unique CustCode
    const generateUniqueCustCode = () => {
      // For simplicity, generating a random 4-digit number
      const randomNumber = Math.floor(1000 + Math.random() * 9000);
      return `CUS${randomNumber}`;
    };

    setState((prevState) => ({
      ...prevState,
      BillNo: generateUniqueBillNo(),
      CustCode: generateUniqueCustCode(),
    }));
  }, []);

  const {
    BillNo,
    BillDate,
    CustCode,
    CustName,
    PaymentMode,
    AdvancePayment,
    DuePayment,
    items,
  } = state;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!BillNo || !CustName) {
      toast.error('Please provide a value for each input field');
    } else {
      const salesData = {
        BillNo,
        BillDate,
        CustCode,
        CustName,
        PaymentMode,
        AdvancePayment,
        DuePayment,
        items: tableData.map((row) => ({
          ItemCode: row[0],
          Description: row[1],
          Quantity: row[2],
          UOM: row[3],
          Rate: row[4],
          ProductTotal: row[5],
          Discount: row[6],
          TaxableAmount: row[7],
          GSTRate: row[8],
          GSTAmount: row[9],
          Amount: row[10],
        })),
      };

      axios
        .post('https://hospital-api.hisabdev.com/api/sales/create', salesData)
        .then(() => {
          setModalData(salesData);
          setState(initialState);
          setTableData([
            [null, null, null, null, null, null, null, null, null, null, null],
          ]);
          toast.success('Successfully saved.');
          setShowModal(true);
        })
        .catch((err) => toast.error(err.response.data));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });

    // If AdvancePayment is changed, recalculate DuePayment
    if (name === 'AdvancePayment') {
      const totalAmount = calculateTotalAmount();
      const duePayment = totalAmount - parseFloat(value || 0);
      setState((prevState) => ({ ...prevState, DuePayment: duePayment }));
    }
  };

  const calculateTotalAmount = () => {
    let total = 0;
    tableData.forEach((row) => {
      total += parseFloat(row[10]) || 0; // Assuming Amount is at index 10
    });
    return total;
  };

  const handleCloseModal = () => {
    setShowModal(false);
    onCancel();
  };

  return (
    <div className="bg-sky-200 p-4 drop-shadow rounded-xl">
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col lg={3} md={6} sm={12}>
            <Form.Group as={Col}>
              <Form.Label>
                Bill No.<span className="required-icon">*</span>
              </Form.Label>
              <Form.Control
                required
                type="text"
                name="BillNo"
                value={BillNo || ''}
                placeholder="BL001"
                readOnly
              />
            </Form.Group>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Form.Group as={Col}>
              <Form.Label>Bill Date</Form.Label>
              <Form.Control
                type="date"
                name="BillDate"
                value={BillDate || ''}
                onChange={handleInputChange}
                placeholder="Bill Date"
              />
            </Form.Group>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Form.Group as={Col}>
              <Form.Label>
                Cust Code<span className="required-icon">*</span>
              </Form.Label>
              <Form.Control
                required
                readOnly
                type="text"
                name="CustCode"
                value={CustCode || ''}
                onChange={handleInputChange}
                placeholder="Cust Code"
              />
            </Form.Group>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Form.Group as={Col}>
              <Form.Label>Cust Name</Form.Label>
              <Form.Control
                type="text"
                name="CustName"
                value={CustName || ''}
                onChange={handleInputChange}
                placeholder="Cust Name"
              />
            </Form.Group>
          </Col>
          <Col lg={12} md={12} sm={12}>
            <Form.Label></Form.Label>
            <DynamicSalesLensTable
              tableData={tableData}
              setTableData={setTableData}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Form.Group as={Col}>
              <Form.Label>
                Payment Mode<span className="required-icon">*</span>
              </Form.Label>
              <Form.Control
                required
                as="select"
                name="PaymentMode"
                value={PaymentMode}
                onChange={handleInputChange}
              >
                <option value="">Select Payment Mode</option>
                <option value="Cash">Cash</option>
                <option value="UPI">UPI</option>
                <option value="Card">Card</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Form.Group as={Col}>
              <Form.Label>Advance Payment</Form.Label>
              <Form.Control
                type="number"
                name="AdvancePayment"
                value={AdvancePayment || 0}
                onChange={handleInputChange}
                placeholder="Advance Payment"
              />
            </Form.Group>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Form.Group as={Col}>
              <Form.Label>Due Payment</Form.Label>
              <Form.Control
                type="number"
                name="DuePayment"
                value={DuePayment || 0}
                readOnly
                placeholder="Due Payment"
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="flex justify-content-between my-4">
          <Button
            variant="danger"
            onClick={onCancel}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </div>
      </Form>
      <Modal
        className="printable-content"
        show={showModal}
        onHide={handleCloseModal}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <div className="d-flex flex-column align-items-center justify-between w-100">
            <div className="d-flex align-items-center">
              <img
                src={logo}
                alt="Logo"
                style={{ height: '50px', marginRight: '20px' }}
              />
              <div className="flex-row">
                <Modal.Title className="mb-0">
                  Rotary Beldanga Eye Hospital
                </Modal.Title>
                <p className="mb-0 text-sm">
                  (Unity of Rotary Domkal Murshidabad Welfare Trust)
                </p>
              </div>
            </div>
            <div className="mt-2 text-center items-center">
              <p className="mb-0 ml-8">
                vill+P.O.-Barua(NH34),P.S.,Beldanga,Murshidabad,West Bengal
              </p>
              <p className="mb-0">Contact No: 03482 264010/ Mob: 8641941515</p>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {modalData && (
            <>
              <h4>Bill No: {modalData.BillNo}</h4>
              <p>Bill Date: {modalData.BillDate}</p>
              <p>Cust Code: {modalData.CustCode}</p>
              <p>Cust Name: {modalData.CustName}</p>
              <p>Payment Mode: {modalData.PaymentMode}</p>
              <p>Advance Payment: {modalData.AdvancePayment}</p>
              <p>Due Payment: {modalData.DuePayment}</p>
              {/* <p>Due Payment: {modalData.DuePayment + modalData.AdvancePayment}</p> */}
              <h5>Items:</h5>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>SL No.</th>
                    <th>Item Code</th>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>UOM</th>
                    <th>Rate</th>
                    <th>Product Total</th>
                    <th>Discount</th>
                    <th>Taxable Amount</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {modalData.items.map((row, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{row.ItemCode}</td>
                      <td>{row.Description}</td>
                      <td>{row.Quantity}</td>
                      <td>{row.UOM}</td>
                      <td>{row.Rate}</td>
                      <td>{row.ProductTotal}</td>
                      <td>{row.Discount}</td>
                      <td>{row.TaxableAmount}</td>
                      <td>{row.Amount}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="modal-btn">
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={() => window.print()}>
            Print
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SalesLensFrom;
