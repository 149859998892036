// import React, { useRef, useState } from 'react';
// import { ReactSketchCanvas } from 'react-sketch-canvas';

// const Retinoscope = () => {
//   const canvasRef = useRef(null);
//   const [eraseMode, setEraseMode] = useState(false);
//   const [strokeWidth, setStrokeWidth] = useState(5);
//   const [eraserWidth, setEraserWidth] = useState(10);
//   const [strokeColor, setStrokeColor] = useState("#000000");
//   const [canvasColor, setCanvasColor] = useState("#ffffff");
//   const [imageUrl, setImageUrl] = useState(''); // State to store the image URL
// console.log('imageUrl',imageUrl);
//   const handleStrokeColorChange = (event) => {
//     setStrokeColor(event.target.value);
//   };

//   const handleCanvasColorChange = (event) => {
//     setCanvasColor(event.target.value);
//   };

//   const handleEraserClick = () => {
//     setEraseMode(true);
//     canvasRef.current?.eraseMode(true);
//   };

//   const handlePenClick = () => {
//     setEraseMode(false);
//     canvasRef.current?.eraseMode(false);
//   };

//   const handleStrokeWidthChange = (event) => {
//     setStrokeWidth(+event.target.value);
//   };

//   const handleEraserWidthChange = (event) => {
//     setEraserWidth(+event.target.value);
//   };

//   const handleClear = (event) => {
//     event.preventDefault();
//     canvasRef.current?.clearCanvas();
//   };

//   const handleSave = async (event) => {
//     event.preventDefault();
//     const url = await canvasRef.current?.exportImage('png');
    
//     if (url) {
//       setImageUrl(url); // Update local state with the image URL
//     } else {
//       console.error("No image URL was returned from the canvas export.");
//     }
//   };

//   return (
//     <div className="d-flex flex-column gap-2 p-2">
//       <div className="d-flex gap-2 align-items-center">
//         <button
//           type="button"
//           className="btn btn-sm btn-outline-primary"
//           disabled={!eraseMode}
//           onClick={handlePenClick}
//         >
//           Pen
//         </button>
//         <button
//           type="button"
//           className="btn btn-sm btn-outline-primary"
//           disabled={eraseMode}
//           onClick={handleEraserClick}
//         >
//           Eraser
//         </button>
//         <label htmlFor="color">Pen color</label>
//         <input
//           type="color"
//           value={strokeColor}
//           onChange={handleStrokeColorChange}
//         />
//         <label htmlFor="color">Canvas color</label>
//         <input
//           type="color"
//           value={canvasColor}
//           onChange={handleCanvasColorChange}
//         />
//         <label htmlFor="strokeWidth" className="form-label">
//           Stroke width
//         </label>
//         <input
//           disabled={eraseMode}
//           type="range"
//           className="form-range"
//           min="1"
//           max="20"
//           step="1"
//           id="strokeWidth"
//           value={strokeWidth}
//           onChange={handleStrokeWidthChange}
//         />
//         <label htmlFor="eraserWidth" className="form-label">
//           Eraser width
//         </label>
//         <input
//           disabled={!eraseMode}
//           type="range"
//           className="form-range"
//           min="1"
//           max="20"
//           step="1"
//           id="eraserWidth"
//           value={eraserWidth}
//           onChange={handleEraserWidthChange}
//         />
//       </div>
//       <ReactSketchCanvas
//         ref={canvasRef}
//         strokeWidth={strokeWidth}
//         eraserWidth={eraserWidth}
//         strokeColor={strokeColor}
//         canvasColor={canvasColor}
//         style={{ border: '1px solid #000', marginBottom: '20px', width: '100%', height: '400px' }}
//       />
//       <div>
//         <button
//           style={{ marginRight: '10px' }}
//           className="btn btn-sm btn-outline-danger"
//           onClick={handleClear}
//         >
//           Clear
//         </button>
//         <button
//           style={{ marginLeft: '10px' }}
//           className="btn btn-sm btn-outline-success"
//           onClick={handleSave}
//         >
//           Save
//         </button>
//       </div>
//       {/* Display the saved image */}
//       {imageUrl && (
//         <div className="mt-3">
//           <h5>Saved Retinoscope Image:</h5>
//           <img src={imageUrl} imageUrl={imageUrl} alt="Retinoscope" style={{ maxWidth: '100%' }} />
//         </div>
//       )}
//     </div>
//   );
// };

// export default Retinoscope;


import React, { useRef, useState } from 'react';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import DisplayImage from './DisplayImage';
import UpdatePreCheckup from './UpdatePreCheckup';

const Retinoscope = ({setImageUrl,imageUrl}) => {
  const canvasRef = useRef(null);
  const [eraseMode, setEraseMode] = useState(false);
  const [strokeWidth, setStrokeWidth] = useState(5);
  const [eraserWidth, setEraserWidth] = useState(10);
  const [strokeColor, setStrokeColor] = useState("#000000");
  const [canvasColor, setCanvasColor] = useState("#ffffff");
  // const [imageUrl, setImageUrl] = useState(''); // State to store the image URL
  const [canvasKey, setCanvasKey] = useState(0); // State to force re-render
  const [showControls, setShowControls] = useState(true); // State to control visibility

  const handleStrokeColorChange = (event) => {
    setStrokeColor(event.target.value);
  };

  const handleCanvasColorChange = (event) => {
    setCanvasColor(event.target.value);
  };

  const handleEraserClick = () => {
    setEraseMode(true);
    canvasRef.current?.eraseMode(true);
  };

  const handlePenClick = () => {
    setEraseMode(false);
    canvasRef.current?.eraseMode(false);
  };

  const handleStrokeWidthChange = (event) => {
    setStrokeWidth(+event.target.value);
  };

  const handleEraserWidthChange = (event) => {
    setEraserWidth(+event.target.value);
  };

  const handleClear = (event) => {
    event.preventDefault();
    canvasRef.current?.clearCanvas();
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const url = await canvasRef.current?.exportImage('png');
    
    if (url) {
      setImageUrl(url); // Update local state with the image URL
      setCanvasKey(prevKey => prevKey + 1); // Force re-render by updating the key
      setShowControls(false); // Hide controls when saving image
    } else {
      console.error("No image URL was returned from the canvas export.");
    }
  };

  const handleDrawAgain = () => {
    setImageUrl(''); // Clear the saved image URL
    setShowControls(true); // Show controls again
    setCanvasKey(prevKey => prevKey + 1); // Force re-render by updating the key
  };

  return (
    <div className="d-flex flex-column gap-2 p-2">
      <button
        type="button"
        className="btn btn-sm btn-outline-secondary"
        onClick={() => setShowControls(prevState => !prevState)}
      >
        {showControls ? 'Hide Controls' : 'Show Controls'}
      </button>

      {showControls && !imageUrl && (
        <div className="d-flex gap-2 align-items-center">
          <button
            type="button"
            className="btn btn-sm btn-outline-primary"
            disabled={!eraseMode}
            onClick={handlePenClick}
          >
            Pen
          </button>
          <button
            type="button"
            className="btn btn-sm btn-outline-primary"
            disabled={eraseMode}
            onClick={handleEraserClick}
          >
            Eraser
          </button>
          <label htmlFor="color">Pen color</label>
          <input
            type="color"
            value={strokeColor}
            onChange={handleStrokeColorChange}
          />
          <label htmlFor="color">Canvas color</label>
          <input
            type="color"
            value={canvasColor}
            onChange={handleCanvasColorChange}
          />
          <label htmlFor="strokeWidth" className="form-label">
            Stroke width
          </label>
          <input
            disabled={eraseMode}
            type="range"
            className="form-range"
            min="1"
            max="20"
            step="1"
            id="strokeWidth"
            value={strokeWidth}
            onChange={handleStrokeWidthChange}
          />
          <label htmlFor="eraserWidth" className="form-label">
            Eraser width
          </label>
          <input
            disabled={!eraseMode}
            type="range"
            className="form-range"
            min="1"
            max="20"
            step="1"
            id="eraserWidth"
            value={eraserWidth}
            onChange={handleEraserWidthChange}
          />
        </div>
      )}
      
      {!imageUrl && (
        <>
        <ReactSketchCanvas
          key={canvasKey} // Force re-render on key change
          ref={canvasRef}
          strokeWidth={strokeWidth}
          eraserWidth={eraserWidth}
          strokeColor={strokeColor}
          canvasColor={canvasColor}
          style={{ border: '1px solid #000', marginBottom: '20px', width: '100%', height: '400px' }}
        />
        <div>
        <button
          style={{ marginRight: '10px' }}
          className="btn btn-sm btn-outline-danger"
          onClick={handleClear}
        >
          Clear
        </button>
        <button
          style={{ marginLeft: '10px' }}
          className="btn btn-sm btn-outline-success"
          onClick={handleSave}
        >
          Save
        </button>
      </div>
      </>
      )}

      

      {/* Display the saved image */}
      {imageUrl && (
        <div className="mt-3">
          <DisplayImage imageUrl={imageUrl} />
          <button
            style={{ marginTop: '10px' }}
            className="btn btn-sm btn-outline-secondary"
            onClick={handleDrawAgain}
          >
            Draw Again
          </button>
        </div>
      )}
    </div>
  );
};

export default Retinoscope;



