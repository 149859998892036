import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { Input } from "@mui/joy";

const initialState = {
  name: "",
  mobilenumber: "",
  percentage: "",
};

function CreateAgent({ onCreate, onCancel, customers, model }) {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const [agentCode, setAgentCode] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log("Agent data", state);
  const { name, mobilenumber, percentage } = state;

  const { id } = useParams();

  useEffect(() => {
    const fetchNextAgentCode = async () => {
      try {
        const response = await axios.get(
          "https://hospital-api.hisabdev.com/api/agent/nextagentcode"
        );
        setAgentCode(response.data.AgentCode);
      } catch (error) {
        console.error("Error fetching next Agent code:", error);
        toast.error("Error fetching next Agent code");
      }
    };

    fetchNextAgentCode();
  }, []);

  //   const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (!name || !mobilenumber ) {
  //     toast.error("Please provide value into each inpute field ");
  //   } else {
  //     setLoading(true);
  //     if (!id) {
  //       axios
  //         .post("https://hospital-api.hisabdev.com/api/agent/createagent", {
  //           name,
  //           mobilenumber,
  //           percentage
  //         })
  //         .then(() => {
  //           setState(initialState);
  //         })
  //         .catch((err) => toast.error(err.respose.data));
  //       toast.success("Agent Create  Successfully");
  //     }
  //     setTimeout(() => {onCancel()}, 500);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !mobilenumber) {
      toast.error("Please provide value into each input field");
    } else {
      setLoading(true);
      if (!id) {
        try {
          await axios.post("https://hospital-api.hisabdev.com/api/agent/createagent", {
            name,
            mobilenumber,
            percentage,
          });
          setState(initialState);
          toast.success("Agent created successfully");
        } catch (err) {
          toast.error(err.response.data);
        } finally {
          setLoading(false);
        }
      }
      setTimeout(() => {
        onCancel();
      }, 500);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  return (
    <div className="bg-sky-200 p-4 drop-shadow rounded-xl">
      <h2 className="text-xl font-semibold ">Create {model}</h2>
      <Form onSubmit={handleSubmit}>
        <div className=" py-2">
          <Row>
            <Col lg={2} md={6} sm={12}>
              <Form.Group controlId="PATIENT_ID">
                <Form.Label className="block text-gray-700 font-medium">
                  Agent's Code
                </Form.Label>
                <Input
                  className=""
                  type="text"
                  name="doctorname"
                  value={agentCode}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group>
                <Form.Label className=" text-gray-700 font-medium">
                  Agent's Name <span className="required-icon">*</span>
                </Form.Label>
                <Input
                  required
                  type="text"
                  placeholder="Agent,s Name"
                  id="name"
                  name="name"
                  value={name || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className=" text-gray-700 font-medium">
                  Mobile No<span className="required-icon">*</span>
                </Form.Label>
                <Input
                  required
                  type="number"
                  placeholder="Mobile Number"
                  name="mobilenumber"
                  value={mobilenumber || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className=" text-gray-700 font-medium">
                  Percentage
                </Form.Label>
                <Input
                  type="number"
                  placeholder="Percentage"
                  name="percentage"
                  value={percentage || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="flex justify-content-between my-4">
          <Button
            variant="danger"
            onClick={onCancel}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
          >
            Cancel
          </Button>
          <Button type="submit" className="btn btn-success" disabled={loading}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default CreateAgent;
