// Appointments.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Table1 from '../../components/Table1';
import { useGlobalState } from '../../context/GlobalStateContext';
import CreateAppointmentForm from '../../components/CreateAppoinment';
import axios from 'axios';
import { toast } from 'react-toastify';
import { MdDelete } from 'react-icons/md';
// import { FaEye } from "react-icons/fa";
import { FaPen } from 'react-icons/fa';
import GrowExample from '../../components/spiner/GrowExample';
import { Button } from 'react-bootstrap';
import { parseISO, compareDesc } from 'date-fns';

function Appointments() {
  const [data, setData] = useState([]);
  const [OutDoordata, setOutDoorData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [createForm, showCreateForm] = useState(false);
  const [error, setError] = useState(null);
  console.log('OutDoordata', OutDoordata);
  const navigate = useNavigate();

  const sortAppointmentsByDate = (appointments) => {
    return appointments.sort((a, b) =>
      compareDesc(parseISO(a.date), parseISO(b.date))
    );
  };

  const loadData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://hospital-api.hisabdev.com/api/registation');
      const responseoutdoor = await axios.get(
        'https://hospital-api.hisabdev.com/api/outdoreregistation'
      );
      setData(sortAppointmentsByDate(response.data[0]));
      setOutDoorData(sortAppointmentsByDate(responseoutdoor.data[0]));
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [createForm]);

  const deleteContact = (id) => {
    if (
      window.confirm('Are you sure that you wanted to delete that patient ?')
    ) {
      axios.delete(
        `https://hospital-api.hisabdev.com/api/registation/removeregistation/${id}`
      );
      toast.success(' Delete Successfully');
      setTimeout(() => loadData(), 500);
    }
  };

  const columns = [
    {
      header: 'Date',
      accessorKey: 'date',
    },
    {
      header: 'Patiant Code',
      accessorKey: 'rpcode',
    },
    {
      header: 'Patiant Name',
      accessorKey: 'name',
    },
    {
      header: 'Gender',
      accessorKey: 'sex',
    },
    {
      header: 'Mobile Number',
      accessorKey: 'mobilenumber',
    },
    {
      header: 'Age',
      accessorKey: 'age',
    },
    {
      header: 'Doctor Name',
      accessorKey: 'doctorname',
    },
    {
      header: 'Time',
      accessorKey: 'time',
    },
    {
      header: 'Type',
      accessorKey: 'type',
    },
    {
      header: 'Amount',
      accessorKey: 'price',
    },
    {
      header: 'Address',
      accessorKey: 'location',
    },
    {
      header: 'Photo',
      accessorKey: 'image', // Assuming "photo" is the key for the image URL
      cell: ({ row }) => (
        <img
          src={
            row.original.image
              ? row.original.image
              : 'https://static.vecteezy.com/system/resources/previews/008/442/086/non_2x/illustration-of-human-icon-user-symbol-icon-modern-design-on-blank-background-free-vector.jpg'
          } // Access the photo URL from the data
          alt="Patient"
          style={{ width: '50px', height: '50px', borderRadius: '50%' }} // Styling the image
        />
      ),
    },
    // {
    //   header: "Guardian Number",
    //   accessorKey: "guardiannumber",
    // },
    // {
    //   header: "Guardian Name",
    //   accessorKey: "guardianname",
    // },
  ];

  const outdoorColumns = [
    {
      header: 'Date',
      accessorKey: 'date',
    },
    {
      header: 'Patient Code',
      accessorKey: 'orpCode',
    },
    {
      header: 'Patiant Name',
      accessorKey: 'name',
    },
    {
      header: 'Gender',
      accessorKey: 'sex',
    },
    {
      header: 'Mobile Number',
      accessorKey: 'mobilenumber',
    },
    {
      header: 'Guardian Number',
      accessorKey: 'guardiannumber',
    },
    {
      header: 'Guardian Name',
      accessorKey: 'guardianname',
    },
    // {
    //   header: "Actions",
    //   cell: (rowData) => (
    //     <div className="flex justify-between">
    //       {/* <button
    //         onClick={() => handleUpdate(rowData?.row?.original?.id)}
    //         className="btn btn-primary btn-sm me-2"
    //       >
    //         <FaPen />
    //       </button> */}
    //       <button
    //         onClick={() => deleteContact(rowData?.row?.original?.id)}
    //         className="btn btn-danger btn-sm"
    //       >
    //         <MdDelete />
    //       </button>
    //     </div>
    //   ),
    // },
  ];

  const handleViewAppointment = (id) => {
    // You can navigate to a detailed view of the appointment using the appointment ID
    // Example: navigate(`/appointments/${id}`);
    navigate('new user');
  };

  const handleUpdate = (id) => {
    navigate(`/appoinment/${id}`);
  };

  return (
    <div className="container p-2 ">
      <h2 className="text-xl font-bold mb-4">Registration</h2>
      {createForm ? (
        <CreateAppointmentForm
          onCancel={() => {
            showCreateForm(false);
          }}
        />
      ) : (
        <div className="flex justify-between">
          <Button
            className="mb-2 drop-shadow"
            onClick={() => showCreateForm(true)}
          >
            {' '}
            Register New Appoinment
          </Button>
        </div>
      )}
      {isLoading && !data[0] && (
        <div className="w-full h-60 flex justify-center items-center">
          <GrowExample />
        </div>
      )}
      {!createForm &&
        (data[0] ? (
          <div className="drop-shadow">
            <Table1
              data={data}
              columns={[
                {
                  header: 'No.',
                  cell: (row) => {
                    return <>{row.cell.row.index + 1}</>;
                  },
                },
                ...columns,
                {
                  header: 'Actions',
                  cell: (rowData) => (
                    <div className="flex justify-between">
                      {/* <button
                        onClick={() =>
                          handleViewAppointment(rowData?.row?.original?.id)
                        }
                        className="btn btn-primary btn-sm me-2"
                      >
                        {" "}
                        <FaEye />{" "}
                      </button> */}
                      <button
                        onClick={() => handleUpdate(rowData?.row?.original?.id)}
                        className="btn btn-primary btn-sm me-2"
                      >
                        {' '}
                        <FaPen />{' '}
                      </button>
                      <button
                        onClick={() =>
                          deleteContact(rowData?.row?.original?.id)
                        }
                        className="btn btn-danger btn-sm"
                      >
                        {' '}
                        <MdDelete />{' '}
                      </button>
                    </div>
                  ),
                },
              ]}
              pageLimit={10}
            />
          </div>
        ) : !isLoading ? (
          <p className="text-red-400 font-semibold w-max mx-auto my-20">
            No Appointment Data
          </p>
        ) : (
          ''
        ))}
      {!createForm && OutDoordata[0] && (
        <div className="drop-shadow">
          <h3>Outdoor Appointments</h3>
          <Table1 data={OutDoordata} columns={outdoorColumns} pageLimit={10} />
        </div>
      )}
    </div>
  );
}

export default Appointments;
