// Customers.js 
import React, { useEffect, useState } from "react";
import CreateDoctor from "../../components/CreateDoctor";
import Button from "react-bootstrap/esm/Button";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Table from "../../components/Table";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { FaPen } from "react-icons/fa";
import GrowExample from '../../components/spiner/GrowExample';



function Doctor() {
  const [data, setData] = useState([]);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname;
  const parts = url.split("/");
  const model = parts[parts.length - 1];
  const [error, setError] = useState(null);

  const loadData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/doctor");
      setData(response.data[0]);
      console.log(response.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [showCreateForm]);

  const deleteContact = (id) => {
    if (
      window.confirm("Are you sure that you wanted to delete that Doctor ?")
    ) {
      axios.delete(`https://hospital-api.hisabdev.com/api/doctor/removedoctor/${id}`);
      toast.success("Dotor Delete Successfully");
      setTimeout(() => loadData(), 500);
    }
  };

  const columns = [
    {
      header: "Doctor Code",
      accessorKey: "dcode",
    },
    {
      header: "Doctor Name",
      accessorKey: "doctorname",
    },
    {
      header: "Doctor Registation No",
      accessorKey: "doctorRegistationNo",
    },
    {
      header: "Doctor Specialist",
      accessorKey: "doctorSpecialist",
    },
    {
      header: "Doctor Availability On",
      accessorKey: "doctorAvailabilityOn",
    },
    {
      header: "Designation",
      accessorKey: "designation",
    },
    {
      header: "General Fees Amount",
      accessorKey: "generalFees",
    },
    {
      header: "Special Fees Amount",
      accessorKey: "specialFees",
    },
    {
      header: "On CallFees Amount",
      accessorKey: "onCallFees",
    },
    {
      header: "Percentage%",
      accessorKey: "percentage",
    },
  ];

  const handleView = (id) => {
    navigate(`/doctor/${id}`);
  };

  const handleUpdate = (data) => {
    navigate(`/doctor/${data}`);
  };

  return (
    <div className="container p-2 ">
      {showCreateForm ? (
        <CreateDoctor
          onCancel={() => {
            setShowCreateForm(false);
          }}
          customers={data}
          model={model}
        />
      ) : (
        <div className="flex justify-between">
          <Button
            className="mb-2 drop-shadow"
            onClick={() => setShowCreateForm(true)}
          >
            {" "}
            {/* Add {model} */}
            Add Doctor
          </Button>
        </div>
      )}
      {isLoading && !data[0] && (
        <div className="w-full h-60 flex justify-center items-center">
          <GrowExample />
        </div>
      )}
      {showCreateForm ? null : data[0] ? (
        <div className="drop-shadow">
          <Table
            data={data}
            columns={[
              ...columns,
              {
                header: "Actions",
                cell: (rowData) => {
                  return (
                    <div className="flex justify-between">
                      {/* <button
                        onClick={() => handleView(rowData?.row?.original?.id)}
                        className="btn btn-primary btn-sm me-2"
                      >
                        {" "}
                        <FaEye />{" "}
                      </button> */}
                      <button
                        onClick={() => handleUpdate(rowData?.row?.original?.id)}
                        className="btn btn-primary btn-sm me-2"
                      >
                        {" "}
                        <FaPen />{" "}
                      </button>
                      <button
                        onClick={() =>
                          deleteContact(rowData?.row?.original?.id)
                        }
                        className="btn btn-danger btn-sm"
                      >
                        {" "}
                        <MdDelete />{" "}
                      </button>
                    </div>
                  );
                },
              },
            ]}
            pageLimit={10}
          />
        </div>
      ) : !isLoading ? (
        <p className="text-red-400 font-semibold w-max mx-auto my-20">
          No Doctor's Data
        </p>
      ) : (
        ""
      )}
    </div>
  );
}

export default Doctor;
