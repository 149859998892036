import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { Input } from "@mui/joy";

const initialState = {
  doctorname: "",
  doctorRegistationNo: "",
  doctorAvailabilityOn: "",
  doctorSpecialist: "",
  specialFees: "",
  designation: "",
  generalFees: "",
  onCallFees: "",
  percentage: "",
  dateOfJoining: "",
  doctorNumber: "",
  remarks: "",
};

function CreateDoctor({ onCreate, onCancel, customers, model }) {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const [doctorCode, setDoctorCode] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log("doctor data", state);
  const {
    doctorname,
    doctorRegistationNo,
    designation,
    onCallFees,
    specialFees,
    generalFees,
    percentage,
    doctorAvailabilityOn,
    doctorSpecialist,
    dateOfJoining,
    doctorNumber,
    remarks,
  } = state;

  const { id } = useParams();

  useEffect(() => {
    const fetchNextDoctorCode = async () => {
      try {
        const response = await axios.get(
          "https://hospital-api.hisabdev.com/api/doctor/nextdoctorcode"
        );
        setDoctorCode(response.data.DoctorCode);
      } catch (error) {
        console.error("Error fetching next product code:", error);
        toast.error("Error fetching next product code");
      }
    };

    fetchNextDoctorCode();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!doctorname || !designation) {
      toast.error("Please provide value into each input field ");
    } else {
      setLoading(true);
      if (!id) {
        try {
          await axios.post("https://hospital-api.hisabdev.com/api/doctor/createdoctor", {
            doctorname,
            doctorRegistationNo,
            designation,
            onCallFees,
            specialFees,
            generalFees,
            percentage,
            doctorAvailabilityOn,
            doctorSpecialist,
            dateOfJoining,
            doctorNumber,
            remarks,
          });
          setState(initialState);
          toast.success("Doctor created successfully");
        } catch (err) {
          toast.error(err.response.data);
        } finally {
          setLoading(false);
        }
      }
      setTimeout(() => {
        onCancel();
      }, 500);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  return (
    <div className="bg-sky-200 p-4 drop-shadow rounded-xl">
      <h2 className="text-xl font-semibold ">Create {model}</h2>
      <Form onSubmit={handleSubmit}>
        <div className=" py-2">
          <Row>
            <Col lg={2} md={6} sm={12}>
              <Form.Group controlId="PATIENT_ID">
                <Form.Label className="block text-gray-700 font-medium">
                  Doctor's Code
                </Form.Label>
                <Input
                  className=""
                  type="text"
                  name="doctorname"
                  value={doctorCode}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group>
                <Form.Label className=" text-gray-700 font-medium">
                  Doctor's Name<span className="required-icon">*</span>
                </Form.Label>
                <Input
                  required
                  type="text"
                  placeholder="Doctor,s Name"
                  id="doctorname"
                  name="doctorname"
                  value={doctorname || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className=" text-gray-700 font-medium">
                  Doctor Registation Number<span className="required-icon">*</span>
                </Form.Label>
                <Input
                  required
                  type="text"
                  placeholder="Doctor,s Registation Number"
                  id="doctorRegistationNo"
                  name="doctorRegistationNo"
                  value={doctorRegistationNo || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className=" text-gray-700 font-medium">
                  Doctor Specialist<span className="required-icon">*</span>
                </Form.Label>
                <Input
                  required
                  type="text"
                  placeholder="Doctor,s Specialist"
                  id="doctorSpecialist"
                  name="doctorSpecialist"
                  value={doctorSpecialist || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className=" text-gray-700 font-medium">
                  Designation<span className="required-icon">*</span>
                </Form.Label>
                <Input
                  required
                  type="text"
                  placeholder="Designation"
                  name="designation"
                  value={designation || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className=" text-gray-700 font-medium">
                  Availability On <span className="required-icon">*</span>
                </Form.Label>
                <Input
                  required
                  type="text"
                  placeholder="Doctor,s Availability On"
                  id="doctorAvailabilityOn"
                  name="doctorAvailabilityOn"
                  value={doctorAvailabilityOn || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className=" text-gray-700 font-medium">
                  Date of Joining<span className="required-icon">*</span>
                </Form.Label>
                <Input
                  required
                  type="date"
                  placeholder="Doctor,s Specialist"
                  id="dateOfJoining"
                  name="dateOfJoining"
                  value={dateOfJoining || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className=" text-gray-700 font-medium">
                  Contact Number<span className="required-icon">*</span>
                </Form.Label>
                <Input
                  required
                  type="number"
                  placeholder="Doctor,s Contact Number"
                  id="doctorNumber"
                  name="doctorNumber"
                  value={doctorNumber || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={2} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Special Fees
                </Form.Label>
                <Input
                  type="number"
                  placeholder="Special Fees"
                  name="specialFees"
                  value={specialFees || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={2} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  General Fees
                </Form.Label>
                <Input
                  type="number"
                  placeholder="General Fees"
                  name="generalFees"
                  value={generalFees || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={2} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  On Call Fees
                </Form.Label>
                <Input
                  type="number"
                  placeholder="On Call Fees"
                  name="onCallFees"
                  value={onCallFees || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>

            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className=" text-gray-700 font-medium">
                  Percentage
                </Form.Label>
                <Input
                  type="number"
                  placeholder="Percentage"
                  name="percentage"
                  value={percentage || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className=" text-gray-700 font-medium">
                  Remarks
                </Form.Label>
                <Input
                  type="text"
                  placeholder="remarks"
                  id="remarks"
                  name="remarks"
                  value={remarks || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="flex justify-content-between my-4">
          <Button
            variant="danger"
            onClick={onCancel}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
          >
            Cancel
          </Button>
          <Button type="submit" className="btn btn-success" disabled={loading}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default CreateDoctor;
