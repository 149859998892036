import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { Input } from "@mui/joy";

const initialState = {
  name: "",
  address: "",
  mobilenumber: "",
  pincode: "",
  block: "",
  age: "",
  sex: "",
  doctor: "",
  date: new Date().toISOString().split("T")[0],
  time: new Date().toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }),
  guardiannumbaer: "",
  guardianname: "",
  bed: "",
  packages: "",
  status: "admitted",
  source: "hospital",
};

function CreateAdmitpatiant({ onCreate, onCancel }) {
  const [doctors, setDoctor] = useState([]);
  const [packageData, setPackageData] = useState([]);
  const [beds, setBed] = useState([]);
  const [showLocation, setShowLocation] = useState([]);
  const [state, setState] = useState(initialState);
  const [patiant, setPatiant] = useState([]);
  const [regiPatiant, setRegistationPatiant] = useState([]);
  const [outdoorPres, setOutDoorPres] = useState([]);
  const [error, setError] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  console.log("state", regiPatiant);
  console.log("outdoor Prescription", outdoorPres);
  const {
    name,
    address,
    mobilenumber,
    pincode,
    block,
    age,
    sex,
    doctor,
    date,
    time,
    guardiannumbaer,
    guardianname,
    bed,
    packages,
    status,
    source = "hospital",
  } = state;
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentTime, setCurrentTime] = useState(new Date());
  console.log("state", state);
  const getCurrentDateAndTime = () => {
    const now = new Date();
    setCurrentDate(now);
    setCurrentTime(now);
  };

  useEffect(() => {
    getCurrentDateAndTime();
  }, []);

  const handleUpdateBedStatus = async (bedId) => {
    console.log("Updating bed status for bedId:", bedId);
    return await axios
      .put(`https://hospital-api.hisabdev.com/api/bed/${bedId}/status`, {
        status: "occupied",
      })
      .then((response) => {
        console.log("Bed status updated successfully:", response);
      })
      .catch((err) => {
        console.error("Error updating bed status:", err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name) {
      toast.error("Please provide value into * input field ");
    } else {
      if (!id) {
        console.log("Form submission started");
        console.log("State before submission:", state);
        console.log("Available beds:", beds);

        axios
          .post("https://hospital-api.hisabdev.com/api/admission/createadmision", {
            ...state,
          })
          .then(() => {
            setState(initialState);
            console.log("classdetails", state);
            const selectedBed = beds.find((b) => b.bedname === state.bed);
            console.log("selectedBed", selectedBed);
            if (selectedBed) {
              console.log("Selected bed ID:", selectedBed.id);
              return handleUpdateBedStatus(selectedBed.id);
            } else {
              console.error("Selected bed not found");
            }
          })
          .catch((err) => {
            console.error("Error during admission creation:", err);
            toast.error(err.response.data);
          });
        toast.success("Admission Successfully");
      }
      setTimeout(() => {
        onCancel();
      }, 500);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const loadDoctorData = async () => {
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/doctor");
      setDoctor(response.data[0]);
    } catch (error) {
      setError(error.message);
    }
  };

  const loadBedData = async () => {
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/bed");
      const vacantBeds = response.data[0].filter(
        (bed) => bed.status === "vacant"
      );
      setBed(vacantBeds);
    } catch (error) {
      setError(error.message);
    }
  };

  const loadPackageData = async () => {
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/packeg");
      setPackageData(response.data[0]);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    loadDoctorData();
    loadBedData();
    loadPackageData();
  }, []);

  const loadAdmissionsData = async () => {
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/admission");
      setPatiant(response.data[0]);
      console.log(response.data, "patiant");
    } catch (error) {
      setError(error.message);
    }
  };

  const loadRegistrationData = async () => {
    try {
      const response = await axios.get(
        "https://hospital-api.hisabdev.com/api/prescription"
      );
      setRegistationPatiant(response.data[0]);
    } catch (error) {
      setError(error.message);
    }
  };

  const loadOutdoorPresData = async () => {
    try {
      const response = await axios.get(
        "https://hospital-api.hisabdev.com/api/prescription/outdoorPres"
      );
      setOutDoorPres(response.data[0]);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    loadAdmissionsData();
    loadRegistrationData();
    loadOutdoorPresData();
  }, []);

  const getNextPatientId = () => {
    if (patiant && patiant.length > 0) {
      const lastPatient = patiant[patiant.length - 1];
      const lastId = parseInt(String(lastPatient.id).replace("P", ""));
      const nextId = lastId + 1;
      return `A${nextId.toString().padStart(3, "0")}`;
    } else {
      return "P0DHDJSK58LI01";
    }
  };

  const loadLocationData = async () => {
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/location");
      setShowLocation(response.data[0]);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    loadLocationData();
  }, []);

  const handleSearchChange = (newValue, actionMeta) => {
    if (actionMeta.action === "select-option") {
      const selectedPatient = newValue ? newValue.patient : null;
      if (selectedPatient) {
        setState({
          ...state,
          name: selectedPatient.name,
          address: selectedPatient.location,
          mobilenumber: selectedPatient.mobilenumber,
          pincode: selectedPatient.pincode,
          block: selectedPatient.block,
          age: selectedPatient.age,
          sex: selectedPatient.sex,
          guardianname: selectedPatient.guardianname,
          guardiannumbaer: selectedPatient.guardiannumber,
          doctor: selectedPatient.doctorname,
          packages: selectedPatient.selectedPackage,
          source: selectedPatient.source,
        });
      }
    } else if (actionMeta.action === "create-option") {
      setState({
        ...state,
        name: newValue,
        address: "",
        mobilenumber: "",
        pincode: "",
        block: "",
        age: "",
        sex: "",
        guardianname: "",
        guardiannumbaer: "",
        doctor: "",
        packages: "",
      });
    }
  };

  const getSearchOptions = () => {
    const combinedPatients = [...regiPatiant, ...outdoorPres];
    return combinedPatients.map((patient) => ({
      value: patient.id,
      label: `${patient.name ? patient.name : ""}${patient.name ? " " + patient.name : ""
        } (${patient.mobilenumber})`,
      patient,
    }));
  };

  return (
    <div className="">
      <Form onSubmit={handleSubmit}>
        <Row className="p-2">
          <Col lg={12} className="p-1">
            <div className="bg-sky-200 rounded p-2 drop-shadow">
              <div className="py-2">
                <h2 className="text-lg font-semibold">
                  Admission Patient Details:
                </h2>
                <Row>
                  <Col lg={4} md={4} sm={12}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Admission ID
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="id"
                        name="id"
                        value={getNextPatientId()}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Date
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="date"
                        value={currentDate.toISOString().split("T")[0]}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Time
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="time"
                        name="time"
                        value={currentTime.toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Name <span className="required-icon">*</span>
                      </Form.Label>
                      <CreatableSelect
                        required
                        id="name"
                        name="name"
                        isClearable
                        options={getSearchOptions()}
                        value={
                          state.name
                            ? { label: state.name, value: state.name }
                            : null
                        }
                        onChange={handleSearchChange}
                        onCreateOption={(value) =>
                          handleSearchChange(value, { action: "create-option" })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Address <span className="required-icon">*</span>
                      </Form.Label>
                      <Form.Control
                        placeholder="Address"
                        type="text"
                        id="address"
                        name="address"
                        value={address || ""}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Mobile Number <span className="required-icon">*</span>
                      </Form.Label>
                      <Form.Control
                        placeholder="Mobile Number"
                        type="text"
                        id="mobilenumber"
                        name="mobilenumber"
                        value={mobilenumber || ""}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Pincode <span className="required-icon">*</span>
                      </Form.Label>
                      <Form.Control
                        placeholder="Pincode"
                        type="text"
                        id="pincode"
                        name="pincode"
                        value={pincode || ""}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Block <span className="required-icon">*</span>
                      </Form.Label>
                      <Form.Select
                        id="block"
                        name="block"
                        value={block || ""}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select Block</option>
                        <option value="Panchayat">Panchayat</option>
                        <option value="Municipality">Municipality</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Age <span className="required-icon">*</span>
                      </Form.Label>
                      <Form.Control
                        placeholder="Age"
                        type="text"
                        id="age"
                        name="age"
                        value={age || ""}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Gender <span className="required-icon">*</span>
                      </Form.Label>
                      <Form.Select
                        id="sex"
                        type="text"
                        name="sex"
                        value={sex || ""}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select Sex</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Guardian Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="guardianname"
                        name="guardianname"
                        value={guardianname || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Guardian Number
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="guardiannumbaer"
                        name="guardiannumbaer"
                        value={guardiannumbaer || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Doctor <span className="required-icon">*</span>
                      </Form.Label>
                      <Form.Select
                        id="doctor"
                        name="doctor"
                        value={doctor || ""}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select Doctor</option>
                        {doctors.map((doc) => (
                          <option key={doc.doctorname} value={doc.doctorname}>
                            {doc.doctorname}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={12} className="p-1">
                    <div className="py-2 p-2">
                      <Row>
                        <Col lg={2} md={4} sm={6} xs={6}>
                          <Form.Group>
                            <Form.Label className="block text-gray-700 font-medium">
                              Bed <span className="required-icon">*</span>
                            </Form.Label>
                            <Form.Select
                              id="bed"
                              name="bed"
                              value={bed || ""}
                              onChange={handleInputChange}
                              required
                            >
                              <option value="">Select Bed</option>
                              {beds.map((bed) => (
                                <option key={bed.id} value={bed.bedname}>
                                  {bed.bedname}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={6}>
                          <Form.Group>
                            <Form.Label className="block text-gray-700 font-medium">
                              Package <span className="required-icon">*</span>
                            </Form.Label>
                            <Form.Select
                              id="packages"
                              name="packages"
                              value={packages || ""}
                              onChange={handleInputChange}
                              required
                            >
                              <option value="">Select Package</option>
                              {packageData.map((pack) => (
                                <option
                                  key={pack.packegname}
                                  value={pack.packegname}
                                >
                                  {pack.packegname}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col lg={12} className="p-1">
                          <div className="p-2 flex justify-between">
                            <Button
                              variant="danger"
                              type="button"
                              className="m-2"
                              onClick={onCancel}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="success"
                              type="submit"
                              className="m-2"
                            >
                              Save
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default CreateAdmitpatiant;
