import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import PlusImg from "../assets/images/red_plus.png";
import { LiaGlassesSolid } from "react-icons/lia";
import { BiMessageSquareDetail } from "react-icons/bi";
import Retinoscope from "./Retinoscope";

const initialState = {
  rpcode: "",
  date: new Date().toISOString().split("T")[0],
  location: "",
  name: "",
  image: "",
  mobilenumber: "",
  sex: "",
  age: "",
  guardiannumber: "",
  guardianname: "",
  doctorname: "",
  doctordesignation: "",
  time: new Date().toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }),
  type: "",
  price: "",
  amount: "",
  status: "checked",
  doctorCheckupStatus: "no",
  tests: [{ testname: "", result: "", amount: 0 }],
  notes: "",
  lancePowers: {
    vision: {
      RE: {
        DV: {
          dsph: "",
          dcyl: "",
          axis: "",
          vision: "",
        },
        NV: {
          dsph: "",
          dcyl: "",
          axis: "",
          vision: "",
        },
      },
      LE: {
        DV: {
          dsph: "",
          dcyl: "",
          axis: "",
          vision: "",
        },
        NV: {
          dsph: "",
          dcyl: "",
          axis: "",
          vision: "",
        },
      },
    },
    pd: "",
    typeOfGlasses: "",
  },
  otherDetailss: {
    chiefComplaints: "",
    history: "",
    familyHistory: "",
    systemicDiseaseOptions: {
      DM: false,
      HTN: false,
      Candida: false,
      Asthma: false,
      Allergy: false,
      Thyroid: false,
    },
    systemicDiseaseOptionsRemarks: "",
    vision: { RE: "", LE: "", UL: false, GL: false },
    phVision: { RE: "", LE: "" },
    colorVision: "Normal",
    roplas: {
      positive: { RE: false, LE: false },
      negative: { RE: false, LE: false },
    },
    ac: "",
    pupil: "",
  },
};

function UpdatePreCheckup() {
  const [doctorList, setDoctor] = useState([]);
  const [testData, setTestData] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState({});
  const [state, setState] = useState(initialState);
  const [showLancePowerForm, setShowLancePowerForm] = useState(false);
  const [showOtherDetailsForm, setShowOtherDetailsForm] = useState(false);
  const [lancePower, setLancePower] = useState(initialState.lancePowers);
  const [otherDetails, setOtherDetails] = useState(initialState.otherDetailss);
  const [imageUrl, setImageUrl] = useState('');
  console.log('props', imageUrl);
  const {
    rpcode,
    date,
    location,
    name,
    image,
    mobilenumber,
    sex,
    age,
    guardiannumber,
    guardianname,
    doctorname,
    doctordesignation,
    time,
    type,
    price,
    amount,
    status,
    doctorCheckupStatus,
    tests,
    notes,
    lancePowers,
    otherDetailss,
  } = state;

  console.log("state", state, lancePower, otherDetails);
  const { id } = useParams();
  const navigate = useNavigate();

  // Fetch Test
  const loadTestData = async () => {
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/test");
      setTestData(response.data[0]);
    } catch (error) {
      console.error("Error loading test data:", error);
    }
  };

  useEffect(() => {
    loadTestData();
  }, []);

  useEffect(() => {
    axios
      .get(`https://hospital-api.hisabdev.com/api/registation/${id}`)
      .then((resp) => setState({ ...resp.data[0] }));
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedOtherDetails = {
      ...otherDetails,
      retinoScope: imageUrl || "",  // Ensure retinoScope is included, use imageUrl or empty string if not set
    };
    if (!name) {
      toast.error("Please provide value into * input field ");
    } else {
      axios
        .post(`https://hospital-api.hisabdev.com/api/pre-checkup/saveprecheckup`, {
          rpcode,
          date,
          location,
          name,
          image,
          mobilenumber,
          sex,
          age,
          guardiannumber,
          guardianname,
          doctorname,
          doctordesignation,
          time,
          type,
          price,
          amount,
          status: "checked",
          doctorCheckupStatus: "no",
          tests,
          notes,
          lancePowers: lancePower,
          otherDetailss: updatedOtherDetails,
        })
        .then(() => {
          setState(initialState);
          toast.success("Pre-Checkup Saved Successfully");
          console.log(otherDetails);
        })
        .catch((err) => toast.error(err.response.data));
      setTimeout(() => {
        navigate("/pre-checkup");
      }, 500);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "doctorname") {
      const doctor = doctorList.find((doc) => doc.doctorname === value);
      setSelectedDoctor(doctor);
      setState({
        ...state,
        [name]: value,
        doctordesignation: doctor ? doctor.designation : "",
      });
    } else if (name === "type") {
      let newPrice = "";
      if (value === "Free") {
        newPrice = "0.00";
      } else if (selectedDoctor) {
        switch (value) {
          case "Special":
            newPrice = selectedDoctor.specialFees;
            break;
          case "General":
            newPrice = selectedDoctor.generalFees;
            break;
          case "OnCall":
            newPrice = selectedDoctor.onCallFees;
            break;
          default:
            newPrice = "";
        }
      }
      setState({ ...state, [name]: value, price: newPrice });
    } else {
      setState({ ...state, [name]: value });
    }
  };

  const handleTestChange = (index, event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      const newTests = Array.isArray(prevState.tests)
        ? [...prevState.tests]
        : [];
      newTests[index] = { ...newTests[index], [name]: value };

      // Find the selected test data to get the amount
      const selectedTest = testData?.find((test) => test.testname === value);
      if (selectedTest) {
        newTests[index].amount = selectedTest.amount;
      }

      // Calculate the total amount
      const totalAmount = newTests.reduce(
        (sum, test) => sum + parseFloat(test.amount || 0),
        0
      );

      return { ...prevState, tests: newTests, amount: totalAmount.toFixed(2) };
    });
  };

  const addTestField = () => {
    setState((prevState) => ({
      ...prevState,
      tests: Array.isArray(prevState.tests)
        ? [...prevState.tests, { testname: "", result: "", amount: 0 }]
        : [{ testname: "", result: "", amount: 0 }],
    }));
  };

  const handleDeleteTest = (index) => {
    setState((prevState) => {
      const updatedTests = Array.isArray(prevState.tests)
        ? prevState.tests.filter((_, i) => i !== index)
        : [];
      // Calculate the total amount
      const totalAmount = updatedTests.reduce(
        (sum, test) => sum + parseFloat(test.amount || 0),
        0
      );

      return {
        ...prevState,
        tests: updatedTests,
        amount: totalAmount.toFixed(2),
      };
    });
  };

  const handleLancePowerClick = () => {
    setShowLancePowerForm((prev) => !prev);
    setShowOtherDetailsForm(false); // Close other form
  };

  const handleVisionChange = (eye, section, field, value) => {
    setLancePower((prevState) => ({
      ...prevState,
      vision: {
        ...prevState.vision,
        [eye]: {
          ...prevState.vision[eye],
          [section]: {
            ...prevState.vision[eye][section],
            [field]: value,
          },
        },
      },
    }));
  };

  const handleOtherDetailsClick = () => {
    setShowOtherDetailsForm((prev) => !prev);
    setShowLancePowerForm(false); // Close other form
  };

  return (
    <div className="container p-2">
      <Form onSubmit={handleSubmit}>
        <Row className="p-2">
          <Col lg={12} className="p-1">
            <div className="bg-sky-200 rounded p-2 drop-shadow">
              <div className="py-2">
                {/* <h2 className="text-lg font-semibold">Patient Details:</h2> */}
                <Row>
                  <Col lg={2} md={2} sm={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Patient ID
                      </Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        name="rpcode"
                        value={rpcode || ""}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Name
                      </Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        name="name"
                        value={name || ""}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Mobile Number
                      </Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        name="mobilenumber"
                        value={mobilenumber || ""}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Address
                      </Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        name="location"
                        value={location || ""}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg={1} md={1} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Gender
                      </Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        name="sex"
                        value={sex || ""}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={1} md={1} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Age
                      </Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        name="age"
                        value={age || ""}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} sm={12}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Doctor
                      </Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        name="doctorname"
                        value={doctorname || ""}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={2} md={2} sm={12}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Type
                      </Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        name="type"
                        value={type || ""}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={2} md={2} sm={12}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Price
                      </Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        name="price"
                        value={price || ""}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={2} md={2} sm={12}>
                    <Form.Group>
                      <Form.Label className="block text-gray-700 font-medium">
                        Amount
                      </Form.Label>
                      <Form.Control
                        // readOnly
                        type="text"
                        name="amount"
                        value={amount || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="p-2">
          <Col lg={3} md={6} sm={12}>
            <Form.Group controlId="testsAssigned">
              <Form.Label className="font-semibold">Tests</Form.Label>
              {tests?.map((test, index) => (
                <div key={index}>
                  <Row className="my-1">
                    <Col>
                      <Form.Control
                        as="select"
                        name="testname"
                        value={test.testname}
                        onChange={(e) => handleTestChange(index, e)}
                      >
                        <option value="">Select Test</option>
                        {testData.map((option) => (
                          <option key={option.testname} value={option.testname}>
                            {option.testname}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                    {index !== 0 ? (
                      <Col lg={2}>
                        <Button
                          variant="danger"
                          onClick={() => handleDeleteTest(index)}
                        >
                          <RxCross2 />
                        </Button>
                      </Col>
                    ) : (
                      <Col lg={2}></Col>
                    )}
                  </Row>
                </div>
              ))}
            </Form.Group>
            <Button
              className="w-full my-2"
              variant="outline-primary"
              onClick={addTestField}
            >
              Add Test
            </Button>
          </Col>
          <Col lg={12} className="p-1">
            <div className="bg-sky-200 rounded p-2 drop-shadow">
              <div className="py-2">
                <Form.Group>
                  <Form.Label className="block text-gray-700 font-medium">
                    Notes
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="notes"
                    value={notes}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </div>
            </div>
          </Col>

          <Col lg={6} md={6} sm={6} xs={6}>
            <Button
              variant="outline-secondary"
              className="w-100 d-flex align-items-center"
              onClick={handleLancePowerClick}
            >
              <LiaGlassesSolid className="me-2" />
              <span>Lance Power</span>
            </Button>
          </Col>
          <Col lg={6} md={6} sm={6} xs={6}>
            <Button
              variant="outline-dark"
              className="w-100 d-flex align-items-center"
              onClick={handleOtherDetailsClick}
            >
              <BiMessageSquareDetail className="me-2" />
              Other Details
            </Button>
          </Col>

          {showLancePowerForm && (
            <>
              <div className="d-flex justify-content-around"></div>
              <div className="my-4">
                <Table striped bordered hover responsive className="mt-3">
                  <thead>
                    <tr>
                      <th colSpan="5" className="text-center">
                        RE
                      </th>
                      <th colSpan="4" className="text-center">
                        LE
                      </th>
                    </tr>
                    <tr>
                      {[
                        "Vision",
                        "Dsph",
                        "Dcyl",
                        "Axis",
                        "Vision",
                        "Dsph",
                        "Dcyl",
                        "Axis",
                        "Vision",
                      ].map((header, index) => (
                        <th key={index}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>D.V.</td>
                      {["RE", "LE"].map((eye) => (
                        <>
                          <td key={`${eye}-dv-dsph`}>
                            <Form.Control
                              type="text"
                              value={lancePower.vision[eye].DV.dsph}
                              onChange={(e) =>
                                handleVisionChange(
                                  eye,
                                  "DV",
                                  "dsph",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td key={`${eye}-dv-dcyl`}>
                            <Form.Control
                              type="text"
                              value={lancePower.vision[eye].DV.dcyl}
                              onChange={(e) =>
                                handleVisionChange(
                                  eye,
                                  "DV",
                                  "dcyl",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td key={`${eye}-dv-axis`}>
                            <Form.Control
                              type="text"
                              value={lancePower.vision[eye].DV.axis}
                              onChange={(e) =>
                                handleVisionChange(
                                  eye,
                                  "DV",
                                  "axis",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td key={`${eye}-dv-vision`}>
                            <Form.Control
                              type="text"
                              value={lancePower.vision[eye].DV.vision}
                              onChange={(e) =>
                                handleVisionChange(
                                  eye,
                                  "DV",
                                  "vision",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                        </>
                      ))}
                    </tr>
                    <tr>
                      <td>N.V.</td>
                      {["RE", "LE"].map((eye) => (
                        <>
                          <td key={`${eye}-nv-dsph`}>
                            <Form.Control
                              type="text"
                              value={lancePower.vision[eye].NV.dsph}
                              onChange={(e) =>
                                handleVisionChange(
                                  eye,
                                  "NV",
                                  "dsph",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td key={`${eye}-nv-dcyl`}>
                            <Form.Control
                              type="text"
                              value={lancePower.vision[eye].NV.dcyl}
                              onChange={(e) =>
                                handleVisionChange(
                                  eye,
                                  "NV",
                                  "dcyl",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td key={`${eye}-nv-axis`}>
                            <Form.Control
                              type="text"
                              value={lancePower.vision[eye].NV.axis}
                              onChange={(e) =>
                                handleVisionChange(
                                  eye,
                                  "NV",
                                  "axis",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td key={`${eye}-nv-vision`}>
                            <Form.Control
                              type="text"
                              value={lancePower.vision[eye].NV.vision}
                              onChange={(e) =>
                                handleVisionChange(
                                  eye,
                                  "NV",
                                  "vision",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                        </>
                      ))}
                    </tr>
                  </tbody>
                </Table>

                <Col lg={12} md={12} sm={12} xs={12}>
                  <Form.Group>
                    <Form.Label className="block text-gray-700 font-medium">
                      P.D.
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="pd"
                      value={lancePower.pd}
                      onChange={(e) =>
                        setLancePower({ ...lancePower, pd: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
                <Form.Group>
                  <Form.Label>Type Of Glasses</Form.Label>
                  <Form.Control
                    as="select"
                    name="typeOfGlasses"
                    value={lancePower.typeOfGlasses}
                    onChange={(e) =>
                      setLancePower({
                        ...lancePower,
                        typeOfGlasses: e.target.value,
                      })
                    }
                  >
                    <option value="">Select Glasses</option>
                    <option value="Bifocal">Bifocal</option>
                    <option value="DistanceVision">Distance Vision</option>
                    <option value="ProgressiveGlass">Progressive Glass</option>
                    <option value="Resilens">Resilens</option>
                    <option value="Photochromatic">Photochromatic</option>
                    <option value="ARC">ARC</option>
                    <option value="BlueCut">Blue Cut</option>
                  </Form.Control>
                </Form.Group>
              </div>
            </>
          )}

          {showOtherDetailsForm && (
            <div className="my-4">
              <Form.Group>
                <Form.Label className="font-semibold">
                  Chief Complaints
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Chief Complaints"
                  value={otherDetails.chiefComplaints}
                  onChange={(e) =>
                    setOtherDetails({
                      ...otherDetails,
                      chiefComplaints: e.target.value,
                    })
                  }
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className="font-semibold">History</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter History"
                  value={otherDetails.history}
                  onChange={(e) =>
                    setOtherDetails({
                      ...otherDetails,
                      history: e.target.value,
                    })
                  }
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className="font-semibold">
                  Family History
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Family History"
                  value={otherDetails.familyHistory}
                  onChange={(e) =>
                    setOtherDetails({
                      ...otherDetails,
                      familyHistory: e.target.value,
                    })
                  }
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className="font-semibold">
                  Systemic Disease Options
                </Form.Label>
                <div className="flex gap-1">
                  {Object.keys(otherDetails.systemicDiseaseOptions).map(
                    (key) => (
                      <Form.Check
                        key={key}
                        type="checkbox"
                        label={key}
                        checked={otherDetails.systemicDiseaseOptions[key]}
                        onChange={(e) =>
                          setOtherDetails({
                            ...otherDetails,
                            systemicDiseaseOptions: {
                              ...otherDetails.systemicDiseaseOptions,
                              [key]: e.target.checked,
                            },
                          })
                        }
                      />
                    )
                  )}
                </div>
                <Form.Label>Remarks</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  placeholder="EnterRemarks"
                  value={otherDetails.systemicDiseaseOptionsRemarks}
                  onChange={(e) =>
                    setOtherDetails({
                      ...otherDetails,
                      systemicDiseaseOptionsRemarks: e.target.value,
                    })
                  }
                />
              </Form.Group>

              <Form.Label className="font-semibold">Vision</Form.Label>
              <Col lg={12} md={12} sm={12} xs={12}>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="UL"
                  checked={otherDetails.vision.UL}
                  onChange={(e) =>
                    setOtherDetails({
                      ...otherDetails,
                      vision: {
                        ...otherDetails.vision,
                        UL: e.target.checked,
                      },
                    })
                  }
                />
                <Form.Check
                  type="switch"
                  label="GL"
                  id="GL"
                  checked={otherDetails.vision.GL}
                  onChange={(e) =>
                    setOtherDetails({
                      ...otherDetails,
                      vision: {
                        ...otherDetails.vision,
                        GL: e.target.checked,
                      },
                    })
                  }
                />
              </Col>
              <div className="flex gap-1">
                <Col lg={3} md={3} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label>RE</Form.Label>
                    <Form.Control
                      as="textarea"
                      type="text"
                      placeholder="Enter RE details"
                      value={otherDetails.vision.RE}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          vision: {
                            ...otherDetails.vision,
                            RE: e.target.value,
                          },
                        })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={3} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label>LE</Form.Label>
                    <Form.Control
                      as="textarea"
                      type="text"
                      placeholder="Enter LE details"
                      value={otherDetails.vision.LE}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          vision: {
                            ...otherDetails.vision,
                            LE: e.target.value,
                          },
                        })
                      }
                    />
                  </Form.Group>
                </Col>
              </div>
              <Form.Label className="font-semibold"> PH Vision</Form.Label>
              <div className="flex gap-1">
                <Col lg={3} md={3} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label>RE</Form.Label>
                    <Form.Control
                      as="textarea"
                      type="text"
                      placeholder="Enter RE details"
                      value={otherDetails.phVision.RE}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          phVision: {
                            ...otherDetails.phVision,
                            RE: e.target.value,
                          },
                        })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={3} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label>LE</Form.Label>
                    <Form.Control
                      as="textarea"
                      type="text"
                      placeholder="Enter LE details"
                      value={otherDetails.phVision.LE}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          phVision: {
                            ...otherDetails.phVision,
                            LE: e.target.value,
                          },
                        })
                      }
                    />
                  </Form.Group>
                </Col>
              </div>
              <Form.Group>
                <Form.Label className="font-semibold">Color Vision</Form.Label>
                <Form.Control
                  as="select"
                  value={otherDetails.colorVision}
                  onChange={(e) =>
                    setOtherDetails({
                      ...otherDetails,
                      colorVision: e.target.value,
                    })
                  }
                >
                  <option value="Normal">Normal</option>
                  <option value="Color Blind">Color Blind</option>
                </Form.Control>
              </Form.Group>

              <Form.Label className="font-semibold">ROPLAS</Form.Label>
              <Form.Group>
                <Form.Label className="font-medium">Positive</Form.Label>
                <div className="flex gap-1">
                  <Form.Check
                    type="checkbox"
                    label="RE"
                    checked={otherDetails.roplas.positive.RE}
                    onChange={(e) =>
                      setOtherDetails({
                        ...otherDetails,
                        roplas: {
                          ...otherDetails.roplas,
                          positive: {
                            ...otherDetails.roplas.positive,
                            RE: e.target.checked,
                          },
                        },
                      })
                    }
                  />
                  <Form.Check
                    type="checkbox"
                    label="LE"
                    checked={otherDetails.roplas.positive.LE}
                    onChange={(e) =>
                      setOtherDetails({
                        ...otherDetails,
                        roplas: {
                          ...otherDetails.roplas,
                          positive: {
                            ...otherDetails.roplas.positive,
                            LE: e.target.checked,
                          },
                        },
                      })
                    }
                  />
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label className="font-medium">Negative</Form.Label>
                <div className="flex gap-1">
                  <Form.Check
                    type="checkbox"
                    label="RE"
                    checked={otherDetails.roplas.negative.RE}
                    onChange={(e) =>
                      setOtherDetails({
                        ...otherDetails,
                        roplas: {
                          ...otherDetails.roplas,
                          negative: {
                            ...otherDetails.roplas.negative,
                            RE: e.target.checked,
                          },
                        },
                      })
                    }
                  />
                  <Form.Check
                    type="checkbox"
                    label="LE"
                    checked={otherDetails.roplas.negative.LE}
                    onChange={(e) =>
                      setOtherDetails({
                        ...otherDetails,
                        roplas: {
                          ...otherDetails.roplas,
                          negative: {
                            ...otherDetails.roplas.negative,
                            LE: e.target.checked,
                          },
                        },
                      })
                    }
                  />
                </div>
              </Form.Group>
              {/* <Form.Group>
                <Form.Label className="font-medium">Retinoscope</Form.Label>
                <div className="d-flex gap-3">
                  <div className="d-flex flex-column align-items-center">
                    <h5>LE</h5>
                    <img className="h-20 " src={PlusImg} alt="Plus Icon 1" />
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <h5>RE</h5>
                    <img className="h-20 " src={PlusImg} alt="Plus Icon 2" />
                  </div>
                </div>
                <div className="flex justify-between gap-1">
                  <Col lg={6} md={6} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label>LE</Form.Label>
                      <Form.Control
                        as="textarea"
                        type="text"
                        placeholder="Enter RE details"
                        value={otherDetails.retinoScope.RE}
                        onChange={(e) =>
                          setOtherDetails({
                            ...otherDetails,
                            retinoScope: {
                              ...otherDetails.retinoScope,
                              RE: e.target.value,
                            },
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label>RE</Form.Label>
                      <Form.Control
                        as="textarea"
                        type="text"
                        placeholder="Enter LE details"
                        value={otherDetails.retinoScope.LE}
                        onChange={(e) =>
                          setOtherDetails({
                            ...otherDetails,
                            retinoScope: {
                              ...otherDetails.retinoScope,
                              LE: e.target.value,
                            },
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                </div>
              </Form.Group> */}
              <Form.Label className="font-medium">Retinoscope</Form.Label>
              <Retinoscope setOtherDetails={setOtherDetails} setImageUrl={setImageUrl} imageUrl={imageUrl} />
              <Form.Group>
                <Form.Label className="font-semibold">A/C</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter A/C"
                  value={otherDetails.ac}
                  onChange={(e) =>
                    setOtherDetails({ ...otherDetails, ac: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="font-semibold">Pupil</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Pupil"
                  value={otherDetails.pupil}
                  onChange={(e) =>
                    setOtherDetails({ ...otherDetails, pupil: e.target.value })
                  }
                />
              </Form.Group>
            </div>
          )}
        </Row>
        <div className="flex justify-content-between my-4">
          <Button
            variant="danger"
            onClick={() => navigate("/pre-checkup")}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
          >
            Cancel
          </Button>
          <Button variant="success" type="submit" className="px-4 py-2">
            Save
          </Button>
        </div>
        {/* </Form> */}
      </Form>
    </div>
  );
}

export default UpdatePreCheckup;
