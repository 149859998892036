import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { Input } from "@mui/joy";

const initialState = {
  GLCode: "",
  description: "",
  PLBSAccount: "",
  PLBSGroup: "",
  PLBSSGroup: "",
  cashBankBook: "",
  cashBankCode: "",
  initial: "",
  opening: "",
};

function CreateGeneralLedger({ onCreate, onCancel, customers, model }) {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const [doctorCode, setDoctorCode] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log("Snehan data", state);
  const {
    GLCode,
    description,
    PLBSAccount,
    PLBSGroup,
    PLBSSGroup,
    cashBankBook,
    cashBankCode,
    initial,
    opening,
  } = state;

  const { id } = useParams();

  // useEffect(() => {
  //   const fetchNextDoctorCode = async () => {
  //     try {
  //       const response = await axios.get(
  //         "https://hospital-api.hisabdev.com/api/doctor/nextledgercode"
  //       );
  //       setDoctorCode(response.data.DoctorCode);
  //     } catch (error) {
  //       console.error("Error fetching next product code:", error);
  //       toast.error("Error fetching next product code");
  //     }
  //   };

  //   fetchNextDoctorCode();
  // }, []);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (!GLCode || !description) {
  //     toast.error("Please provide value into each inpute field ");
  //   } else {
  //     if (!id) {
  //       axios
  //         .post("https://hospital-api.hisabdev.com/api/ledger/creategeneralledger", {
  //           ...state
  //         })
  //         .then(() => {
  //           setState(initialState);
  //         })
  //         .catch((err) => toast.error(err.respose.data));
  //       toast.success("General Ledger Create  Successfully");
  //     }
  //     setTimeout(() => {
  //       onCancel();
  //     }, 500);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!GLCode || !description) {
      toast.error("Please provide a value for each input field");
      return;
    }

    setLoading(true); // Start loading
    try {
      await axios.post("https://hospital-api.hisabdev.com/api/ledger/creategeneralledger", {
        ...state,
      });
      toast.success("General Ledger created successfully");
      setState(initialState);
      onCancel();
    } catch (err) {
      toast.error(
        "Error creating General Ledger: " + err.response?.data || err.message
      );
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleRadioChange = (e) => {
    const { value } = e.target;
    setState({ ...state, cashBankBook: value });
  };

  return (
    <div className="bg-sky-200 p-4 drop-shadow rounded-xl">
      <h2 className="text-xl font-semibold ">Create</h2>
      <Form onSubmit={handleSubmit}>
        <div className=" py-2">
          <Row>
            <Col lg={2} md={6} sm={12}>
              <Form.Group controlId="PATIENT_ID">
                <Form.Label className="block text-gray-700 font-medium">
                  Code
                </Form.Label>
                <Input
                  className=""
                  placeholder="General Legdger Code"
                  type="text"
                  name="GLCode"
                  value={GLCode || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={5} md={6} sm={12}>
              <Form.Group>
                <Form.Label className=" text-gray-700 font-medium">
                  Description<span className="required-icon">*</span>
                </Form.Label>
                <Input
                  required
                  type="text"
                  placeholder="Description"
                  id="description"
                  name="description"
                  value={description || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={5} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Profit & Loss/Balance Sheet Account
                </Form.Label>
                <Form.Control
                  as="select"
                  name="PLBSAccount"
                  value={PLBSAccount}
                  onChange={handleInputChange}
                >
                  <option value="">Select Account Type</option>
                  <option value="profit_and_loss">Profit & Loss Account</option>
                  <option value="balance_sheet">Balance Sheet Account</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className=" text-gray-700 font-medium">
                  PL Group <span className="required-icon">*</span>
                </Form.Label>
                <Input
                  required
                  type="text"
                  placeholder="PL Group"
                  name="PLBSGroup"
                  value={PLBSGroup || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className=" text-gray-700 font-medium">
                  PL Sub Group
                </Form.Label>
                <Input
                  type="text"
                  placeholder="Sub Group"
                  name="PLBSSGroup"
                  value={PLBSSGroup || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Cash/Bank Book
                </Form.Label>
                <Form.Check
                  type="radio"
                  label="Yes"
                  value="yes"
                  checked={cashBankBook === "yes"}
                  onChange={handleRadioChange}
                />
                <Form.Check
                  type="radio"
                  label="No"
                  value="no"
                  checked={cashBankBook === "no"}
                  onChange={handleRadioChange}
                />
              </Form.Group>
            </Col>
            <Col lg={2} md={6} sm={12}>
              <Form.Group>
                <Form.Label className=" text-gray-700 font-medium">
                  Cash/Bank Code
                </Form.Label>
                <Input
                  type="text"
                  placeholder="Cash Bank Code"
                  name="cashBankCode"
                  value={cashBankCode || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={2} md={6} sm={12}>
              <Form.Group>
                <Form.Label className=" text-gray-700 font-medium">
                  Initial
                </Form.Label>
                <Input
                  type="text"
                  placeholder="Initial"
                  name="initial"
                  value={initial || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group>
                <Form.Label className=" text-gray-700 font-medium">
                  Opening
                </Form.Label>
                <Input
                  type="number"
                  placeholder="Opening"
                  name="opening"
                  value={opening || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="flex justify-content-between my-4">
          <Button
            variant="danger"
            onClick={onCancel}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
          >
            Cancel
          </Button>
          <Button type="submit" className="btn btn-success" disabled={loading}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default CreateGeneralLedger;
