import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { Input } from "@mui/joy";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";

const initialState = {
  patientname: "",
  tests: [],
  group_tests: [],
  referDrName: "",
  totalAmount: 0,
  advancePayment: 0,
  duePayment: 0,
  date: new Date().toISOString().split("T")[0],
  patientnumber: "",
  patientaddress: "",
  status: "processing",
};

function CreatePathology({ onCancel, model }) {
  const [tests, setTests] = useState([{ testname: "", result: "", amount: 0 }]);
  const [gpTests, setGpTests] = useState([
    { gptestname: "", gpresult: "", gpamount: 0 },
  ]);
  const [doctor, setDoctor] = useState([]);
  const [testData, setTestData] = useState([]);
  const [GroupTestData, setGroupTestData] = useState([]);
  const [state, setState] = useState(initialState);
  const [totalAmount, setTotalAmount] = useState(0);
  const [patientData, setPatientData] = useState([]);
  const [agentData, setAgentData] = useState([]);
  const [isExistingPatient, setIsExistingPatient] = useState(true);
  console.log("state", state);
  const {
    patientname,
    referDrName,
    advancePayment,
    duePayment,
    date,
    patientnumber,
    patientaddress,
    patienttest,
    agent,
    status,
  } = state;
  const loadPatientData = async () => {
    try {
      const registrationResponse = await axios.get(
        "https://hospital-api.hisabdev.com/api/pre-checkup"
      );
      const admissionResponse = await axios.get(
        "https://hospital-api.hisabdev.com/api/prescription"
      );
      // const outdoorRegistrationResponse = await axios.get(
      //   "https://hospital-api.hisabdev.com/api/outdoreregistation"
      // );

      // Combine all patient data into one array
      const combinedPatientData = [
        ...registrationResponse.data[0],
        ...admissionResponse.data[0],
        // ...outdoorRegistrationResponse.data[0],
      ];

      setPatientData(combinedPatientData);
    } catch (error) {
      console.error("Error loading patient data:", error);
    }
  };

  const loadAgentData = async () => {
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/agent");
      setAgentData(response.data[0]);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    loadTestData();
    loadGroupTestData();
    loadDoctorData();
    loadPatientData();
    loadAgentData();
  }, []);

  const loadTestData = async () => {
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/test");
      setTestData(response.data[0]);
    } catch (error) {
      console.error("Error loading test data:", error);
    }
  };

  const loadGroupTestData = async () => {
    try {
      const response = await axios.get(
        "https://hospital-api.hisabdev.com/api/test/grouptest"
      );
      setGroupTestData(response.data);
    } catch (error) {
      console.error("Error loading group test data:", error);
    }
  };

  const loadDoctorData = async () => {
    try {
      const response = await axios.get("https://hospital-api.hisabdev.com/api/doctor");
      setDoctor(response.data[0]);
    } catch (error) {
      console.error("Error loading doctor data:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedState = { ...state, [name]: value };

    if (name === "patientname") {
      const selectedPatient = patientData.find(
        (patient) => patient.name === value
      );
      if (selectedPatient) {
        updatedState = {
          ...updatedState,
          patientnumber: selectedPatient.mobilenumber, // Assuming mobile is the field name for mobile number
          patientaddress: selectedPatient.address, // Assuming address is the field name for address
          patientaddress: selectedPatient.location,
          patienttest: selectedPatient.tests,
        };
      }
    } else if (name === "advancePayment") {
      const newAdvancePayment = parseFloat(value) || 0;
      const newDuePayment = totalAmount - newAdvancePayment;
      updatedState = {
        ...updatedState,
        advancePayment: newAdvancePayment,
        duePayment: newDuePayment,
      };
    }

    setState(updatedState);
  };

  const handleTestChange = (index, event) => {
    const { name, value } = event.target;
    const newTests = [...tests];
    newTests[index][name] = value;

    // Find the selected test data to get the amount
    const selectedTest = [...testData].find((test) => test.testname === value);
    if (selectedTest) {
      newTests[index].amount = selectedTest.amount;
    }
    setTests(newTests);
    calculateTotalAmount(newTests, gpTests);
  };

  const handleGpTestChange = (index, event) => {
    const { name, value } = event.target;
    const newgTests = [...gpTests];
    newgTests[index][name] = value;

    const selectedgTest = [...GroupTestData].find(
      (gtest) => gtest.name === value
    );
    if (selectedgTest) {
      newgTests[index].gptestname = selectedgTest.name;
      newgTests[index].gpamount = selectedgTest.PAmount;
    }
    setGpTests(newgTests);
    calculateTotalAmount(tests, newgTests);
  };

  const calculateTotalAmount = (tests, gpTests) => {
    const totalTestAmount = tests.reduce(
      (acc, test) => acc + parseFloat(test.amount || 0),
      0
    );
    const totalGroupTestAmount = gpTests.reduce(
      (acc, gtest) => acc + parseFloat(gtest.gpamount || 0),
      0
    );
    const total = totalTestAmount + totalGroupTestAmount;
    setTotalAmount(total);
    const newDuePayment = total - parseFloat(state.advancePayment || 0);
    setState({ ...state, totalAmount: total, duePayment: newDuePayment });
  };

  const addTestField = () => {
    setTests([...tests, { testname: "", result: "", amount: 0 }]);
  };
  const addGpTestField = () => {
    setGpTests([...gpTests, { testname: "", result: "", amount: 0 }]);
  };

  const handleDeleteTest = (index) => {
    const updatedTests = tests.filter((_, i) => i !== index);
    setTests(updatedTests);
    calculateTotalAmount(updatedTests, gpTests);
  };

  const handleDeleteGpTest = (index) => {
    const updatedGpTests = gpTests.filter((_, i) => i !== index);
    setGpTests(updatedGpTests);
    calculateTotalAmount(tests, updatedGpTests);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !patientname ||
      !totalAmount ||
      !duePayment ||
      !date ||
      !patientnumber ||
      !patientaddress
    ) {
      toast.error("Please provide a value for each input field");
    } else {
      const data = {
        patientname,
        tests: tests.map((test) => ({
          name: test.testname,
          amount: test.amount,
        })),
        group_tests: gpTests.map((gtest) => ({
          name: gtest.gptestname,
          amount: gtest.gpamount,
        })),
        referDrName,
        totalAmount,
        advancePayment,
        duePayment,
        date,
        patientnumber,
        patientaddress,
        agent,
        status,
      };
      console.log("data", data);
      try {
        const response = await axios.post(
          "https://hospital-api.hisabdev.com/api/pathology/createpathology",
          data
        );
        console.log("response", response.data);
        setState(initialState);
        setTests([{ testname: "", result: "", amount: 0 }]);
        setGpTests([{ gptestname: "", gpresult: "", gpamount: 0 }]);

        toast.success("Pathology created successfully!");
        onCancel(); // Close the modal immediately
      } catch (err) {
        toast.error(err.response.data);
      }
    }
  };

  return (
    <div className="bg-sky-200 p-4 drop-shadow rounded-xl">
      <h2 className="text-xl font-semibold">Create</h2>
      <Form onSubmit={handleSubmit}>
        <div className="py-2">
          <Row>
            <Col lg={3} md={6} sm={12}>
              <Form.Group controlId="patientname">
                <Form.Label className="block text-gray-700 font-medium">
                  Patient's Name
                </Form.Label>
                {isExistingPatient ? (
                  <Form.Select
                    required
                    className=""
                    placeholder="Patient's name"
                    name="patientname"
                    value={patientname}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Patient</option>
                    {patientData.map((patient) => (
                      <option key={patient.id} value={patient.name}>
                        {patient.name}
                      </option>
                    ))}
                  </Form.Select>
                ) : (
                  <Input
                    required
                    className=""
                    placeholder="Patient's name"
                    type="text"
                    name="patientname"
                    value={patientname}
                    onChange={handleInputChange}
                  />
                )}
                <Button
                  variant="link"
                  onClick={() => setIsExistingPatient(!isExistingPatient)}
                >
                  {isExistingPatient
                    ? "Enter new patient name"
                    : "Select existing patient"}
                </Button>
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group controlId="patientnumber">
                <Form.Label className="block text-gray-700 font-medium">
                  Patient's Mobile Number
                  <span className="required-icon">*</span>
                </Form.Label>
                <Input
                  required
                  className=""
                  placeholder="Patient's number"
                  type="number"
                  name="patientnumber"
                  value={patientnumber}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="patientnumber">
                <Form.Label className="block text-gray-700 font-medium">
                  Patient's Address<span className="required-icon">*</span>
                </Form.Label>
                <Input
                  required
                  className=""
                  placeholder="Patient's Address"
                  type="text"
                  name="patientaddress"
                  value={patientaddress}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Label className="block text-gray-700 font-medium">
                Selected Patient Referance test
              </Form.Label>
              {patienttest && patienttest.length > 0 ? (
                <ul>
                  {patienttest.map((test, index) => (
                    <li key={index}>
                      <strong>Test Name:</strong> {test.testname},
                      <strong> Amount:</strong> {test.amount},
                      {/* <strong> Result:</strong> {test.result || 'Pending'} */}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No tests available.</p>
              )}
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="testsAssigned">
                <Form.Label className="font-semibold">Tests</Form.Label>
                {tests.map((test, index) => (
                  <div key={index}>
                    <Row className="my-1">
                      <Col>
                        <Form.Control
                          as="select"
                          name="testname"
                          value={test.testname}
                          onChange={(e) => handleTestChange(index, e)}
                        >
                          <option value="">Select Test</option>
                          {testData.map((option) => (
                            <option
                              key={option.testname}
                              value={option.testname}
                            >
                              {option.testname}
                              {/* - {option.amount} */}
                            </option>
                          ))}
                          {/* {GroupTestData.map((group) => (
                            <option key={group.name} value={group.name}>
                              {group.name} (Group)
                            </option>
                          ))} */}
                        </Form.Control>
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          name="remarks"
                          placeholder="Remarks"
                          value={`₹${test.amount}`}
                          readOnly
                        />
                      </Col>
                      {index !== 0 ? (
                        <Col lg={2}>
                          <Button
                            variant="danger"
                            onClick={() => handleDeleteTest(index)}
                          >
                            <RxCross2 />
                          </Button>
                        </Col>
                      ) : (
                        <Col lg={2}></Col>
                      )}
                    </Row>
                  </div>
                ))}
              </Form.Group>
              <Button
                className="w-full my-2"
                variant="outline-primary"
                onClick={addTestField}
              >
                Add Test
              </Button>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="testsAssigned">
                <Form.Label className="font-semibold">Group Tests</Form.Label>
                {gpTests.map((gtest, index) => (
                  <div key={index}>
                    <Row className="my-1">
                      <Col>
                        <Form.Control
                          as="select"
                          name="testname"
                          value={gtest.name}
                          onChange={(e) => handleGpTestChange(index, e)}
                        >
                          <option value="">Select Test</option>
                          {GroupTestData.map((group) => (
                            <option key={group.name} value={group.name}>
                              {group.name}
                              {/* -₹{group.PAmount} */}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          name="remarks"
                          placeholder="Remarks"
                          value={`₹${gtest.gpamount}`}
                          readOnly
                        />
                      </Col>
                      {index !== 0 ? (
                        <Col lg={2}>
                          <Button
                            variant="danger"
                            onClick={() => handleDeleteGpTest(index)}
                          >
                            <RxCross2 />
                          </Button>
                        </Col>
                      ) : (
                        <Col lg={2}></Col>
                      )}
                    </Row>
                  </div>
                ))}
              </Form.Group>
              <Button
                className="w-full my-2"
                variant="outline-primary"
                onClick={addGpTestField}
              >
                Add Group Test
              </Button>
            </Col>
            <Col lg={6} md={4} sm={12} xs={12}>
              <Form.Group controlId="referDrName">
                <Form.Label className="block text-gray-700 font-medium">
                  Refer Doctor <span className="required-icon">*</span>
                </Form.Label>
                <Form.Select
                  required
                  placeholder="Select a Doctor"
                  value={referDrName}
                  name="referDrName"
                  onChange={handleInputChange}
                >
                  <option value="">Select Doctor</option>
                  {doctor.map((item) => (
                    <option key={item.doctorname} value={item.doctorname}>
                      {item.doctorname} ({item.designation})
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={6} md={4} sm={12} xs={12}>
              <Form.Group controlId="referDrName">
                <Form.Label className="block text-gray-700 font-medium">
                  Agent <span className="required-icon">*</span>
                </Form.Label>
                <Form.Select
                  required
                  placeholder="Select a Doctor"
                  value={agent}
                  name="agent"
                  onChange={handleInputChange}
                >
                  <option value="">Select Agent</option>
                  {agentData.map((item) => (
                    <option key={item.name} value={item.name}>
                      {item.name} ({item.mobilenumber})
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group controlId="totalAmount">
                <Form.Label className="text-gray-700 font-medium">
                  Total Amount
                </Form.Label>
                <Input
                  type="text"
                  placeholder="Total Amount"
                  name="totalAmount"
                  value={`₹${totalAmount}`}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group controlId="advancePayment">
                <Form.Label className="text-gray-700 font-medium">
                  Advance Payment
                </Form.Label>
                <Input
                  type="number"
                  placeholder="Advance Payment ₹"
                  name="advancePayment"
                  value={advancePayment}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group controlId="duePayment">
                <Form.Label className="text-gray-700 font-medium">
                  Due Amount
                </Form.Label>
                <Input
                  type="text"
                  placeholder="Due Payment"
                  name="duePayment"
                  value={`₹${duePayment}`}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="flex justify-content-between my-4">
          <Button
            variant="danger"
            onClick={onCancel}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
          >
            Cancel
          </Button>
          <Button type="submit" className="px-4 py-2">
            Create
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default CreatePathology;
