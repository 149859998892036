import { Input } from "@mui/joy";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const initialState = {
  TYPE: "",
  TRNNO: "",
  TRN_DATE: "",
  GLCODE: "",
  DESCRIPT: "",
  CBNAME: "",
  NARRATION: "",
  PAYEE_R: "",
  CHEQUE_ON: "",
  CHEQUE_NO: "",
  CHEQUE_DT: "",
  AMOUNT: "",
};

const UpdateVoucher = ({ onCreate, onCancel }) => {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const [Ptype, setType] = useState("");
  console.log('updatesnehang', state);
  const {
    TYPE,
    TRNNO,
    TRN_DATE,
    GLCODE,
    DESCRIPT,
    CBNAME,
    NARRATION,
    PAYEE_R,
    CHEQUE_ON,
    CHEQUE_NO,
    CHEQUE_DT,
    AMOUNT,
  } = state;
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`https://hospital-api.hisabdev.com/api/voucher/${id}`)
      .then((resp) => {
        const voucherData = resp.data[0];
        setState({
          ...voucherData,
          // Format dates if they exist
          TRN_DATE: voucherData.TRN_DATE ? new Date(voucherData.TRN_DATE).toISOString().split('T')[0] : "",
          CHEQUE_DT: voucherData.CHEQUE_DT ? new Date(voucherData.CHEQUE_DT).toISOString().split('T')[0] : "",
        });
      })
      .catch((error) => {
        console.error("Error fetching voucher:", error);
        toast.error("Failed to fetch voucher details");
      });
  }, [id]);


  const handleSubmit = (e) => {
    e.preventDefault();
    if (!TYPE || !GLCODE) {
      toast.error("Please provide value into each inpute field ");
    } else {
      if (id) {
        axios
          .put(`https://hospital-api.hisabdev.com/api/voucher/updatevoucher/${id}`, {
            TYPE,
            TRNNO,
            TRN_DATE,
            GLCODE,
            DESCRIPT,
            CBNAME,
            NARRATION,
            PAYEE_R,
            CHEQUE_ON,
            CHEQUE_NO,
            CHEQUE_DT,
            AMOUNT,
          })
          .then(() => {
            setState(initialState);
            console.log("classdetails", state);
          })
          .catch((err) => toast.error(err.respose.data));
        toast.success("Update Successfully");
      }
      setTimeout(() => {
        navigate("/voucher");
      }, 500);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'TRN_DATE' || name === 'CHEQUE_DT') {
      const formattedDate = new Date(value).toISOString().split('T')[0];
      setState({ ...state, [name]: formattedDate });
    } else {
      setState({ ...state, [name]: value });
    }
  };


  const handleTypeChange = (e) => {
    setType(e.target.value);
    setState({ ...state, TYPE: e.target.value });
  };

  return (
    <div className="bg-sky-200 p-4 drop-shadow rounded-xl">
      <h2 className="text-xl font-semibold">Create</h2>
      <Form onSubmit={handleSubmit}>
        <div className="py-2">
          <Row>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Type
                </Form.Label>
                <Form.Control
                  as="select"
                  name="TYPE"
                  value={TYPE || ""}
                  onChange={handleTypeChange}
                >
                  <option value="">Select Type</option>
                  <option value="payment">Payment</option>
                  <option value="received">Received</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Voucher No
                </Form.Label>
                <Input
                  required
                  type="text"
                  placeholder="Voucher No"
                  id="TRNNO"
                  name="TRNNO"
                  value={TRNNO || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Date
                </Form.Label>
                <Input
                  type="date"
                  placeholder="Date"
                  id="TRN_DATE"
                  name="TRN_DATE"
                  value={TRN_DATE || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={2} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  GL Code
                </Form.Label>
                <Input
                  required
                  type="text"
                  placeholder="GL Code"
                  name="GLCODE"
                  value={GLCODE || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  GL Account
                </Form.Label>
                <Input
                  required
                  type="text"
                  placeholder="GL Account"
                  name="DESCRIPT"
                  value={DESCRIPT || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Cash/Bank
                </Form.Label>
                <Input
                  type="text"
                  placeholder="Cash/Bank"
                  name="CBNAME"
                  value={CBNAME || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  {Ptype === "payment"
                    ? "Paid To"
                    : Ptype === "received"
                      ? "Received From"
                      : "Paid To"}
                </Form.Label>
                <Input
                  type="text"
                  placeholder={
                    Ptype === "payment"
                      ? "Paid To"
                      : Ptype === "received"
                        ? "Received From"
                        : "Paid To"
                  }
                  name="PAYEE_R"
                  value={PAYEE_R || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={9} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Narration
                </Form.Label>
                <Input
                  type="text"
                  placeholder="Narration"
                  name="NARRATION"
                  value={NARRATION || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Cheque No
                </Form.Label>
                <Input
                  type="text"
                  placeholder="Cheque No"
                  name="CHEQUE_NO"
                  value={CHEQUE_NO || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Dated
                </Form.Label>
                <Input
                  type="date"
                  name="CHEQUE_DT"
                  value={CHEQUE_DT || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Drawn No
                </Form.Label>
                <Input
                  type="text"
                  placeholder="Drawn No"
                  name="CHEQUE_ON"
                  value={CHEQUE_ON || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="text-gray-700 font-medium">
                  Amount Rs
                </Form.Label>
                <Input
                  type="number"
                  placeholder="Amount Rs"
                  name="AMOUNT"
                  value={AMOUNT || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="flex justify-content-between my-4">
          <Button
            variant="danger"
            onClick={() => navigate('/voucher')}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
          >
            Cancel
          </Button>
          <Button type="submit" className="px-4 py-2">
            Update
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default UpdateVoucher;
